import format from "date-fns/format";
import compare from "date-fns/compareAsc";
import {
	convertIfNumber,
	getNestedParameterCount,
	setDeviceDetails,
	setTeamDetails,
	setTicketDetails
} from "./converterObj";

function convertToMap(arr) {
	const res = arr.map(item => [item.id, item.name]);
	return new Map(res);
}

export function convertSellDataForStore(result) {
	const teamMemberArr = result.teamMembers.map(item => { return {id: item.id, name: item.deviceNumber}; });

	const devices = convertToMap(teamMemberArr);
	const providerNamesMap = new Map(result.providers.map(item => [`p-${item.id}`, item.name]));
	const teamNames = convertToMap(result.teams);

	const ticketNamesMap = convertToMap(result.ticketTemplates);
	const teamNamesMap = new Map([...teamNames, ...providerNamesMap]);
	const devicesNamesMap = new Map();

	const detailedSellActions = result.sellActions.map(item => {
		return [item.id, {
			teamId: item.selledByTeamId,
			device: devices.get(item.selledByTeamMemberId)
		}];
	});
	const sellActionMap = new Map(detailedSellActions);

	const sellData = result.tickets.map(ticketInfo => {
		const date = new Date(ticketInfo.createdAtUtc); // TODO: timezone?
		const sellAct = sellActionMap.get(ticketInfo.sellActionId) || {teamId: "", teamName: "", device: ""};

		const teamId = sellAct.teamId || (ticketInfo.providerId ? `p-${ticketInfo.providerId}` : "");
		const deviceId = `${sellAct.device || "webClient"}_${teamId}`;
		devicesNamesMap.set(deviceId, `${sellAct.device ? `Device ${sellAct.device}` : "Uploaded"} (${teamNamesMap.get(teamId)})`);

		return {
			date: format(date, "dd MMM yyyy"),
			time: format(date, "HH:mm"),
			ticketId: ticketInfo.ticketTemplateId,
			teamId,
			deviceId,
			device: sellAct.device || "web_client"
		}
	});

	return {
		sellData,
		ticketNamesMap,
		teamNamesMap,
		devicesNamesMap
	};
}

export function convertScanDataForStore(result) {
	const teamNamesMap = convertToMap(result.teams);

	const ticketNamesMap = convertToMap(result.ticketTemplates);
	const devicesNamesMap = new Map();

	const scanData = result.scanActions.map(scan => {
		const { scannedAtUtc, deviceNumber, scannedByTeamId, ticketTemplateId } = scan;

		const date = new Date(scannedAtUtc); // TODO: timezone?
		const deviceId = `${deviceNumber}_${scannedByTeamId}`

		devicesNamesMap.set(deviceId, `Device ${deviceNumber} (${teamNamesMap.get(scannedByTeamId)})`)

		return {
			date: format(date, "dd MMM yyyy"),
			time: format(date, "HH:mm"),
			ticketId: ticketTemplateId,
			teamId: scannedByTeamId,
			deviceId,
			device: deviceNumber
		}
	});

	return {
		scanData,
		ticketNamesMap,
		teamNamesMap,
		devicesNamesMap
	};
}

//Provide map {"00" => {time: "00:00"}, ... , "23" => {time: "23:00"}} //
const dayInHoursMap = () => {
	const res = new Map();
	for (let i = 0; i < 24; i++) {
		const hours = `0${i}`.slice(-2);
		res.set(hours, {});
	}
	return res;
};

const extendWithValue = (map, nextValue, keyHour) => {
	if (!map.get("00").hasOwnProperty(nextValue)) {
		map.forEach((val, key) => map.set(key, Object.assign({[nextValue]: 0}, val)));
	}
	const oldInfo = map.get(keyHour);
	map.set(keyHour, Object.assign({}, oldInfo, {[nextValue]: oldInfo[nextValue] + 1}));

	return map;
};

const extendTicketWithDetails = (map, keyHour, ticketId, teamId, device) => {
	map.forEach((val, hourKey) => { //for each hour add old value extended with new params
		map.set(hourKey, setTicketDetails(val, ticketId, teamId, device, 0))
	});
	const oldInfo = map.get(keyHour);
	map.set(keyHour, setTicketDetails(oldInfo, ticketId, teamId, device, 1));

	return map;
};

const extendTeamWithDetails = (map, keyHour, ticketId, teamId, device) => {
	map.forEach((val, hourKey) => { //for each hour add old value extended with new params
		map.set(hourKey, setTeamDetails(val, ticketId, teamId, device, 0));
	});

	const oldInfo = map.get(keyHour);
	map.set(keyHour, setTeamDetails(oldInfo, ticketId, teamId, device, 1));

	return map;
};

const extendDeviceWithDetails = (map, keyHour, ticketId, device) => {
	map.forEach((val, hourKey) => { //for each hour add old value extended with new params
		map.set(hourKey, setDeviceDetails(val, ticketId, device, 0))
	});

	const oldInfo = map.get(keyHour);
	map.set(keyHour, setDeviceDetails(oldInfo, ticketId, device, 1));

	return map;
};

const countTicketDetails = (hourInfo, ticketDetailsCounter) => {
	return Object.keys(hourInfo).reduce((ticketRes, ticketId) => {
		const teamDetails = Object.keys(hourInfo[ticketId]).reduce((teamRes, teamKey) => {
			const teamId = convertIfNumber(teamKey);

			Object.keys(hourInfo[ticketId][teamId].device).forEach(deviceId => {
				const devThisHour = hourInfo[ticketId][teamId].device[deviceId];
				ticketDetailsCounter = setTicketDetails(ticketDetailsCounter, ticketId, teamId, deviceId, devThisHour);
			});

			const teamSoldTicket = getNestedParameterCount(ticketDetailsCounter, ticketId, teamId);
			const devices = ticketDetailsCounter[ticketId][teamId].device;
			return Object.assign(teamRes, {[teamId]: {count: teamSoldTicket, device: devices}})
		}, {});
		return Object.assign(ticketRes, {[ticketId]: Object.assign({}, ticketDetailsCounter[ticketId], teamDetails)});
	}, {});
};

const countTeamDetails = (hourInfo, teamDetailsCounter) => {
	return Object.keys(hourInfo).reduce((teamRes, teamId) => {
		const ticketDetails = Object.keys(hourInfo[teamId]).reduce((ticketRes, ticketId) => {

			Object.keys(hourInfo[teamId][ticketId].device).forEach(deviceId => {
				const devThisHour = hourInfo[teamId][ticketId].device[deviceId];
				teamDetailsCounter = setTeamDetails(teamDetailsCounter, ticketId, teamId, deviceId, devThisHour);
			});

			const soldTicketCount = getNestedParameterCount(teamDetailsCounter, teamId, ticketId);
			const devices = teamDetailsCounter[teamId][ticketId].device;
			return Object.assign(ticketRes, {[ticketId]: {count: soldTicketCount, device: devices}})
		}, {});
		return Object.assign(teamRes, {[teamId]: Object.assign({}, teamDetailsCounter[teamId], ticketDetails)});
	}, {});
};

const countDeviceDetails = (hourInfo, deviceDetailsCounter) => {
	return Object.keys(hourInfo).reduce((deviceRes, deviceId) => {
		const deviceDetails = Object.keys(hourInfo[deviceId]).reduce((ticketRes, ticketId) => {

			const ticketsThisHour = hourInfo[deviceId][ticketId];
			deviceDetailsCounter = setDeviceDetails(deviceDetailsCounter, ticketId, deviceId, ticketsThisHour);

			return Object.assign(ticketRes, {[ticketId]: deviceDetailsCounter[deviceId][ticketId]})
		}, {});
		return Object.assign(deviceRes, {[deviceId]: Object.assign({}, deviceDetailsCounter[deviceId], deviceDetails)});
	}, {});
};

export function convertToDataMap(data = []) {
	const dataMap = new Map();
	const detailsMap = new Map();

	//sort tickets by creating Map<date, {tickets: Map<[keyHour], {time, [ticketName1], [ticketName2], ..}>, teams, devices}>
	data.forEach(ticket => {
		const {date, ticketId, teamId, device, deviceId} = ticket;
		const keyHour = ticket.time.slice(0, 2);

		delete ticket.date;

		let tickets, teams, devices, ticketsDetails, teamsDetails, devicesDetails;
		const dayData = dataMap.has(date) ?
			dataMap.get(date) : { tickets: dayInHoursMap(), teams: dayInHoursMap(), devices: dayInHoursMap() };
		const dayDetails = detailsMap.has(date) ?
			detailsMap.get(date) : { tickets: dayInHoursMap(), teams: dayInHoursMap(), devices: dayInHoursMap() };

		tickets = extendWithValue(dayData.tickets, ticketId, keyHour);
		teams = extendWithValue(dayData.teams, teamId, keyHour);
		devices = extendWithValue(dayData.devices, deviceId, keyHour);

		ticketsDetails = extendTicketWithDetails(dayDetails.tickets, keyHour, ticketId, teamId, device);
		teamsDetails = extendTeamWithDetails(dayDetails.teams, keyHour, ticketId, teamId, device);
		devicesDetails = extendDeviceWithDetails(dayDetails.devices, keyHour, ticketId, deviceId);

		dataMap.set(date, {tickets, teams, devices});
		detailsMap.set(date, {tickets: ticketsDetails, teams: teamsDetails, devices: devicesDetails});
	});

	return {dataMap, detailsMap};
}

export function summedValuesDataMap(dataMap, detailsMap, ticketsArr, teamsArr, devicesArr) {
	const ticketCounter = ticketsArr.reduce((o, key) => Object.assign(o, {[key]: 0}), {});
	const teamCounter = teamsArr.reduce((o, key) => Object.assign(o, {[key]: 0}), {});
	const deviceCounter = devicesArr.reduce((o, key) => Object.assign(o, {[key]: 0}), {});
	const ticketDetailsCounter = ticketsArr.reduce((o, key) => Object.assign(o, {[key]: {}}), {});
	const teamDetailsCounter = teamsArr.reduce((o, key) => Object.assign(o, {[key]: {}}), {});
	const deviceDetailsCounter = devicesArr.reduce((o, key) => Object.assign(o, {[key]: {}}), {});

	Array.from(dataMap.keys()).sort((a, b) => compare(new Date(a), new Date(b)))
		.forEach(date => {
			const thisDayData = dataMap.get(date);
			const thisDayDetails = detailsMap.get(date);

			const tickets = new Map();
			const teams = new Map();
			const devices = new Map();
			const ticketsDetails = new Map();
			const teamsDetails = new Map();
			const devicesDetails = new Map();

			const hourValuesObj = (info, counter, hour) => {
				return Object.keys(info).reduce((o, key) => {
					counter[key] = counter[key] + info[key];
					return Object.assign(o, {[key]: counter[key]});
				}, {time: `${hour}:00`});
			};

			// for each hour
			for (let time = 0; time < 24; time++) {
				const hour = `0${time}`.slice(-2);
				tickets.set(hour, hourValuesObj(thisDayData.tickets.get(hour), ticketCounter, hour));
				teams.set(hour, hourValuesObj(thisDayData.teams.get(hour), teamCounter, hour));
				devices.set(hour, hourValuesObj(thisDayData.devices.get(hour), deviceCounter, hour));

				const ticketSumDetails = countTicketDetails(thisDayDetails.tickets.get(hour), ticketDetailsCounter);
				const teamSumDetails = countTeamDetails(thisDayDetails.teams.get(hour), teamDetailsCounter);
				const deviceSumDetails = countDeviceDetails(thisDayDetails.devices.get(hour), deviceDetailsCounter);

				ticketsDetails.set(hour, ticketSumDetails);
				teamsDetails.set(hour, teamSumDetails);
				devicesDetails.set(hour, deviceSumDetails);
			}

			dataMap.set(date, {tickets, teams, devices});
			detailsMap.set(date, {tickets: ticketsDetails, teams: teamsDetails, devices: devicesDetails});
		});

	return {dataMap, detailsMap};
}
