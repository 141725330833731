import * as actionTypes from "../actionTypes/emailTemplatesActionsTypes";

const initialState = {
  templates: [],
  isEmailTemplatesLoading: false,
  isEmailTemplateUpdating: false,
  currentTemplate: {
    value: null,
    label: '',
    item: {
      headerImageUrl: null,
      footerImageUrl: null,
      subject: '',
      bodyContent: '',
    }
  },
};

const emailTemplates = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EMAIL_TEMPLATES:
      return { ...state, isEmailTemplatesLoading: true };

    case actionTypes.GET_EMAIL_TEMPLATES_SUCCESS:
      return { ...state, isEmailTemplatesLoading: false, templates: action.payload };

    case actionTypes.GET_EMAIL_TEMPLATES_FAIL:
      return { ...state, isEmailTemplatesLoading: false };

    case actionTypes.EDIT_EMAIL_TEMPLATE:
      return { ...state, isEmailTemplateUpdating: true };

    case actionTypes.EDIT_EMAIL_TEMPLATE_SUCCESS:
      return { ...state, isEmailTemplateUpdating: false };

    case actionTypes.EDIT_EMAIL_TEMPLATE_FAIL:
      return { ...state, isEmailTemplateUpdating: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}

export default emailTemplates;
