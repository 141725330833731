import React from 'react';

import ErrorImage from '../../../images/error.svg';
import './PayNow.sass';

const payNow = props =>
    <div className="pay-now">
        <p>Your scanbits balance is negative. Please, pay now to continue working with the system.</p>
        <img src={ErrorImage} alt="Error"/>
        <button
            type="button"
            className="btn-primary"
            onClick={props.showModal}
        >
            <span>Pay Now</span>
        </button>
    </div>;

export default payNow;
