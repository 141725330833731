import React, { Component } from 'react';

import './ButtonBurger.sass';

class ButtonBurger extends Component {
    state = {
        isSidebarState: false
    };

    toggleSidebar = () => {
        const { toggleSidebar } = this.props;
        const { isSidebarState } = this.state;

        this.setState({ isSidebarState: !isSidebarState });
        toggleSidebar(isSidebarState);
    };

    render() {
        return (
            <div className="btn-burger" onClick={ this.toggleSidebar }>
                <div />
                <div />
                <div />
            </div>
        );
    }
}

export default ButtonBurger;