import React, { Component } from "react";
import "./UserInfoSettings.sass";

import getRandomArbitrary from "../../helpers/getRandomId";
import withReactContent from "sweetalert2-react-content";
import swal from "sweetalert2";

const MySwal = withReactContent(swal);
class UserInfoSettingsComponent extends Component {
  state = {
    userSettingsInput: "",
  };

  componentDidMount() {
    if (this.props.isTeamExisting) {
      this.props.getUserInfoSettings(
        this.props.userToken,
        this.props.scanTeamId
      );

      this.setState({
        isReady: true,
      });
    }
  }

  inputHandler = (evt) => {
    const { name, value } = evt.target;
    this.setState({
      [name]: value,
    });
  };

  submitHandler = (evt) => {
    evt.preventDefault();
    if (this.props.isTeamExisting) {
      this.props.createUserInfoSettings(
        this.props.userToken,
        this.state.userSettingsInput,
        this.props.scanTeamId
      );
    } else {
      this.props.addAdditionalField({
        title: this.state.userSettingsInput,
        id: getRandomArbitrary(100000, 999999),
      });
    }

    this.setState({
      userSettingsInput: "",
    });
  };

  deleteHandler = (teamConfigurationId) => {
    MySwal.fire({
      title: "Are you sure that you want to delete this field?",
      text: "It's impossible to take step back then",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      showConfirmButton: true,
      confirmButtonText: "Delete",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        this.props.deleteUserInfoSettings(
          this.props.userToken,
          teamConfigurationId,
          this.props.scanTeamId
        );
      }
    });
  };

  render() {
    const validationOnSpaces = this.state.userSettingsInput.replace(/ /g, "");
    return (
      <div className="UserInfoSettings">
        <div className="UserInfoSettings__header">
          <h3 className="UserInfoSettings__heading">
            What fields should your team fill out?
          </h3>

          <form
            className="UserInfoSettings__form"
            onSubmit={this.submitHandler}
          >
            <label
              className="UserInfoSettings__input-label"
              htmlFor="add-user-info"
            >
              <span className="UserInfoSettings__input-name">Field name</span>
              <input
                placeholder="Enter field name"
                name="userSettingsInput"
                className="UserInfoSettings__input"
                id="add-user-info"
                type="text"
                value={this.state.userSettingsInput}
                onChange={this.inputHandler}
              />
            </label>

            <button
              className="btn-primary UserInfoSettings__btn"
              type="submit"
              disabled={validationOnSpaces.length < 3}
            >
              Add
            </button>
          </form>
        </div>

        {this.props.userInfoSettings !== undefined && (
          <div
            className={
              this.props.userInfoSettings.length === 0
                ? "UserInfoSettings__content--hidden"
                : "UserInfoSettings__content"
            }
          >
            {[...this.props.userInfoSettings].reverse().map((userInfo) => (
              <div className="UserInfoSettings__item" key={userInfo.id}>
                {userInfo.title}
                <i
                  className="UserInfoSettings__clear"
                  onClick={
                    this.props.isTeamExisting
                      ? () => this.deleteHandler(userInfo.id)
                      : () => this.props.delAdditionalField(userInfo.id)
                  }
                ></i>
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default UserInfoSettingsComponent;
