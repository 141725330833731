import React from 'react';
import {Link} from 'react-router-dom';

import './Registration.sass';
import ROUTES from '../../../constants/routes';
import { getRegion } from '../../../helpers/getRegion';

const RegistrationSuccess = () => {
  const region = getRegion();
  return (
    <div className="auth login">
        <div className="auth__logo">
            <img
              className="auth__logo-img"
              src={`/${region.theme}/logo.png`}
              alt={region.title}
            />
            <div className="auth__logo-title">{region.title}</div>
          </div>
        <div className="auth__title">
            <p>Thank you for registration!</p>
            <p>Please click the activation link that has been sent to your email.</p>
            <div className="sign-in">
                <Link to={ROUTES.LOGIN}>Sign in</Link>
            </div>
        </div>
    </div>
  )
}
    

export default RegistrationSuccess;

