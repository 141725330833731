import React from 'react';
import {convertIfNumber} from "../../../../helpers/converterObj";

const TabDevice = ({ payload, data, getTicketName, getDeviceName }) => {
    return (
        <div className="chart-info">
            {
                payload ? payload.map(item => {
                    const deviceId = convertIfNumber(item.name);
                    const keyTime = item.payload["time"] ? item.payload["time"].slice(0, 2) : "00";
                    const dayData = data.get(keyTime)[deviceId];
                    return <div className="chart-item" key={item.name}>
                        <div className="chart-item__wrapper">
                            <div className="chart-item__label">
                                <div className="chart-item__indicator"
                                     style={{backgroundColor: item.color}}/>
                                <p className="chart-item__name">
                                    { item.name === "web_client" ? "Uploaded" : `${getDeviceName(item.name)}` }
                                </p>
                            </div>
                            <p className="chart-item__value">{item.value !== 0 ? item.value : '0'}</p>
                        </div>
                        {
                            Object.keys(dayData).map(ticketId => {
                                return [
                                    <div className="chart-item__wrapper" key={ticketId}>
                                        <p className="chart-item__team-name">{getTicketName(ticketId)}</p>
                                        <p className="chart-item__team-name">{dayData[ticketId]}</p>
                                    </div>
                                ];
                            })
                        }
                    </div>
                }) : null
            }
        </div>
    );
};

export default TabDevice;
