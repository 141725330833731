import React from "react";
import DatePicker from "react-datepicker/es";
import "react-datepicker/dist/react-datepicker.css";

import "./TicketRule.sass";

const TicketRule = (props) => {
  const { id, startDate, endDate, price, description } = props.rule;
  return (
    <div className="ticket-rule">
      <div className="ticket-rule__wrap">
        <button
          className="close"
          onClick={() => {
            props.remove(id);
          }}
        />
        <div className="ticket-rule__row">
          <div className="ticket-rule__block">
            <label>Start</label>
            <DatePicker
              selected={startDate}
              onChange={(value) => {
                props.onChange(id, "startDate", value);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              placeholderText="Select start date"
              dateFormat="h:mm a d MMM yyyy "
              timeCaption="time"
            />
          </div>
          <div className="ticket-rule__block">
            <label>End</label>
            <DatePicker
              selected={endDate}
              onChange={(value) => {
                props.onChange(id, "endDate", value);
              }}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              placeholderText="Select end date"
              dateFormat="h:mm a d MMM yyyy "
              timeCaption="time"
            />
          </div>
        </div>
        <div className="ticket-rule__row">
          <div className="ticket-rule__block">
            <label>Addition Cost</label>
            <input
              type="text"
              placeholder="Enter cost"
              value={price}
              onChange={(event) => {
                props.onChange(id, "price", event.target.value);
              }}
            />
          </div>
        </div>

        <div className="ticket-rule__row">
          <div className="ticket-rule__block">
            <label>Message</label>
            <textarea
              placeholder="Enter message"
              value={description}
              onChange={(event) => {
                props.onChange(id, "description", event.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketRule;
