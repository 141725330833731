import * as actionTypes from '../actionTypes/promotersTypes';

const initialState = {
    promotersList: [],
    promoterAuthInfo: {},
    loading: false,
    currentPromoter: null,
    error: null
};

const promoters = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROMOTERS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_PROMOTERS_SUCCESS:
            return {
                ...state,
                promotersList: action.payload,
                loading: false
            };
        case actionTypes.GET_PROMOTERS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_SUCCESS:
            return {
                ...state,
                promoterAuthInfo: action.payload,
                loading: false
            };
        case actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case actionTypes.SET_PROMOTER_SCANBIT_COST_START:
            return {
                ...state,
                loading: true,
                error: null
            };
        case actionTypes.SET_PROMOTER_SCANBIT_COST_SUCCESS:
            return {
                ...state,
                loading: false
            };
        case actionTypes.SET_PROMOTER_SCANBIT_COST_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
                currentPromoter: null
            };

        case actionTypes.GET_PROMOTER_START:
            return {
                ...state,
                loading: true,
                error: null,
                currentPromoter: null
            };
        case actionTypes.GET_PROMOTER_SUCCESS:
            return {
                ...state,
                currentPromoter: action.payload,
                loading: false
            };
        case actionTypes.GET_PROMOTER_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
                currentPromoter: null,
            };

        default:
            return state
    }
};

export default promoters;
