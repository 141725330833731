import React, {Component} from 'react';
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, ResponsiveContainer} from 'recharts';
import CustomTooltip from "../../../../../components/Statistics/CustomTooltip/CustomTooltip";
import './EventStatistics.sass';

export default class StatisticChart extends Component {

	render() {
		const {
			data,
			color,
			tooltipData,
			ticketName,
			teamName,
			deviceName,
			handleClickOnChart,
			selectedData,
            selectedPayload,
			isActive,
			handleChangeTab,
            activeTab,
            initialPayloadTicket,
            initialPayloadTeam,
            initialPayloadDevice
		} = this.props;
		const chartData = data.hasOwnProperty(activeTab) ? data[activeTab] : null;

		let lines = [];
		if (chartData) {
			const tmp = chartData[0];
			delete tmp.time;
			lines = Object.keys(tmp).map(value => {
				return (
					<Line
						key={value}
						type="monotone"
						dataKey={value}
						stroke={color(activeTab, value)}
						strokeWidth={3}
						r={0}
					/>);
			});
        }

        return (
            <>
                <div style={{ width: '100%', height: '500px' }} className={"statistic-chart"}>
                    <ResponsiveContainer width="100%">
                        <LineChart
                            data={chartData}
                            margin={{top: 100, right: 320, bottom: 50, left: 50}}

                            onClick={(data) => handleClickOnChart(data)}
                        >
                            <CartesianGrid vertical={false}/>
                            <XAxis dataKey="time"/>
                            <YAxis/>
                            <Tooltip
                                active={true}
                                content={
                                    <CustomTooltip
                                        initialPayloadTicket={initialPayloadTicket}
                                        initialPayloadTeam={initialPayloadTeam}
                                        initialPayloadDevice={initialPayloadDevice}
                                        isActiveByClick={isActive}
                                        handleChangeTab={handleChangeTab}
                                        tooltipData={tooltipData}
                                        getTicketName={ticketName}
                                        getTeamName={teamName}
                                        deviceName={deviceName}
                                        selectedTooltipData={selectedData}
                                        selectedPayload={selectedPayload}
                                    />
                                }
                            />
                            {lines}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
                <div style={{ width: '100%', height: '400px' }} className={"statistic-chart_mobile"}>
                    <ResponsiveContainer width="100%">
                        <LineChart
                            data={chartData}
                            margin={{top: 90, right: 50, bottom: 10, left: 10}}
                            onClick={(data) => handleClickOnChart(data)}
                        >
                            <CartesianGrid vertical={false}/>
                            <XAxis dataKey="time"/>
                            <YAxis/>
                            <Tooltip
                                active={true}
                                content={
                                    <CustomTooltip
                                        initialPayloadTicket={initialPayloadTicket}
                                        initialPayloadTeam={initialPayloadTeam}
                                        initialPayloadDevice={initialPayloadDevice}
                                        isActiveByClick={isActive}
                                        handleChangeTab={handleChangeTab}
                                        tooltipData={tooltipData}
                                        getTicketName={ticketName}
                                        getTeamName={teamName}
                                        deviceName={deviceName}
                                        selectedTooltipData={selectedData}
                                        selectedPayload={selectedPayload}
                                    />
                                }
                            />
                            {lines}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </>
		);
	}
}
