export function convertIfNumber(id) {
	return Number.isNaN(+id) ? id : +id;
}

export function setTicketDetails(oldObj, ticketId, teamId, device, inc) {
	const oldTeamObj = oldObj[ticketId] && oldObj[ticketId][teamId];
	return Object.assign({},
		oldObj,
		{
			[ticketId]: Object.assign(
				oldObj[ticketId] || {},
				{ [teamId]: {
						count: ((oldTeamObj && oldTeamObj.count) || 0) + inc,
						device: Object.assign({},
							oldTeamObj ? oldTeamObj.device : {},
							{ [device]: ((oldTeamObj && oldTeamObj.device[device]) || 0) + inc }
						)
					}}
			)
		}
	);
}

export function setTeamDetails(oldObj, ticketId, teamId, device, inc) {
	const oldTicketObj = oldObj[teamId] && oldObj[teamId][ticketId];
	return Object.assign({},
		oldObj,
		{
			[teamId]: Object.assign(
				oldObj[teamId] || {},
				{ [ticketId]: {
						count: ((oldTicketObj && oldTicketObj.count) || 0) + inc,
						device: Object.assign({},
							oldTicketObj ? oldTicketObj.device : {},
							{ [device]: ((oldTicketObj && oldTicketObj.device[device]) || 0) + inc }
						)
					}}
			)
		}
	)
}

export function setDeviceDetails(oldObj, ticketId, device, inc) {
	return Object.assign({},
		oldObj,
		{
			[device]: Object.assign(
				oldObj[device] || {},
				{ [ticketId]: ((oldObj[device] && oldObj[device][ticketId]) || 0) + inc }
			)
		}
	)
}

export function getNestedParameterCount(obj, firstId, secondId) {
	return (obj[firstId] && obj[firstId][secondId] && obj[firstId][secondId].count) || 0;
}

export function getDeviceCount(obj, firstId, secondId, device) {
	return (obj[firstId] && obj[firstId][secondId] && obj[firstId][secondId].device && obj[firstId][secondId].device[device]) || 0;
}

export function getTicketCount(obj, firstId, secondId, device) {
	return (obj[firstId] && obj[firstId][secondId] && obj[firstId][secondId].ticket && obj[firstId][secondId].ticket[device]) || 0;
}
