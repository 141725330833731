import StatisticDataModel from "./StatisticDataModel";

export default class EventDataModel {
	constructor(sellStatistic = new StatisticDataModel(), scanStatistic = new StatisticDataModel()) {
		this.sellStatistic = sellStatistic;
		this.scanStatistic = scanStatistic;
	}

	withUpdatedSellData(nextData) {
		return new EventDataModel(new StatisticDataModel(nextData), this.scanStatistic);
	}

	getSellStatisticForDay(key) {
		return this.sellStatistic.getNextDayData(key);
	}

	getSellStatisticDetailsDayData(key) {
		return this.sellStatistic.getNextDayDetails(key);
	}

	getSellDatesWithData() {
		return this.sellStatistic.getDates();
	}

	getSellTicketName(key) {
		return this.sellStatistic.getTicketName(key);
	}

	getSellTeamName(key) {
		return this.sellStatistic.getTeamName(key);
	}

	getSellDeviceName(key) {
		return this.sellStatistic.getDeviceName(key);
	}

	getSellTicketNamesArr() {
		return this.sellStatistic.getTicketNames();
	}

	getSellTeamNamesArr() {
		return this.sellStatistic.getTeamNames();
	}

	getSellDeviceNamesArr() {
		return this.sellStatistic.getDeviceNames();
	}

	withUpdatedScanData(nextData) {
		return new EventDataModel(this.sellStatistic, new StatisticDataModel(nextData));
	}

	getScanStatisticForDay(key) {
		return this.scanStatistic.getNextDayData(key);
	}

	getScanStatisticDetailsDayData(key) {
		return this.scanStatistic.getNextDayDetails(key);
	}

	getScanDatesWithData() {
		return this.scanStatistic.getDates();
	}

	getScanTicketName(key) {
		return this.scanStatistic.getTicketName(key);
	}

	getScanTeamName(key) {
		return this.scanStatistic.getTeamName(key);
	}

	getScanDeviceName(key) {
		return this.scanStatistic.getDeviceName(key);
	}

	getScanTicketNamesArr() {
		return this.scanStatistic.getTicketNames();
	}

	getScanTeamNamesArr() {
		return this.scanStatistic.getTeamNames();
	}

	getScanDeviceNamesArr() {
		return this.scanStatistic.getDeviceNames();
	}
}
