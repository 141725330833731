import React from "react";

import CheckBox from "../../components/UI/Checkbox/CheckBox";
import "./styles.sass";

const SingleCheckboxSelector = ({
  items,
  selectedValue,
  onChange,
  className,
}) => (
  <div className={className}>
    {items.map((x) => {
      const isSelected = x.value === selectedValue;
      const containerClass = !isSelected ? "disabled" : "";

      return (
        <div key={x.value} className={containerClass}>
          <CheckBox
            name={x.value}
            id={x.value}
            onChange={onChange}
            checked={isSelected}
          >
            {x.label}
          </CheckBox>
        </div>
      );
    })}
  </div>
);

export default SingleCheckboxSelector;
