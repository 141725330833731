import React, { Component } from 'react';
import moment from 'moment'

class EventsMobileTable extends Component {
    render() {
        const { events, goToEvent } = this.props;
        return (
            <div className="events-mobiile-table">
                {
                    events.map(event => (
                        <div className="events-mobiile-table__row"
                             key={event.id}
                             onClick={()=> {goToEvent(event.id)}}>
                            <div className="events-mobiile-table__left-col">
                                <div className="event-name">{ event.name }</div>
                                <div className="event-date">{ moment(event.startDate).format("DD MMMM YYYY HH:mm") }</div>
                            </div>
                            <div className="events-mobiile-table__right-col">
                                <div className="event-tickets">{ event.scannedTickets } / { event.allTickets }</div>
                                <div className="event-date">{ moment(event.endDate).format("DD MMMM YYYY HH:mm") }</div>
                            </div>
                        </div>
                    ))
                }
            </div>
        );
    }
}

export default EventsMobileTable;
