export const seasonPassMemberLangs = {
  en: {
    emailSent: {
      title: "Email Sent",
      about:
        "Please check your email. If you don't find the email, you can resend it and also check your “Spam” folder",
      btnReset: "Resent Email",
      btnChange: "Change Email",
    },
    emailConfirmation: {
      title: "Email Confirmation",
      about:
        "We just need one small favour from you - please confirm your email address",
      btnConfirm: "Confirm Email",
      inputEmailPlaceholder: "Enter email",
      inputStreetAndHouseNumberPlaceholder: "Enter Street and House Number",
      inputCityPlaceholder: "Enter City",
      inputPostalCodePlaceholder: "Enter Postal Code",
      inputEmailErrorMessage: "This is an invalid email",
    },
  },
  rs: {
    emailSent: {
      title: "E-mail je poslat",
      about: "Molimo Vas da proverite vaš e-mail. Ukoliko ne pronađete e-mail, proverite svoj “Spam” folder ili pošaljite ponovo klikom na dugme ispod: Ponovo pošaljite e-mail.",
      btnReset: "Ponovo pošaljite e-mail",
      btnChange: "Promeni e-mail adresu",
    },
    emailConfirmation: {
      title: "Potvrdite e-mail adresu",
      about: "Ukoliko ste član Kluba ili želite to da postanete, unesite svoju mail adresu sa kojom ste registrovani ili ćete se registrovati.",
      btnConfirm: "Potvrdi email",
      inputEmailPlaceholder: "Upišite mejl adresu",
      inputStreetAndHouseNumberPlaceholder: "Adresa stanovanja i broj",
      inputCityPlaceholder: "Grad",
      inputPostalCodePlaceholder: "Poštanski broj",
      inputEmailErrorMessage: "Upisana je netačna mejl adresa",
    },
  },
};
