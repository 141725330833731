import React from 'react';

import Upload from '../../../containers/Upload/Upload';

import './FirstTab.sass';
import { getCustomURLs } from '../../../helpers/getCustomURLs';

const { SERVER_URL } = getCustomURLs();

const FirstTab = props =>
    <div className="first-tab">
        <div className="download">
            <form
                action={SERVER_URL + "/FilesExamples/Tickets_template.csv"}
                method="GET">
                <button>Download template</button>
            </form>
        </div>

        <div className="choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload onButtonEnable={props.onButtonEnable} />

        </div>
    </div>;

export default FirstTab;
