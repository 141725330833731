import React, { useContext } from "react";

import { ActionsMenuContext } from "../store/ActionsMenuProvider";

export const useShowActionsMenu = () => {
  const actionsMenuCtx = useContext(ActionsMenuContext);

  const showMenu = ({ items, minMenuHeight, offsetY, offsetX = 0 }) => {
    return (event) => {
      const element = event.currentTarget;
      const rect = element.getBoundingClientRect();

      const spaceBelow = window.innerHeight - rect.bottom;
      const spaceRight = window.innerWidth - rect.right;

      let position;

      if (spaceBelow >= minMenuHeight) {
        position = { top: rect.bottom + offsetY, right: spaceRight - offsetX };
      } else {
        position = {
          bottom: spaceBelow + rect.height + offsetY,
          right: spaceRight - offsetX,
        };
      }

      actionsMenuCtx.showMenu({
        items,
        position,
      });
    };
  };

  return [showMenu, actionsMenuCtx.hideMenu];
};
