export default async function getDimensionsFromImage({ imageObject, context }) {
  if (!context || !imageObject.imageElement) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  return {
    x: imageObject.x,
    y: imageObject.y,
    width: imageObject.imageElement.naturalWidth,
    height: imageObject.imageElement.naturalHeight,
  };
}
