import React from "react";

import { connect } from "react-redux";
import {
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT,
  DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  DEFAULT_CANVAS_FOOTER_MB_OBJECT,
  DEFAULT_CANVAS_FOOTER_PIB_OBJECT,
  DEFAULT_CANVAS_FOOTER_LINK_OBJECT,
} from "../../../constants/canvas";
import {
  deleteCanvasObject,
  setActiveObjectId,
  setUserMode,
} from "../../../store/actions/canvasActions";
import { IconButton } from "../../UI/IconButton/IconButton";

const prettyType = (type) => {
  switch (type) {
    case "qrCode":
      return "QR Code";
    default:
      return type;
  }
};

const CanvasObjectsControl = ({
  canvasObjects,
  activeObjectId,
  setUserMode,
  deleteCanvasObject,
  setActiveObjectId,
  hasFooter
}) => {
  const handleDelete = (id) => () => {
    deleteCanvasObject(id);
    setUserMode("select");
  };

  const handleSetActive = (id) => () => {
    setActiveObjectId(id);
  };

  return (
    <ul className="canvas-objects-control">
      {[...canvasObjects].map(({ type, id }) => {
        if (type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type && !hasFooter) {
          return null;
        };

        if (
          type === DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT.type ||
          type === DEFAULT_CANVAS_FOOTER_NAME_OBJECT.type ||
          type === DEFAULT_CANVAS_FOOTER_MB_OBJECT.type ||
          type === DEFAULT_CANVAS_FOOTER_PIB_OBJECT.type ||
          type === DEFAULT_CANVAS_FOOTER_LINK_OBJECT.type
        ) {
          return null;
        };

        let className = "canvas-objects-control__item";
        const isFooterImage = type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type;

        if (activeObjectId === id) {
          className += " canvas-objects-control__item_active";
        }

        if (isFooterImage) {
          className += " canvas-objects-control__item_disabled";
        }

        return (
          <li
            key={id}
            onClick={!isFooterImage && handleSetActive(id)}
            className={className}
          >
            {!isFooterImage ? prettyType(type) : "Footer"}
            {!isFooterImage && (
              <IconButton onClick={handleDelete(id)} iconName="rubbish-bin" />
            )}
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state) => {
  const { canvas } = state;

  return {
    canvasObjects: canvas.canvasObjects,
    activeObjectId: canvas.activeObjectId,
    hasFooter: canvas.currentTemplate.hasFooter,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setUserMode: (mode) => dispatch(setUserMode(mode)),
  setActiveObjectId: (id) => dispatch(setActiveObjectId(id)),
  deleteCanvasObject: (id) => dispatch(deleteCanvasObject(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CanvasObjectsControl);
