import React, {Component} from 'react';
import './ListEmptyState.sass';

import EmptyStateImage from '../../../images/pic_empty_scanteams.svg'

class ListEmptyState extends Component {
    render() {
        return (
            <div className='scanbits-empty-state'>
                <h2 className="scanbits-empty-state__title">There are no scanteams</h2>
                <img src={EmptyStateImage} alt="No scanteams"/>
            </div>
        );
    }
}

export default ListEmptyState;