import React from "react";

import "./Counter.sass";

export const Counter = ({
  label,
  min = 1,
  max = 999,
  step = 1,
  value,
  onValueChange,
}) => {
  const minNumber = parseFloat(min);
  const maxNumber = parseFloat(max);
  const stepNumber = parseFloat(step);

  const incrementHandler = () => {
    const valueNumber = Number(value);
    onValueChange(
      valueNumber + stepNumber <= maxNumber
        ? valueNumber + stepNumber
        : maxNumber
    );
  };

  const decrementHandler = () => {
    const valueNumber = Number(value);
    onValueChange(
      valueNumber - stepNumber >= minNumber
        ? valueNumber - stepNumber
        : minNumber
    );
  };

  const valueChangeHandler = ({ target: { value } }) => {
    let newValue = "";

    if (value !== "") {
      const parsedValue = parseInt(value);

      if (Number.isFinite(parsedValue)) {
        if (parsedValue > maxNumber) {
          newValue = maxNumber;
        } else if (parsedValue < minNumber) {
          newValue = minNumber;
        } else {
          newValue = parsedValue;
        }
      }
    }

    onValueChange(newValue);
  };

  return (
    <div className="counter">
      <span>{label}</span>
      <div className="counter-content">
        <div className="minus counter-btn" onClick={decrementHandler} />
        <input type="text" onChange={valueChangeHandler} value={value} />
        <div className="plus counter-btn" onClick={incrementHandler} />
      </div>
    </div>
  );
};
