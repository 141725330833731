import React from 'react';

import './MobileHeader.sass';

const mobileHeader = ({children}) => (
    <div className="mobile-header">
        {children}
    </div>
);

export default mobileHeader;
