import React, { Component } from "react";
import { connect } from "react-redux";

import Select from "react-select";
import DatePicker from "react-datepicker/es";
import queryString from "query-string";

import axios from "../../axios-instance";

import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";

import browserTabTitleOverride from "../../helpers/tabTitleOverride";

import "./SatsScan.sass";
import moment from "moment";
import AsyncSelect from "react-select/lib/Async";
import CheckBox from "../../components/UI/Checkbox/CheckBox";
import { getScanteamsAsyncFunc } from "../../helpers/getScanteamsAsyncFunc";
import { getTicketTemplatesAsyncFunc } from "../../helpers/getTicketTemplatesAsyncFunc";
import { getReportByScanteamAtPeriodGroupedByTime } from "../../store/actions/statisticsActions";
import { Graphic } from "./Graphic";
import Table from "./Table";
import { getHeaders } from "../../helpers/getHeaders";
import { loadFile } from "../../helpers/loadFile";

class SatsScan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fromTimeUtc: moment().toISOString(),
      toTimeUtc: moment().toISOString(),
      groupByMinutes: 10,
      scanteams: [],
      ticketTempalteIds: [],
      groupByTime: false,
      reportRows: [],
      data: null,
      isLoading: false,
      isDisabled: false,
    };
  }

  componentDidMount() {
    this.getData();
    browserTabTitleOverride();
  }

  handleShareData = async () => {
    const token = this.props.user.token;

    const { isLoading, groupByTime, data, isDisabled, reportRows, ...state } =
      this.state;
    const request = {
      data: JSON.stringify({ ...state, token }),
      password: "1",
      expiredAt: moment().add("years", 1).toISOString(),
    };
    const { data: key } = await axios.post("SharedData", request, {
      headers: getHeaders(token),
    });

    navigator.clipboard.writeText(
      `${window.location.href}?sharedDataId=${key}`
    );
  };

  getData = async () => {
    try {
      this.setState({ isLoading: true });

      const {
        fromTimeUtc,
        toTimeUtc,
        groupByMinutes,
        ticketTempalteIds,
        scanteams,
      } = this.state;

      let token = this.props.user.token;
      const { sharedDataId } = queryString.parse(this.props.location.search);

      let req = {
        fromTimeUtc: moment(fromTimeUtc).toISOString(),
        toTimeUtc: moment(toTimeUtc).toISOString(),
        groupByMinutes,
        scanteams: scanteams.length
          ? scanteams.map(({ value }) => value)
          : null,
        ticketTempalteIds: ticketTempalteIds.length
          ? ticketTempalteIds.map(({ value }) => value)
          : null,
      };

      if (!token || sharedDataId) {
        this.setState({ isDisabled: true });
        const { data } = await axios.get(
          `/SharedData/${sharedDataId}?password=1`
        );
        this.setState({ ...data });
        token = data.token;
        delete data.token;
        req = { ...req, ...data };
      }

      const { data } =
        await this.props.getReportByScanteamAtPeriodGroupedByTime(req, token);

      const reportRows = data.reportRows.reduce((acc, curr) => {
        curr.ticketTemplates.forEach((item) => {
          const existingIndex = acc.findIndex(
            (elem) => elem.ticketName === item.ticketName
          );
          if (existingIndex !== -1) {
            acc[existingIndex].dailyTicketScans += item.dailyTicketScans;
            acc[existingIndex].seasonPassScans += item.seasonPassScans;
            acc[existingIndex].totalScans += item.totalScans;
          } else {
            acc.push({ ...item });
          }
        });
        return acc;
      }, []);

      this.setState({ isLoading: false, data, reportRows });
    } catch (e) {
      console.log(e);
    }
  };

  handleChange = (name, value) => {
    this.setState(
      {
        [name]: value,
      },
      this.getData
    );
  };

  render() {
    const {
      fromTimeUtc,
      groupByMinutes,
      toTimeUtc,
      scanteams,
      ticketTempalteIds,
      isDisabled,
      groupByTime,
      data,
      reportRows,
      isLoading,
    } = this.state;

    const minutesOption = [
      { value: 10, label: "10min" },
      { value: 30, label: "30min" },
      { value: 60, label: "1h" },
    ];

    if (!data && !isLoading) {
      return null;
    }

    return (
      <Container className="stats-scan">
        <div className="stats-scan__fields-group">
          <DatePicker
            selected={fromTimeUtc}
            onChange={(value) => this.handleChange("fromTimeUtc", value)}
            showTimeSelect
            timeFormat="HH:mm"
            disabled={isDisabled}
            timeIntervals={15}
            dateFormat="h:mm a d MMM yyyy "
            timeCaption="time"
          />
          <DatePicker
            selected={toTimeUtc}
            onChange={(value) => this.handleChange("toTimeUtc", value)}
            showTimeSelect
            disabled={isDisabled}
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="h:mm a d MMM yyyy "
            timeCaption="time"
          />
          <Select
            value={minutesOption.find(({ value }) => value === groupByMinutes)}
            options={minutesOption}
            isDisabled={isDisabled}
            onChange={(value) =>
              this.handleChange("groupByMinutes", value.value)
            }
            classNamePrefix="custom-select"
            className="custom-select"
          />
          <AsyncSelect
            isDisabled={isDisabled}
            cacheOptions
            defaultOptions
            isMulti
            placeholder="Select scan teams"
            loadOptions={getScanteamsAsyncFunc()}
            value={scanteams}
            onChange={(value) => this.handleChange("scanteams", value)}
            classNamePrefix="custom-select"
            className="custom-select"
          />
          {/* <AsyncSelect
            cacheOptions
            defaultOptions
            isMulti
            placeholder="Select ticket types"
            loadOptions={getTicketTemplatesAsyncFunc()}
            value={ticketTempalteIds}
            onChange={(value) => this.handleChange("ticketTempalteIds", value)}
            classNamePrefix="custom-select"
            className="custom-select"
          /> */}
        </div>
        {!isDisabled && (
          <button
            className="download-report__button"
            style={{ marginTop: "20px" }}
            onClick={this.handleShareData}
          >
            Copy Report
          </button>
        )}
        <div className="stats-scan__graphic-warpper">
          <PageTitle notAbsolute>Tickets Scans</PageTitle>
          <Graphic data={data ? data.reportRows : []} loading={isLoading} />
        </div>

        {/* <CheckBox
          checked={groupByTime}
          id="groupByTime"
          name="groupByTime"
          onChange={(evt) =>
            this.handleChange("groupByTime", evt.target.checked)
          }
        >
          <span>Group by time</span>
        </CheckBox> */}
        <Table
          data={groupByTime ? data.reportRows : reportRows}
          loading={isLoading}
          groupByTime={groupByTime}
        />
      </Container>
    );
  }
}

const mapStateToProps = ({ auth: { user } }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => ({
  getReportByScanteamAtPeriodGroupedByTime: (request, token, asCsv) =>
    dispatch(getReportByScanteamAtPeriodGroupedByTime(request, token, asCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SatsScan);
