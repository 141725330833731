import compareDesc from "date-fns/compareDesc";
import {convertIfNumber} from "../../../helpers/converterObj";

export default class StatisticDataModel {
	constructor(nextData = {dataMap: new Map(), detailsMap: new Map(), ticketNamesMap: new Map(), teamNamesMap: new Map(), devicesNamesMap: new Map()}) {
		this.sortedDataArr = nextData.dataMap;
		this.sortedDataDetails = nextData.detailsMap;
		this.ticketNamesMap = nextData.ticketNamesMap;
		this.teamNamesMap = nextData.teamNamesMap;
		this.devicesNamesMap = nextData.devicesNamesMap;
	}

	getDates() {
		return Array.from(this.sortedDataArr.keys()).sort((a, b) => compareDesc(new Date(a), new Date(b)));
	}

	getNextDayData(data = "") {
		if (data === "") {
			return {};
		}

		const dataPerDay = this.sortedDataArr.get(data);
		return {
			ticket: Array.from(dataPerDay.tickets.values()),
			team: Array.from(dataPerDay.teams.values()),
			device: Array.from(dataPerDay.devices.values())
		};
	}

	getNextDayDetails(data = "") {
		if (data === "") {
			return {};
		}

		const detailsPerDay = this.sortedDataDetails.get(data);
		return {
			ticket: detailsPerDay.tickets,
			team: detailsPerDay.teams,
			device: detailsPerDay.devices
		};
	}

	getTicketName(key) {
		const ticketId = convertIfNumber(key);
		return key ? this.ticketNamesMap.get(ticketId) : "";
	}

	getTeamName(key) {
		const teamId = convertIfNumber(key);
		return key ? this.teamNamesMap.get(teamId) : "";
	}

	getDeviceName(key) {
		return key ? this.devicesNamesMap.get(key) : "";
	}

	getTicketNames() {
		return Array.from(this.ticketNamesMap.keys());
	}

	getTeamNames() {
		return Array.from(this.teamNamesMap.keys());
	}

	getDeviceNames() {
		return Array.from(this.devicesNamesMap.keys());
	}
}