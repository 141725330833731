import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCanvasObject } from "../../../store/actions/canvasActions";
import FieldLabel from "../../FieldLabel/FieldLabel";
import PageTitle from "../../UI/PageTitle/PageTitle";

class BorderRadiusControl extends Component {
  handleRadiusChange = (event) => {
    const { activeObject, updateCanvasObject } = this.props;
    const radius = event.target.value;

    updateCanvasObject(activeObject.id, {
      borderRadius: radius,
    });
  };

  render() {
    const { activeObject } = this.props;

    if (!activeObject) {
      return null;
    }

    return (
      <FieldLabel className="control-field" label="Border Radius">
        <input
          type="number"
          value={activeObject.borderRadius}
          onChange={this.handleRadiusChange}
        />
      </FieldLabel>
    );
  }
}

const mapStateToProps = (state) => {
  const { canvas } = state;
  const activeObject = canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  );
  return {
    activeObject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCanvasObject: (id, object) => dispatch(updateCanvasObject(id, object)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BorderRadiusControl);
