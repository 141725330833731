export const getCustomURLs = () => {
  const url = window.location.origin;

  if (process.env.REACT_APP_ENV_NAME === "development") {
    switch (url) {
      case "http://localhost:3000":
        return ({
          SERVER_URL: "https://dev-api.fanceetickets.com",
          API_URL: "https://dev-api.fanceetickets.com/v1",
          //API_URL: "https://localhost:5001/v1",
          PUBLIC_URL: url,
        });

      case "https://dev-app.fanceetickets.com":
        return ({
          SERVER_URL: "https://dev-api.fanceetickets.com",
          API_URL: "https://dev-api.fanceetickets.com/v1",
          PUBLIC_URL: url,
        });

      case "https://dev-app.sixtix.com": // require input correct data
        return ({
          SERVER_URL: "https://dev-api.sixtix.com", // require input correct data
          API_URL: "https://dev-api.sixtix.com/v1", // require input correct data
          PUBLIC_URL: url, // require input correct data
        });

      case "https://dev-app.headworks.com.ua":
      case "http://dev-app.headworks.com.ua": // require input correct data
        return ({
          SERVER_URL: "http://dev-api.headworks.com.ua", // require input correct data
          API_URL: "http://dev-api.headworks.com.ua/v1", // require input correct data
          PUBLIC_URL: url, // require input correct data
        });

      case "https://dev-app.france.fr": // require input correct data
        return ({
          SERVER_URL: "https://dev-api.france.com", // require input correct data
          API_URL: "https://dev-api.france.com/v1", // require input correct data
          PUBLIC_URL: url, // require input correct data
        });

      default:
        return ({
          SERVER_URL: "https://dev-api.fanceetickets.com",
          API_URL: "https://dev-api.fanceetickets.com/v1",
          PUBLIC_URL: url,
        });
    }
  } else if (process.env.REACT_APP_ENV_NAME === "production") {
    switch (url) {
      case "https://app.fanceetickets.com":
        return ({
          SERVER_URL: "https://api.fanceetickets.com",
          API_URL: "https://api.fanceetickets.com/v1",
          PUBLIC_URL: url,
        });

      // case "sixtix":
      //   return ({
      //     SERVER_URL: "https://api.fanceetickets.com",
      //     API_URL: "https://api.fanceetickets.com/v1",
      //     PUBLIC_URL: "https://app.fanceetickets.com",
      //   });

      // case "france":
      //   return ({
      //     SERVER_URL: "https://api.fanceetickets.com",
      //     API_URL: "https://api.fanceetickets.com/v1",
      //     PUBLIC_URL: "https://app.fanceetickets.com",
      //   });

      default:
        return ({
          SERVER_URL: "https://api.fanceetickets.com",
          API_URL: "https://api.fanceetickets.com/v1",
          PUBLIC_URL: url,
        });
    }
  }
};
