export const forEachElementsByClassName = (className, func) =>
  document.querySelectorAll(`.${className}`).forEach(func);

export const makeElementsReadonly = (className) =>
  forEachElementsByClassName(className, (e) => {
    const element = e.childNodes[0];

    if (element) {
      element.setAttribute("readOnly", true);
    }
  });
