import React from "react";
import Icon from "../UI/Icon/Icon";

import uncheckedSvg from "./images/unchecked.svg";

import "./Stepper.sass";

export const Stepper = ({
  steps = [],
  currentStep,
  headerBgStyle = {},
  headerComponent = null,
  actionComponent = null,
  headerWrapperClass = "",
}) => {
  return (
    <div className="stepper">
      <div style={headerBgStyle} className={headerWrapperClass}>
        <div className="stepper__header">
          {steps.map(({ title }, index) => (
            <StepperHeader
              key={title + index}
              title={title}
              currentStep={currentStep}
              step={index + 1}
            />
          ))}
        </div>
        {headerComponent}
      </div>
      {steps.map(({ component }, index) => (
        <Step key={index} currentStep={currentStep} step={index + 1}>
          {component}
        </Step>
      ))}
      {actionComponent}
    </div>
  );
};

export const StepperHeader = ({ title, currentStep, step }) => {
  const renderStepIcon = () => {
    switch (true) {
      case currentStep > step:
        return <Icon name="stepper-done-step" />;
      case currentStep === step:
        return <Icon name="stepper-current-step" />;
      case currentStep < step:
        return <img src={uncheckedSvg} alt="Next Step" />;
      default:
        return null;
    }
  };

  return (
    <div
      className={`stepper__header-title ${
        currentStep >= step ? "stepper__header_active" : ""
      }`}
    >
      <div
        className={`stepper__header-indicator ${
          currentStep >= step ? "stepper__header-indicator_active" : ""
        }`}
      />
      <div className="stepper__header-hero">
        {/* eslint-disable-next-line jsx-a11y/alt-text */}
        {renderStepIcon()}
        <h5>{title}</h5>
      </div>
    </div>
  );
};

export const Step = ({ currentStep, step, children }) => {
  const style = currentStep !== step ? { display: "none" } : {};

  return (
    <div style={style} className="stepper__step">
      {children}
    </div>
  );
};
