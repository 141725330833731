export const GET_PROMOTERS_START = 'GET_PROMOTERS_START';
export const GET_PROMOTERS_SUCCESS = 'GET_PROMOTERS_SUCCESS';
export const GET_PROMOTERS_FAIL = 'GET_PROMOTERS_FAIL';

export const GET_PROMOTER_START = 'GET_PROMOTER_START';
export const GET_PROMOTER_SUCCESS = 'GET_PROMOTER_SUCCESS';
export const GET_PROMOTER_FAIL = 'GET_PROMOTER_FAIL';

export const SET_PROMOTER_SCANBIT_COST_START = 'SET_PROMOTER_SCANBIT_COST_START';
export const SET_PROMOTER_SCANBIT_COST_SUCCESS = 'SET_PROMOTER_SCANBIT_COST_SUCCESS';
export const SET_PROMOTER_SCANBIT_COST_FAIL = 'SET_PROMOTER_SCANBIT_COST_FAIL';

export const BAN_PROMOTER_START = 'BAN_PROMOTER_START';
export const BAN_PROMOTER_SUCCESS = 'BAN_PROMOTER_SUCCESS';
export const BAN_PROMOTER_FAIL = 'BAN_PROMOTER_FAIL';

export const SIGN_IN_PROMOTER_AS_ADMIN_START = 'SIGN_IN_PROMOTER_AS_ADMIN_START';
export const SIGN_IN_PROMOTER_AS_ADMIN_SUCCESS = 'SIGN_IN_PROMOTER_AS_ADMIN_SUCCESS';
export const SIGN_IN_PROMOTER_AS_ADMIN_FAIL = 'SIGN_IN_PROMOTER_AS_ADMIN_FAIL';
