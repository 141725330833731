import { paymentMethods } from "../../containers/SeasonPass/constants";
import axios from "../../axios-instance.js";
import { TransactionCreationRequest, TransactionSeatModel } from "./paymentModels.js";
import { isNoSeatTicket, isPlainSeat, isSeatsIoTicket } from "../ticketHelper.ts";
import { getCurrencieLabel } from "../../constants/currencies.js";
import crypto from "crypto-js";
import { getTokenFormPaymentApi } from "../getTokenFormPaymentApi.js";

const mapSeatIo = ({
    category: { label, key },
    labels: { section, parent, own },
    pricing: { price },
    originalPrice,
    eventData,
}): TransactionSeatModel => {
    const [area, side] = section.split("-");
    const ticketTemplate = eventData.ticketTemplates.find(
        ({ categoryKey }) => +categoryKey === +key
    );
    const ticketTemplateId = ticketTemplate && ticketTemplate.ticketTemplateId;

    if (!originalPrice) {
        originalPrice = price;
    }

    return {
        ticketTemplateId: ticketTemplateId,
        ticketName: label,
        area: area,
        row: parent,
        seat: own,
        side: side,
        amount: price,
        fixedDiscount: +(+originalPrice - +price).toFixed(2),
    };
};

const mapSeat = (seats, ticket, i, coupon) => {
    const { ticketTemplateId, count } = ticket;

    for (let j = 0; j < count; j++) {
        let fixedDiscount = 0;
        if (coupon && (!coupon.ticketTypeIds || coupon.ticketTypeIds.length === 0)) {
            fixedDiscount = coupon.fixedDiscount;
        }
        else if (coupon && coupon.ticketTypeIds && coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length > 0 && coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length <= count && j < coupon.ticketTypeIds.filter((v) => (v === ticketTemplateId)).length) {
            fixedDiscount = coupon.fixedDiscount;
        }

        if (!fixedDiscount) {
            fixedDiscount = 0;
        }

        if (!ticket.price) {
            ticket.price = 0;
        }

        if (!ticket.originalPrice) {
            ticket.originalPrice = ticket.price;
        }

        const seat = {
            ticketTemplateId: ticketTemplateId,
            discount: (i == 0 && j < 2 && coupon) ? coupon.discount : 0,
            fixedDiscount: +(fixedDiscount + +ticket.originalPrice - +ticket.price).toFixed(2),
        };

        seats.push(seat);
    }

    return seats;
};

export interface PaymentRequest {
    paymentMethod: number,
    paymentType: number,
    couponCode: string,
    redirectUrl: string,
    eventData: any,
    coupon: any,
    transaction: {
        id?: string,
        fullName: string,
        physicalAddress: string,
        city: string,
        postalCode: string,
        phoneNumber: string,
        buyerEmail: string,
        birthday: string,
        gender: string,
        companyName: string,
        companyVatNumber: string,
    },
    tickets: any[],
}

export class CreateTransactionResponse {
    transactionId: string;
    invoiceId: string;
    paymentUrl: string;

    constructor(transactionId: string, invoiceId: string, paymentUrl: string) {
        this.transactionId = transactionId;
        this.invoiceId = invoiceId;
        this.paymentUrl = paymentUrl;
    }
}

export const createTransaction = async (paymentInfo: PaymentRequest) => {

    const seats = paymentInfo.tickets
        .filter(
            t => isSeatsIoTicket(t)).map((ticket) => mapSeatIo({ ...ticket, eventData: paymentInfo.eventData }))
        .concat(paymentInfo.tickets.filter(t => isNoSeatTicket(t)).reduce((result, item, i) => {
            return mapSeat(result, item, i, paymentInfo.coupon);
        }, [])).concat(paymentInfo.tickets.filter(t => isPlainSeat(t)));

    const request: TransactionCreationRequest = {
        paymentProvider: paymentInfo.paymentMethod,
        successUrl: paymentInfo.redirectUrl,
        transaction: {
            id: paymentInfo.transaction.id,
            amount: 0,
            currency: getCurrencieLabel(paymentInfo.eventData.currencyId),
            fullName: paymentInfo.transaction.fullName,
            physicalAddress: paymentInfo.transaction.physicalAddress,
            city: paymentInfo.transaction.city,
            postalCode: paymentInfo.transaction.postalCode,
            phoneNumber: paymentInfo.transaction.phoneNumber,
            buyerEmail: paymentInfo.transaction.buyerEmail,
            birthday: paymentInfo.transaction.birthday,
            gender: paymentInfo.transaction.gender,

            companyEmail: '',
            companyName: paymentInfo.transaction.companyName,
            companyAddress: '',
            companyCity: '',
            companyPostalCode: '',
            companyCountry: '',
            buyerCountry: '',
            companyVatNumber: paymentInfo.transaction.companyVatNumber,

            paymentType: paymentInfo.paymentType,
            couponCode: paymentInfo.couponCode,
            seats: seats,
            tagNames: [],
        }
    }

    const { data } = await axios.post("Transaction/createForSell", request);

    return new CreateTransactionResponse(
        data.transactionId,
        data.invoiceId,
        data.paymentUrl,
    );
}

export const executePayment = async (request: PaymentRequest) => {
    switch (request.paymentMethod) {
        case paymentMethods.LedPay:
            await executeLedPay(request);
            break;

        case paymentMethods.PaySpot:
            await executePaySpot(request);
            break;

        case paymentMethods.PayNl:
            await executePayNl(request);
            break;

        default:
            break;
    }
}

async function executeLedPay(request: PaymentRequest) {
    const { invoiceId } = await createTransaction(request);

    const formData = await getLedPayInvoiceData(request, invoiceId);

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_self');

    for (const [key, value] of Object.entries<any>(formData)) {
        const input = document.createElement('input');
        input.setAttribute('name', key);
        input.setAttribute('value', value);
        form.append(input);
    }

    form.setAttribute('action', process.env.REACT_APP_PAYMENT_LINK || '');

    document.getElementsByTagName('body')[0].append(form);

    form.submit();
}

async function executePaySpot(request: PaymentRequest) {
    const { invoiceId } = await createTransaction(request);

    const formData = await getPayspotInvoiceData(invoiceId);

    const form = document.createElement('form');
    form.setAttribute('method', 'post');
    form.setAttribute('target', '_self');

    for (const [key, value] of Object.entries<any>(formData)) {

        if (key === 'actionUrl'
            || key === 'invoiceId'
        ) {
            continue;
        }

        const input = document.createElement('input');
        input.setAttribute('name', key.toUpperCase());
        input.setAttribute('value', value);
        form.append(input);
    }

    form.setAttribute('action', formData.actionUrl);

    document.getElementsByTagName('body')[0].append(form);

    form.submit();
}

async function executePayNl(request: PaymentRequest) {
    const { paymentUrl } = await createTransaction(request);

    window.open(paymentUrl, '_self');
}

async function getPayspotInvoiceData(invoiceId) {
    const { data } = await axios.get(`Payment/paymentInfo/${invoiceId}`);

    return data.paySpot;
}

async function getLedPayInvoiceData(request: PaymentRequest, invoiceId: string) {
    const { data } = await axios.get(`Payment/paymentInfo/${invoiceId}`);

    const token = await getTokenFormPaymentApi({ merchantId: request.eventData.merchantId });

    console.log(token.data.access_token);

    const ledRequest = {
        ...data.ledPay,
        accessToken: token.data.access_token,
        merchantSig: '',
    }

    ledRequest.merchantSig = createMerchantSin(ledRequest, ledRequest.signedKeys);

    return ledRequest;
}


export const createMerchantSin = (pairs, pairsKeys = "") => {
    let keys = pairsKeys.split(",");
    keys = keys.sort();

    const signData = keys;

    keys.forEach((key) => {
        const val = pairs[key].toString();
        signData.push(val.replace(/\\/g, "\\\\").replace(/:/g, "\\:"));
    });

    return crypto.enc.Base64.stringify(
        crypto.HmacSHA256(signData.join(":"), crypto.enc.Hex.parse(process.env.REACT_APP_HMAC_KEY || ''))
    );
};