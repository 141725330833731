export const GET_SCANTEAMS_START = 'GET_SCANTEAMS_START';
export const GET_SCANTEAMS_SUCCESS = 'GET_SCANTEAMS_SUCCESS';
export const GET_SCANTEAMS_FAIL = 'GET_SCANTEAMS_FAIL';

export const GET_SCANTEAM_START = 'GET_SCANTEAM_START';
export const GET_SCANTEAM_SUCCESS = 'GET_SCANTEAM_SUCCESS';
export const GET_SCANTEAM_FAIL = 'GET_SCANTEAM_FAIL';

export const DELETE_SCANTEAM_START = 'DELETE_SCANTEAM_START';
export const DELETE_SCANTEAM_SUCCESS = 'DELETE_SCANTEAM_SUCCESS';
export const DELETE_SCANTEAM_FAIL = 'DELETE_SCANTEAM_FAIL';

export const GET_ALL_EVENTS_START = 'GET_ALL_EVENTS_START';
export const GET_ALL_EVENTS_SUCCESS = 'GET_ALL_EVENTS_SUCCESS';
export const GET_ALL_EVENTS_FAIL = 'GET_ALL_EVENTS_FAIL';

export const CREATE_SCANTEAM_START = 'CREATE_SCANTEAM_START';
export const CREATE_SCANTEAM_SUCCESS = 'CREATE_SCANTEAM_SUCCESS';
export const CREATE_SCANTEAM_FAIL = 'CREATE_SCANTEAM_FAIL';

export const UPDATE_SCANTEAM_START = 'UPDATE_SCANTEAM_START';
export const UPDATE_SCANTEAM_SUCCESS = 'UPDATE_SCANTEAM_SUCCESS';
export const UPDATE_SCANTEAM_FAIL = 'UPDATE_SCANTEAM_FAIL';

export const REFRESH_ACCESS_START = 'REFRESH_ACCESS_START';
export const REFRESH_ACCESS_SUCCESS = 'REFRESH_ACCESS_SUCCESS';
export const REFRESH_ACCESS_FAIL = 'REFRESH_ACCESS_FAIL'

export const GET_USER_INFO_SETTINGS_START = 'GET_USER_INFO_SETTINGS_START';
export const GET_USER_INFO_SETTINGS_SUCCESS = 'GET_USER_INFO_SETTINGS_SUCCESS';
export const GET_USER_INFO_SETTINGS_FAIL = 'GET_USER_INFO_SETTINGS_FAIL';

export const CREATE_USER_INFO_SETTINGS_START = 'CREATE_USER_INFO_SETTINGS_START';
export const CREATE_USER_INFO_SETTINGS_SUCCESS = 'CREATE_USER_INFO_SETTINGS_SUCCESS';
export const CREATE_USER_INFO_SETTINGS_FAIL = 'CREATE_USER_INFO_SETTINGS_FAIL'

export const DELETE_USER_INFO_SETTINGS_START = 'DELETE_USER_INFO_SETTINGS_START';
export const DELETE_USER_INFO_SETTINGS_SUCCESS = 'DELETE_USER_INFO_SETTINGS_SUCCESS';
export const DELETE_USER_INFO_SETTINGS_FAIL = 'DELETE_USER_INFO_SETTINGS_FAIL';
