import React from 'react';
import '../../containers/Promoters/Promoters.sass';

const PromotersMobileTable = ({ promoters, signInPromoterAsAdmin, handleBanPromoter }) => {

    return (
        <div className="promoters-mobile-table">
            {
                promoters.map(promoter => (
                    <div className="promoters-mobile-table__row"
                        key={promoter.id}
                    >
                        <div className="promoters-mobile-table__row-top">
                            <div className="promoters-mobile-table__left-col">
                                <div className="promoter-name">{promoter.name}</div>
                                <div className="promoter-email">{promoter.email}</div>
                                <div className="promoter-phone">{promoter.phone}</div>
                            </div>
                            <div className="promoters-mobile-table__right-col">
                                <div className="spent-scanbits">
                                    {promoter.scanbitsBalance} / {promoter.spentScanbits}
                                </div>
                            </div>
                        </div>
                        <div className="promoters-mobile-table__row-bottom">
                            <button
                                className={`${promoter.banStatus === 2 ? 'btn-green' : 'btn-red'}`}
                                onClick={e => handleBanPromoter(e, promoter)}>
                                {promoter.banStatus === 2 ? 'Unblock' : 'Block'}
                            </button>
                            <button className="btn-black" onClick={e => signInPromoterAsAdmin(e, promoter)}>Sign in</button>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
export default PromotersMobileTable;
