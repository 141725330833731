import React from 'react';

import './MoblieList.sass';
import Icon from '../../UI/Icon/Icon';
import moment from 'moment';

const MobileList = ({items, goToScanTeamPage}) => {
    function toggle(e) {
        e.target.parentNode.parentNode.parentNode.classList.toggle('active');
    }

    return (
        <div className="scant-teams-mobile-list">
            {items.map(item =>
                <div className="scant-teams-mobile-list__item"
                     key={item.id}
                     onClick={e => (goToScanTeamPage(item.id, e))}
                >
                    <div className="scant-teams-mobile-list__item__header">
                        <h3>{item.name}</h3>
                        <button className="btn-default" onClick={toggle}><Icon
                            name={'chevron'}/></button>
                    </div>

                    {item.teamEvents.map((event, index) => (
                        <div className="scant-teams-mobile-list__block" key={event.name + index}>
                            <h4 className="scant-teams-mobile-list__block__title">
                                {event.name}
                            </h4>
                            <div className="scant-teams-mobile-list__block__dates">
                                <span>{moment(event.startDate).format("DD/m/YY HH:MM")}</span>
                                <span>{moment(event.endDate).format("DD/m/YY HH:MM")}</span>
                            </div>

                            <p className="scant-teams-mobile-list__item__statements">
                                <span>Ability to search:</span>
                                <span>{item.isSearchAvailable ? 'Yes' : 'No'}</span>
                            </p>
                            <p className="scant-teams-mobile-list__item__statements">
                                <span>Mark as unscanned:</span>
                                <span>{item.isUnscanAvailable ? 'Yes' : 'No'}</span>
                            </p>
                            <p className="scant-teams-mobile-list__item__statements">
                                <span>Sales:</span>
                                <span>{item.teamSellAbilityType === 2 ? 'Yes' : 'No'}</span>
                            </p>
                            <p className="scant-teams-mobile-list__item__statements">
                                <span>Scan:</span>
                                <span>{item.isScanAvailable ? 'Yes' : 'No'}</span>
                            </p>

                            <ul>
                                { item.teamTicketTemplates.map(ticket => {
                                    return <li key={ticket.id}>{event.id === ticket.eventId ? ticket.ticketName : null}</li>
                                })}
                            </ul>
                        </div>
                    ))}
                </div>)
            }
        </div>
    );
};

export default MobileList;
