import React from 'react';

const TabTicketInitial = ({ payload, getName, getTeamName, data }) => {

	return (
		<div className="chart-info">
			{payload ?
				payload && payload.map(item => {
					const keyTime = item.payload["time"] ? item.payload["time"].slice(0, 2) : "00";
					const dayData = data.get(keyTime)[item.name];

					return <div className="chart-item" key={item.name}>
						<div className="chart-item__wrapper">
							<div className="chart-item__label">
								<div className="chart-item__indicator" style={{backgroundColor: item.color}}/>
								<p className="chart-item__name">
									{ getName(item.name) || 'No data' }
								</p>
							</div>
							<p className="chart-item__value">{item.value !== 0 ? item.value : '0'}</p>
						</div>
						{
							Object.keys(dayData).map(teamId => {
								const devices = Object.keys(dayData[teamId].device).map(deviceId => {
									return (
										<div className="chart-item__wrapper" key={deviceId}>
											<p className="chart-item__device-name">{deviceId === "web_client" ? "Uploaded" : `Device ${deviceId}`}</p>
											<p className="chart-item__device-name">{dayData[teamId].device[deviceId]}</p>
										</div>
									);
								});
								return [
									<div className="chart-item__wrapper" key={teamId}>
										<p className="chart-item__team-name">{getTeamName(teamId)}</p>
										<p className="chart-item__team-name">{dayData[teamId].count}</p>
									</div>,
									devices
								];
							})
						}
					</div>
				}) : <>No tickets data selected</>
			}
		</div>
	);
};

export default TabTicketInitial;
