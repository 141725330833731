import * as actionTypes from '../actionTypes/scanteamsTypes';

const initialState = {
    scanteamsList: [],
    events: [],
    userInfoSettings: [],
    loading: false,
};

const scanteams = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SCANTEAMS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_SCANTEAMS_SUCCESS:
            return {
                ...state,
                scanteamsList: action.payload,
                loading: false
            };
        case actionTypes.GET_SCANTEAMS_FAIL:
            return {
                ...state,
                error: action.error,
                loading: false,
            };

        case actionTypes.DELETE_SCANTEAM_START:
            return {
                ...state,
                loading: true
            };

        case actionTypes.DELETE_SCANTEAM_SUCCESS:
            return {
                ...state,
                scanteamsList: [],
                loading: false
            };
        case actionTypes.DELETE_SCANTEAM_FAIL:
            return {
                ...state,
                scanteamsList: [],
                loading: false
            };


        case actionTypes.GET_ALL_EVENTS_START:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.GET_ALL_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload,
                loading: true,
            };
        case actionTypes.GET_ALL_EVENTS_FAIL:
            return {
                ...state,
                loading: true,
            };

        case actionTypes.GET_USER_INFO_SETTINGS_START:
                return {
                    ...state,
                    userInfoSettings: [],
                    loading: true,
                }
        case actionTypes.GET_USER_INFO_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfoSettings: action.payload,
            }
        case actionTypes.GET_USER_INFO_SETTINGS_FAIL:
            return {
                ...state,
                loading: false,
            }

        case actionTypes.CREATE_USER_INFO_SETTINGS_START:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.CREATE_USER_INFO_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                userInfoSettings: [...state.userInfoSettings, action.payload]
            }
        case actionTypes.CREATE_USER_INFO_SETTINGS_FAIL:
            return {
                ...state,
                loading: false,
            }

        case actionTypes.DELETE_USER_INFO_SETTINGS_START:
            return {
                ...state,
                loading: true,
            }
        case actionTypes.DELETE_USER_INFO_SETTINGS_SUCCESS:
            return {
                ...state,
                userInfoSettings: [...state.userInfoSettings].filter(setting => setting.id !== action.payload.id),
                loading: false,
            }
        case actionTypes.DELETE_USER_INFO_SETTINGS_FAIL:
            return {
                ...state,
                loading: false,
            }

        default:
            return state
    }
};

export default scanteams;
