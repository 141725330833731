import React, { Component } from "react";
import { connect } from "react-redux";
// import ActionsControl from "./controls/ActionsControl";
import BackgroundControl from "./controls/BackgroundControl";
import BorderRadiusControl from "./controls/BorderRadiusControl";

import FrameControl from "./controls/FrameControl";
import ImageControl from "./controls/ImageControl";
import LayerControl from "./controls/LayerControl";
import OpacityControl from "./controls/OpacityControl";
import TemplateControl from "./controls/TemplateControl";
import TextControl from "./controls/TextControl";

import "./CanvasSidebar.sass";
import CanvasObjectsControl from "./controls/CanvasObjectsControl";
import { IconButton } from "../UI/IconButton/IconButton";
import Icon from "../UI/Icon/Icon";
import NewLoader from "../NewLoader/NewLoader";

export class CanvasSidebar extends Component {
  state = {
    isMinimized: false,
  };
  shouldComponentUpdate(nextProps) {
    const { activeObject: newActiveObject } = nextProps;
    const { activeObject } = this.props;

    const isActiveObjectTypeSame =
      activeObject &&
      newActiveObject &&
      activeObject.type === newActiveObject.type;

    switch (true) {
      case isActiveObjectTypeSame:
        return false;
    }
    return true;
  }

  toggleMinimized = () => {
    this.setState(({ isMinimized }) => ({ isMinimized: !isMinimized }));
  };
  render() {
    const {
      userMode,
      actionMode,
      activeObject,
      canvasObjectsEmpty,
      isLoading,
    } = this.props;

    const { isMinimized } = this.state;

    const activeNodes = {
      icons: userMode === "icon",
      images: userMode === "image",
      frame: !!activeObject,
      align: !!activeObject,
      strokeWidth:
        !!activeObject &&
        activeObject.type !== "text" &&
        activeObject.type !== "icon" &&
        activeObject.type !== "image",
      opacity: !!activeObject,
      layer: true,
      actions: !!activeObject,
    };

    if (activeObject && activeObject.type) {
      activeNodes.backgroundColor =
        activeObject.type === "rectangle" ||
        activeObject.type === "ellipse" ||
        activeObject.type === "icon";

      activeNodes.text = activeObject.type === "text";

      activeNodes.borderRadius = activeObject.type === "rectangle";

      activeNodes.strokeColor =
        activeObject.type !== "text" &&
        activeObject.type !== "icon" &&
        activeObject.type !== "image" &&
        (activeObject.strokeWidth || 0) > 0;
    }

    const hasActiveNodes = Object.values(activeNodes).some((value) => value);

    if (!hasActiveNodes || (actionMode && actionMode.type === "isDrawing")) {
      return null;
    }

    if (isMinimized) {
      return (
        <div className="canvas-sidebar minimized">
          <button
            className="btn-primary canvas-sidebar__btn_minimized"
            onClick={this.toggleMinimized}
          >
            <Icon name={"chevron"} />
          </button>
        </div>
      );
    }

    return (
      <div className="canvas-sidebar">
        {isLoading && (
          <div className="canvas-sidebar__owerlay">
            <NewLoader />
          </div>
        )}
        <div className="canvas-sidebar__btn-minimized-wrapper">
          <button
            className="btn-primary canvas-sidebar__btn_minimized"
            onClick={this.toggleMinimized}
          >
            <Icon name={"chevron"} />
          </button>
        </div>
        <TemplateControl
          contextRef={this.props.contextRef}
          canvasRef={this.props.canvasRef}
          windowSize={this.props.windowSize}
        />
        {activeNodes.layer && <LayerControl />}
        {!canvasObjectsEmpty && <CanvasObjectsControl />}
        {activeNodes.images && (
          <ImageControl contextRef={this.props.contextRef} />
        )}
        {activeNodes.frame && <FrameControl />}
        <div className="canvas-sidebar__control-wrapper">
          {activeNodes.backgroundColor && <BackgroundControl />}
          {activeNodes.borderRadius && <BorderRadiusControl />}
          {activeNodes.opacity && <OpacityControl />}
        </div>
        {activeNodes.text && <TextControl />}
        {/* {activeNodes.actions && <ActionsControl />} */}
        {/* {activeNodes.icons && <IconControl />} */}
        {/* {activeNodes.strokeWidth && <StrokeWidthControl />} */}
        {/* {activeNodes.strokeColor && <StrokeColorControl />} */}
        {/* {activeNodes.align && <AlignControl />} */}
      </div>
    );
  }
}

const mapStateToProps = ({
  canvas: { activeObjectId, userMode, actionMode, canvasObjects, isLoading },
}) => {
  const activeObject = canvasObjects.find(
    (object) => object.id === activeObjectId
  );

  return {
    isLoading,
    userMode,
    actionMode,
    activeObject,
    canvasObjectsEmpty: !canvasObjects.length,
  };
};

export default connect(mapStateToProps)(CanvasSidebar);
