import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import MobileHeader from "../../components/MobileHeader/MobileHeader";
import Container from "../../components/UI/Container/Container";
import browserTabTitleOverride from "../../helpers/tabTitleOverride";
import { getScanbitsCount } from "../../store/actions/paymentsActions";
import Payment from "./Payment/Payment";
import "./Payments.sass";
import Scanbits from "./Scanbits/Scanbits";

class Payments extends Component {
  componentDidMount() {
    browserTabTitleOverride();

    const user = localStorage.getItem("user");

    if (user) {
      const { token } = JSON.parse(user);

      this.props.getScanbitsCount(token);
    }
  }

  state = {
    disabledButton: true,
  };

  handleDisabledButton = () => {
    this.setState({ disabledButton: false });
  };

  render() {
    return (
      <Container className="payments">
        <MobileHeader>Payment</MobileHeader>
        <div className="wrapper">
          <Tabs>
            <TabList>
              <Tab>
                <div className="tab-shadow-one-side" />
                <div className="tab-shadow-top-side" />
                <div className="page-title">Payment</div>
              </Tab>
              <Tab>
                <div className="tab-shadow-one-side" />
                <div className="tab-shadow-top-side" />
                <div className="page-title">Buy scanbits</div>
              </Tab>
            </TabList>
            <TabPanel>
              <Payment
                history={this.props.history}
                handleDisabledButton={this.handleDisabledButton}
              />
            </TabPanel>
            <TabPanel>
              <Scanbits disabledButton={this.state.disabledButton} />
            </TabPanel>
          </Tabs>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  getScanbitsCount: (token) => getScanbitsCount(token),
};

const ConnectedPayments = connect(
  mapStateToProps,
  mapDispatchToProps
)(Payments);

export default withRouter(ConnectedPayments);
