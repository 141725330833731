import * as actionTypes from '../actionTypes/barCodesTypes';

const initialState = {
    providers: [],
    loading: false,
    providerConfig: null
};

const barCodes = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROVIDERS_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                providers: action.payload,
                loading: false
            };
        case actionTypes.GET_PROVIDERS_FAIL:
            return {...state, loading: true};

        case actionTypes.UPLOAD_TICKETS_TABLE_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.UPLOAD_TICKETS_TABLE_SUCCESS:
            return {
                ...state,
                barcodesTable: action.payload,
                loading: false
            };
        case actionTypes.UPLOAD_TICKETS_TABLE_FAIL:
            return {...state, loading: true};
        
        case actionTypes.GET_PROVIDER_CONFIG_START:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_PROVIDER_CONFIG_SUCCESS:
            return {
                ...state,
                providerConfig: action.payload,
                loading: false
            };
        case actionTypes.GET_PROVIDER_CONFIG_FAIL:
            return {
                ...state,
                loading: false
            }

        default:
            return state
    }
};

export default barCodes;
