import React from "react";
import { NavLink } from "react-router-dom";

import Container from "../UI/Container/Container";
import Icon from "../UI/Icon/Icon";

import "./Scanbits.sass";
import connect from "react-redux/es/connect/connect";
import ROUTES from "../../constants/routes";

const Scanbits = ({ scanbitsCount }) => {
  if (
    window.location.pathname === "/promoters" ||
    window.location.pathname === ROUTES.DASHBOARD
  ) {
    return null;
  } else {
    return (
      <Container>
        <NavLink to={ROUTES.PAYMENTS}>
          <div className="scanbits">
            <div className="scanbits__body">
              <Icon name="scanbit" />
              {scanbitsCount >= 1000000
                ? Math.round(scanbitsCount / 1000) + "K"
                : scanbitsCount}{" "}
              Scanbits
            </div>
            <div className="scanbits__shadow" />
          </div>
        </NavLink>
      </Container>
    );
  }
};

const mapStateToProps = ({ payments: { scanbitsCount } }) => ({
  scanbitsCount,
});

export default connect(mapStateToProps)(Scanbits);
