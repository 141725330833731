import React, { Component, Fragment } from "react";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import Page404 from "../../../BarCodes/BarCodes";

import {
  sendResalePurposes,
} from "../../../../store/actions/eventsActions";

import "../NewEvent.sass";
import PleaseWaitModal from "../../../BarCodes/modals/PleaseWaitModal";
import { executeAsyncWithModal } from "../../../../helpers/modal";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class EventReselling extends Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: null,
      endDate: null,
      currentEvent: {
        eventEmails: {
          emailTicketTemplateId: 0,
          emailOfferTemplateId: 0,
          emailGuestTemplateId: 0,
          emailErrorTemplateId: 0,
          emailInvoiceTemplateId: 0,
        },
      },
      isDisabled: true,
      isFound: true,
      scannedTicketsStartDate: null,
      scannedTicketsEndDate: null,
      isImportActive: false,
      ticketToSync: null,
      qrCodeVisibilityHours: 0,
    };
  };

  sendResalePurposes = () => {
    const { id } = this.props.currentEvent;

    MySwal.fire({
      title: "Are you sure you want to send reselling offers?",
      text: "Users will receive resale offer emails",
      confirmButtonText: "Send",
      cancelButtonText: "Cancel",
      showCancelButton: true,
      showConfirmButton: true,
      reverseButtons: true,
      customClass: "warning-modal",
    }).then((result) => {
      if (result.value) {
        executeAsyncWithModal(
          () => this.props.sendResalePurposes(id),
          <PleaseWaitModal message="Preparing sending reselling offers...." />
        );
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div
            className="add-event__column"
            style={{
              alignItems: "flex-end",
            }}
          >
            <div className="add-event__block">
              <button
                type="button"
                className="btn-primary btn-primary--reselling"
                onClick={this.sendResalePurposes}
              >
                Send reselling offers
              </button>
            </div>
          </div>
      </Fragment>
    );
  }
};

const mapStateToProps = ({
  events: { currentEvent },
}) => ({ currentEvent });

const mapDispatchToProps = (dispatch) => ({
  sendResalePurposes: (eventId) => dispatch(sendResalePurposes(eventId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EventReselling);
