import React, { useState } from "react";
import { SeatsioSeatingChart } from "@seatsio/seatsio-react";

import { SelectedTickets } from "../SelectedTickets";
import NewLoader from "../../../components/NewLoader/NewLoader";
import { TicketsPicker } from "../TicketsPicker";

import "../OpenSell.sass";
import { getCurrentLanguageTexts } from "../../../constants/seasonPassPersonalInfoLangs";

export const SelectTickets = ({
  tickets,
  setCouponHandler,
  coupon,
  total,
  ticketTemplates,
  onObjectSelected,
  currency,
  pricing,
  onObjectDeselected,
  feeRate,
  fixedFeeRate,
  totalFee,
  loading,
  workspaceKey,
  eventSeatsId,
  event,
  isOffers,
  maxSelectedObjects = 4,
  hasSeats,
  channels = ['NO_CHANNEL'],
  offers = [],
  showInfo = false,
  reservations = [],
  multiSelectEnabled = false,
  onSetChart = (chart) => null,
  allowEnterCouponCode = true,
  allowManualDiscount = false,
  manualDiscount = 0,
  setManualDiscount = () => null,
  popupMode = false,
}) => {
  const [chart, setChart] = useState(null);
  const txt = getCurrentLanguageTexts();

  const renderSelectSection = () => {
    switch (hasSeats) {
      case false:
        return (
          <TicketsPicker
            onObjectSelected={onObjectSelected}
            onObjectDeselected={onObjectDeselected}
            ticketTemplates={ticketTemplates}
            currency={currency}
          />
        );
      case true:

        const onSeatSelected = (params) => {
          onObjectSelected(params);
        }

        return (
          <SeatsioSeatingChart
            channels={channels}
            maxSelectedObjects={maxSelectedObjects}
            pricing={pricing}
            session="continue"
            priceFormatter={(price) => `${price} ${currency}`}
            onChartRendered={c => { onSetChart(c); setChart(c) }}
            onObjectSelected={onSeatSelected}
            onObjectDeselected={onObjectDeselected}
            workspaceKey={workspaceKey}
            event={eventSeatsId}
            region="eu"
            multiSelectEnabled={multiSelectEnabled}
            objectWithoutPricingSelectable={false}
            tooltipInfo={(o) => {
              if (!showInfo) {
                return
              }

              const isOfferChannel = reservations.some(r => r.label === 'Offers' && o.isInChannel(r.channelKey));

              let info = '';

              info += `${reservations.reduce((a, i) => {
                return a + ((o.status == 'free' && o.isInChannel(i.channelKey)) ? `[b]${i.label}[/b][br/]` : ``);
              }, '')}`

              if (info) {
                info += '[br/]'
              }

              if (o.extraData) {
                if ('email' in o.extraData) {
                  info += `${o.extraData.email}[br/]`
                }

                if ('transaction_payment_type' in o.extraData) {
                  const type = o.extraData.transaction_payment_type;
                  info += `[b]${false
                    || type == 1 && 'Online'
                    || type == 2 && 'By cash'
                    || type == 3 && 'By pin'
                    || type == 4 && 'As generated'
                    || type == 5 && 'As guest'
                    || type == 6 && 'By invoice'
                    || type == 7 && 'Uploaded'
                    || ''
                    }[/b][br/]`
                }

                if ('ticket_type' in o.extraData) {
                  const type = o.extraData.ticket_type;
                  info += `[b]${type}[/b][br/]`
                }
              }
              else if (isOfferChannel) {
                const offer = offers.find(of => of.seatLabel === o.label);
                if (offer) {
                  info += `[b]Offered to:[/b] ${offer.fullName}[br/]${offer.email} `
                }
              }

              return info
            }
            }
          />
        );
      default:
        return null;
    }
  };

  if (isOffers) {
    return (
      <div className="partizan-open-selling__step-info">
        {loading ? (
          <div style={{ height: "550px" }}>
            <NewLoader />
          </div>
        ) : (
          <SelectedTickets
            hasSeats={hasSeats}
            totalFee={totalFee}
            tickets={tickets}
            currency={currency}
            total={total}
            event={event}
            mixedTickets={true}
            setCouponHandler={setCouponHandler}
            allowEnterCouponCode={allowEnterCouponCode}
            coupon={coupon}
          />
        )}
      </div>
    );
  }

  return (
    <div className="partizan-open-selling__step partizan-open-selling__select-tickets">
      <div className={`partizan-open-selling__step-hero ${popupMode ? 'select-ticket-popup-mode' : ''}`}>
        <h4 className="partizan-open-selling__text-bold">
          {hasSeats ? txt.sellPageTxt.chooseYourSeatOnMap : txt.sellPageTxt.tickets}
        </h4>
        <div className={`partizan-open-selling__select-section ${hasSeats ? "partizan-open-selling__select-section_no-scroll" : ""}`}>
          {renderSelectSection()}
        </div>
      </div>
      <div className={`partizan-open-selling__step-info ${popupMode ? 'select-ticket-popup-mode' : ''}`}>
        <SelectedTickets
          hasSeats={hasSeats}
          totalFee={totalFee}
          tickets={tickets}
          currency={currency}
          total={total}
          event={event}
          mixedTickets={true}
          setCouponHandler={setCouponHandler}
          ticketAdd={onObjectSelected}
          ticketRemove={onObjectDeselected}
          coupon={coupon}
          allowEnterCouponCode={allowEnterCouponCode}
          allowManualDiscount={allowManualDiscount}
          manualDiscount={manualDiscount}
          setManualDiscount={setManualDiscount}
          popupMode={popupMode}
        />
      </div>
    </div>
  );
};
