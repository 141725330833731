import React from "react";
import moment from "moment";

import Icon from "../../../components/UI/Icon/Icon";

import "./SellingEventsCard.sass";

export const SellingEventsCard = ({
  emailHeaderUrl,
  startDate,
  place,
  name,
  prefixLabel,
  handleClick,
}) => {
  return (
    <div className="selling-events-card" onClick={handleClick}>
      <img alt="Event" src={emailHeaderUrl} />
      <div className="selling-events-card__hero">
        <div>
          <h6>{prefixLabel}</h6>
          <h4>{name}</h4>
        </div>

        <div>
          <div className="selling-events-card__info">
            <Icon name="map-pin-fill" />
            <span>{place}</span>
          </div>
          <div className="selling-events-card__info">
            <Icon name="clock-dark" />
            <span>{moment(startDate).format("DD.MM.YYYY HH:mm")}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
