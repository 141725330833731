import React from "react";

export const getSeatDescription = (seat) => {
  let description = <>{seat.area}-{seat.side}-{seat.row}-{seat.seat}</>;

  /* if (seat.area) {
    description = (
      <>
        {description}Area - <b>{seat.area}</b>
      </>
    );
  }

  if (seat.row) {
    description = (
      <>
        {description}, Row - <b>{seat.row}</b>
      </>
    );
  }

  if (seat.seat) {
    description = (
      <>
        {description}, Seats - <b>{seat.seat}</b>
      </>
    );
  }

  if (seat.side) {
    description = (
      <>
        {description}, Place - <b>{seat.side}</b>
      </>
    );
  } */

  if (seat.ticketTemplate.ticketName) {
    description = <b>{seat.ticketTemplate.ticketName }, {description} </b>;
  }

  return description;
};
