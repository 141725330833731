import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";
import ls from 'localstorage-slim';

import styles from './BalancePage.module.sass';
import Loader from "react-loaders";
import ReactTable from "react-table";
import { loadFile } from "../../helpers/loadFile";

const BalancePage = () => {
    const [state, setState] = useState({
        isLoaded: false,
        balance: 0,
        isPaynlAccount: false,
        name: 'Promoter'
    });

    const token = JSON.parse(localStorage.getItem("user")).token;

    useEffect(() => {
        (async () => {
            const balanceCache = ls.get('balance_cache')

            if (!balanceCache) {
                const data = await axios.get('/PromoterBalance', {
                    headers: getHeaders(token),
                })

                setState(v => {
                    return {
                        ...v,
                        ...data.data,
                        isLoaded: true,
                    }
                });

                ls.set('balance_cache', {
                    ...state,
                    ...data.data,
                    isLoaded: true,
                }, { ttl: 60 })
            }
            else {
                setState(balanceCache)
            }
        })()
    }, []);

    const downloadReportHandler = async () => {

        const res = await axios.get('/PromoterBalance/report',
            {
                headers: {
                    ...getHeaders(ls.get("user").token),
                    "Content-Type": "application/pdf",
                    Accept: "application/pdf"
                },
                responseType: "blob",
            }
        );

        loadFile(res.data, 'promoterBalanceReport.pdf',
            {
                type: "application/pdf"
            }
        );

    };

    const columns = [
        {
            Header: "Amount",
            accessor: "amount",
            resizable: false,
            width: 220,
            Cell: ({ row: { _original: { amount } } }) => (
                <span title={amount}>{amount} EUR</span>
            ),
        },
        {
            Header: "Requested",
            accessor: "requested",
            resizable: false,
            width: 220,
            Cell: ({ row: { _original: { requested } } }) => {
                const date = new Date(requested)

                return <span title={requested}>{date.toLocaleDateString()}</span>
            },
        },
    ]

    return <Container className="transactions membership-managment">
        <PageTitle>Balance</PageTitle>

        <section>
            {state.isLoaded
                ? <section>

                    <section className={styles['balances']}>

                        <div className={styles['balance-label']}>
                            <p>Available Balance</p>
                            <p>{(state.clearedPayout / 100.0).toFixed(2)} EUR</p>
                        </div>

                        <div className={styles['balance-label']}>
                            <p>Pending Income</p>
                            <p>{(state.pendingIncome / 100.0).toFixed(2)} EUR</p>
                        </div>

                        <div className={styles['balance-label']}>
                            <p>Refunded</p>
                            <p>{(state.refunded / 100.0).toFixed(2)} EUR</p>
                        </div>

                        <button className="btn-default"
                            onClick={downloadReportHandler}>
                            Download report
                        </button>

                    </section>

                    <h2 className={styles['clearing-table-title']}>Clearing history</h2>
                    <ReactTable
                        data={state.clearings}
                        columns={columns}
                        currentPageNumber={0}
                        pageSize={2} />

                </section>
                : <Loader />
            }
        </section>

    </Container>
}

export default withRouter(BalancePage)