import * as actionTypes from "../actionTypes/transactionsTypes";

const initialState = {
  transactionsList: [],
  transactionsTotal: 0,
  transactionsLoading: false,
  firstTransactionsLoading: true,
  error: {},
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRANSACTIONS_START:
      return {
        ...state,
        transactionsTotal: 0,
        transactionsLoading: true,
      };
    case actionTypes.GET_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        transactionsList: [...action.payload.list],
        transactionsTotal: action.payload.total,
        transactionsLoading: false,
        firstTransactionsLoading: false,
      };
    case actionTypes.GET_TRANSACTIONS_FAIL:
      return {
        ...state,
        error: action.payload,
        transactionsTotal: 0,
        transactionsLoading: false,
      };
    case actionTypes.APPROVE_TRANSACTION: {
      const currentState = state;

      const transactionIndex = currentState.transactionsList.findIndex(
        (transaction) => transaction.id === action.payload
      );

      currentState.transactionsList[transactionIndex].paymentStatus = 1;

      return { ...currentState };
    }
    case actionTypes.CANCEL_OFFER_TRANSACTION:
    case actionTypes.DELETE_SEATS_TRANSACTION:
    case actionTypes.EDIT_TRANSACTION: {
      const currentState = state;

      const transactionIndex = currentState.transactionsList.findIndex(
        (transaction) => transaction.id === action.payload.id
      );

      currentState.transactionsList[transactionIndex] = action.payload;

      return { ...currentState };
    }
    case actionTypes.DELETE_TRANSACTION: {
      const currentState = state;

      currentState.transactionsList = currentState.transactionsList.filter(
        (transaction) => transaction.id !== action.payload
      );

      return { ...currentState };
    }
    case actionTypes.CLEAR_TRANSACTIONS:
      return { ...initialState };
    default:
      return state;
  }
};

export default transactions;
