import React from "react";
import { connect } from "react-redux";
import {
  decrementZoom,
  incrementZoom,
} from "../../store/actions/canvasActions";
import { IconButton } from "../UI/IconButton/IconButton";

import "./CanvasZoom.sass";

function CanvasZoom({ zoom, incrementZoom, decrementZoom }) {
  return (
    <div className="canvas-zoom">
      <IconButton
        iconName="plus"
        onClick={() => {
          incrementZoom(5);
        }}
      />
      <p>{`${Math.trunc(Math.abs(zoom))}%`}</p>
      <IconButton
        iconName={"minus"}
        onClick={() => {
          decrementZoom(5);
        }}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  const { canvas } = state;

  return {
    zoom: canvas.zoom,
  };
};

const mapDispatchToProps = (dispatch) => ({
  incrementZoom: (zoom) => dispatch(incrementZoom(zoom)),
  decrementZoom: (zoom) => dispatch(decrementZoom(zoom)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CanvasZoom);
