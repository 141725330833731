import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import {
  getTemplateTextValues,
  setDefaultParams,
  updateCanvasObject,
} from "../../../store/actions/canvasActions";
import ColorPicker from "../../ColorPicker/ColorPicker";
import FieldLabel from "../../FieldLabel/FieldLabel";
import { IconButton } from "../../UI/IconButton/IconButton";

const fontsOptions = [
  {
    value: "sans-serif",
    label: "sans-serif",
  },
  {
    value: "FS Sinclair Trial",
    label: "FS Sinclair Trial",
  },
  {
    value: "SF Pro Display",
    label: "SF Pro Display",
  },
  {
    value: "Open Sans",
    label: "Open Sans",
  },
  {
    value: "Helvetica Neue",
    label: "Helvetica Neue",
  },
];

class TextControl extends Component {
  state = {
    textValue: null,
  };

  componentDidMount() {
    this.props.getTemplateTextValues();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.activeObject.id !== this.props.activeObject.id ||
      prevProps.textValues !== this.props.textValues
    ) {
      this.setTextValue();
    }
  }

  setTextValue = () => {
    const {
      activeObject: { text },
      textValues,
    } = this.props;

    const textValue = textValues.find(({ value }) => text === value);

    if (!!textValue) {
      this.setState({ textValue });
      return;
    }

    this.setState({ textValue: null });
  };

  handleTextChange = (event) => {
    const { activeObject, updateCanvasObject } = this.props;
    const newText = event.target.value;

    updateCanvasObject(activeObject.id, { text: newText });
  };

  handleTextValueChange = (value) => {
    const { activeObject, updateCanvasObject } = this.props;

    this.setState({ textValue: value });
    updateCanvasObject(activeObject.id, { text: value.value });
  };

  handleTextAlignHorizontal = (textAlignHorizontal) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { textAlignHorizontal });
  };

  handleTextAlignVertical = (textAlignVertical) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { textAlignVertical });
  };

  handleFontFamilyChange = (value) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { fontFamily: value });
  };

  handleFontColorChange = (newColor) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { fontColorHex: newColor });
    // setDefaultParams({ fontColorHex: newColor });
  };

  handleFontWeightChange = () => {
    const { activeObject, updateCanvasObject } = this.props;
    const newFontWeight =
      activeObject.fontWeight === "normal" ? "bold" : "normal";
    updateCanvasObject(activeObject.id, { fontWeight: newFontWeight });
  };

  handleFontStyleChange = () => {
    const { activeObject, updateCanvasObject } = this.props;
    const newFontStyle =
      activeObject.fontStyle === "normal" ? "italic" : "normal";
    updateCanvasObject(activeObject.id, { fontStyle: newFontStyle });
  };

  handleFontVariantChange = () => {
    const { activeObject, updateCanvasObject } = this.props;
    const newFontVariant =
      activeObject.fontVariant === "normal" ? "small-caps" : "normal";
    updateCanvasObject(activeObject.id, { fontVariant: newFontVariant });
  };

  handleFontSizeChange = (newFontSize) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { fontSize: newFontSize });
  };

  handleLineHeightChange = (newLineHeight) => {
    const { activeObject, updateCanvasObject } = this.props;
    updateCanvasObject(activeObject.id, { fontLineHeightRatio: newLineHeight });
  };

  render() {
    const { activeObject, isTextValuesLoading, textValues } = this.props;
    const { textValue } = this.state;

    if (!activeObject) {
      return null;
    }

    return (
      <div className="text-control">
        <h5 className="control-title">Text</h5>
        <FieldLabel className="control-field" label="Text">
          <input
            disabled={!!textValue}
            value={activeObject.text}
            onChange={this.handleTextChange}
            className="text-control__text-input"
          />
        </FieldLabel>
        <FieldLabel className="control-field" label="Text Value">
          <Select
            isLoading={isTextValuesLoading}
            options={textValues}
            value={textValue}
            onChange={this.handleTextValueChange}
            classNamePrefix="custom-select"
            className="custom-select"
          />
        </FieldLabel>
        <FieldLabel className="control-field" label="Text Color">
          <ColorPicker
            colorShowType={"circle"}
            value={activeObject.fontColorHex}
            onChange={this.handleFontColorChange}
            inputType="input"
          />
        </FieldLabel>
        <FieldLabel className="control-field" label="Font Family">
          <Select
            options={fontsOptions}
            value={fontsOptions.find(
              ({ value }) => value === activeObject.fontFamily
            )}
            onChange={(value) => {
              this.handleFontFamilyChange(value.value);
            }}
            classNamePrefix="custom-select"
            className="custom-select"
          />
        </FieldLabel>
        <FieldLabel className="control-field" label="Font Size">
          <input
            value={activeObject.fontSize}
            onChange={(e) => this.handleFontSizeChange(e.target.value)}
            className="text-control__range"
          />
        </FieldLabel>
        <FieldLabel className="control-field" label="Line Height">
          <input
            value={activeObject.fontLineHeightRatio}
            onChange={(e) => this.handleLineHeightChange(e.target.value)}
            className="text-control__range"
          />
        </FieldLabel>

        <FieldLabel
          className="control-field"
          label="Text style"
          shouldWrapInLabel={false}
        >
          <div className="text-control__fonts-styles">
            <IconButton
              onClick={this.handleFontWeightChange}
              iconName="text-style-bold"
              className={
                activeObject.fontWeight === "bold"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={this.handleFontStyleChange}
              iconName="text-style-italic"
              className={
                activeObject.fontStyle === "italic"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={this.handleFontVariantChange}
              iconName="text-style-small-caps"
              className={
                activeObject.fontVariant === "small-caps"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
          </div>
        </FieldLabel>

        <FieldLabel
          className="control-field"
          label="Text align"
          shouldWrapInLabel={false}
        >
          <div className="text-control__fonts-styles">
            <IconButton
              onClick={() => this.handleTextAlignHorizontal("left")}
              iconName="text-align-horizontal-left"
              className={
                activeObject.textAlignHorizontal === "left"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={() => this.handleTextAlignHorizontal("center")}
              iconName="text-align-horizontal-center"
              className={
                activeObject.textAlignHorizontal === "center"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={() => this.handleTextAlignHorizontal("right")}
              iconName="text-align-horizontal-right"
              className={
                activeObject.textAlignHorizontal === "right"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
          </div>
        </FieldLabel>

        <FieldLabel
          className="control-field"
          label="Vertical align"
          shouldWrapInLabel={false}
        >
          <div className="text-control__fonts-styles">
            <IconButton
              onClick={() => this.handleTextAlignVertical("top")}
              iconName="text-align-vertical-top"
              className={
                activeObject.textAlignVertical === "top"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={() => this.handleTextAlignVertical("middle")}
              iconName="text-align-vertical-middle"
              className={
                activeObject.textAlignVertical === "middle"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
            <IconButton
              onClick={() => this.handleTextAlignVertical("bottom")}
              iconName="text-align-vertical-bottom"
              className={
                activeObject.textAlignVertical === "bottom"
                  ? "btn-primary__active btn-primary"
                  : "btn-primary"
              }
            />
          </div>
        </FieldLabel>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { canvas } = state;
  const activeObject = canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  );
  return {
    activeObject,
    textValues: canvas.textValues,
    isTextValuesLoading: canvas.isTextValuesLoading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplateTextValues: () => dispatch(getTemplateTextValues()),
  updateCanvasObject: (id, object) => dispatch(updateCanvasObject(id, object)),
  setDefaultParams: (params) => dispatch(setDefaultParams(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TextControl);
