import React, { Component } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

import Dropzone from "./Dropzone/Dropzone";
import OnlyOneFileModal from "./modals/OnlyOneFileModal";
import UnsupportedFileFormatModal from "./modals/UnsupportedFileFormatModal";
import File from "../../images/ic_file.svg";
import Close from "../../images/ic_x.svg";

import "./Upload.sass";

const MySwal = withReactContent(Swal);

class Upload extends Component {
  state = {
    files: [],
    loaded: false,
    isDragOver: false,
  };

  onlyOneFileErrorHandle = () => {
    this.setState({
      isDragOver: false,
      loaded: true,
    });

    MySwal.fire({
      html: <OnlyOneFileModal />,
      customClass: "only-one-file-modal",
    });
  };

  unsupportedFileFormatHandle = () => {
    this.setState({
      isDragOver: false,
      loaded: true,
    });

    MySwal.fire({
      html: <UnsupportedFileFormatModal />,
      customClass: "unsupported-file-format-modal",
    });
  };

  onFilesAdded = (files) => {
    this.setState((prevState) => ({
      loaded: true,
      files: prevState.files.concat(files),
    }));
  };

  onDragOver = (event) => {
    if (this.state.files.length === 0) {
      event.preventDefault();
      this.setState({
        isDragOver: true,
      });
    }

    if (this.state.files.length === 1) {
      return;
    }
  };

  onDragLeave = () => {
    this.setState({
      isDragOver: false,
    });
  };

  closeHandler = () => {
    this.setState({
      files: [],
    });

    //this.state.files.length = this.state.files.length - 1;
    this.props.onButtonEnable(this.state.files, () => {});

    if (this.props.onClose) {
      this.props.onClose();
    }

    this.setState({
      isDragOver: false,
    });
  };

  onButtonEnable = (e) => {
    this.props.onButtonEnable(e, this.closeHandler);
  };

  render() {
    const acceptFormat = this.props.accept ? this.props.accept : "text/csv";

    return (
      <div
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        className={`Upload ${this.state.isDragOver ? "upload-over" : ""}`}
      >
        <div className="Content">
          <div>
            <Dropzone
              accept={acceptFormat}
              onFilesAdded={this.onFilesAdded}
              onClose={this.closeHandler}
              onButtonEnable={this.onButtonEnable}
              files={this.state.files}
              onlyOneFileErrorHandle={this.onlyOneFileErrorHandle}
              unsupportedFileFormatHandle={this.unsupportedFileFormatHandle}
            />
          </div>
          {this.state.files.length === 1 ? (
            <div className="Files">
              {this.state.files.map((file) => {
                return (
                  <div key={file.name} className="Row">
                    <div className="file">
                      <div className="img-wrapper">
                        <img src={File} alt="file" className="uploaded-file" />
                        <img
                          src={Close}
                          alt="close"
                          className="close"
                          onClick={this.closeHandler}
                        />
                      </div>
                      <span className="Filename">
                        {file.name.length > 40
                          ? file.name.substr(0, 40) + "...csv"
                          : file.name}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default Upload;
