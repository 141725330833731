import React from "react";

import "./Tooltip.sass";

export const Tooltip = ({ children, style, className, description }) => {

  const showTooltip = () => {
    if (description) {
      let tooltip = document.querySelector(".custom-tooltip");

      if (!tooltip) {
        tooltip = document.createElement("div");

        tooltip.className = "custom-tooltip";
        tooltip.textContent = description;

        document.body.appendChild(tooltip);
      }

      return tooltip;
    }
  };

  const setTooltipPosition = (x, y) => {
    const tooltip = showTooltip();

    if (!tooltip.style) {
      tooltip.style = {};
    }

    tooltip.style.top = `${y + 25}px`;
    tooltip.style.left = `${x - 40}px`;
  }

  const closeTooltip = () => {
    const tooltip = document.querySelector(".custom-tooltip");

    if (tooltip) {
      tooltip.remove();
    }
  }

  const handleMouseEnter = (event) => {
    if (description) {
      setTooltipPosition(
        event.clientX,
        event.clientY
      )
    }
  }

  const handleMouseMove = (event) => {
    if (description) {
      setTooltipPosition(
        event.clientX,
        event.clientY
      )
    }
  };

  const handleMouseLeave = () => {
    if (description) {
      closeTooltip();
    }
  };

  return (
    <div
      style={style}
      className={className}
      onMouseMove={handleMouseMove}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleMouseLeave}>
      {children}
    </div>
  );
};
