import React, { Component } from "react";
import { connect } from "react-redux";

import {
  CANVAS_CONTROLS_OVERLAY,
  CANVAS_UNIQUE_ID,
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT,
  DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  DEFAULT_CANVAS_FOOTER_MB_OBJECT,
  DEFAULT_CANVAS_FOOTER_PIB_OBJECT,
  DEFAULT_CANVAS_FOOTER_LINK_OBJECT,
} from "../../constants/canvas";

import getRelativeMousePositionOnCanvas from "../../helpers/getRelativeMousePositionOnCanvas";
import getCursorFromUserMode from "../../helpers/getCursorFromUserMode";
// import getCursorFromUserMode from "../../helpers/getCursorFromUserMode";

import {
  appendEllipseObject,
  appendFooterImageObject,
  appendFreeDrawObject,
  appendFreeDrawPointToCanvasObject,
  appendRectangleObject,
  appendTextObject,
  decrementZoom,
  getTemplate,
  incrementZoom,
  moveCanvasObject,
  resizeCanvasObject,
  setActionMode,
  setActiveObjectId,
  setCanvasLoading,
  setUserMode,
  updateCanvasObject,
  updateScrollPosition,
  getFooterInfo,
} from "../../store/actions/canvasActions";

import "./Canvas.sass";
import { canvasDrawEverything, canvasInit, generateUniqueId } from "./helpers";
import getControlPoints from "../../helpers/getControlPoints";
import isCursorWithinRectangle from "../../helpers/isCursorWithinRectangle";
import getDimensionsFromFreeDraw from "../../helpers/getDimensionsFromFreeDraw";
import NewLoader from "../NewLoader/NewLoader";
import { getFooterImagePdfTemplate } from "../../helpers/getFooterImagePdfTemplate";
import { appendPDFfooter } from "../../helpers/appendPDFfooter";

class Canvas extends Component {
  previousTouchRef = React.createRef(null);
  distanceBetweenTouchesRef = React.createRef(0);
  initialDrawingPositionRef = React.createRef({ x: 0, y: 0 });

  componentDidUpdate(prevProps) {
    const {
      canvasObjects,
      activeObjectId,
      canvasWorkingSize,
      defaultParams,
      scrollPosition,
      userMode,
      actionMode,
      contextRef,
      canvasRef,
      zoom,
      currentTemplate,
      getTemplate,
      token,
      addImageElementToObj,
      appendRectangleObject,
      appendTextObject,
      isLoading,
      footerInfo,
    } = this.props;

    const context = contextRef.current;
    const canvas = canvasRef.current;

    const prevContext = prevProps.contextRef.current;
    const prevCanvas = prevProps.canvasRef.current;

    const isHaveFooter = !!canvasObjects.some(
      (o) => o.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type
    );

    const isCanvasWorkingSizeChanged =
      canvasWorkingSize.width !== prevProps.canvasWorkingSize.width ||
      canvasWorkingSize.height !== prevProps.canvasWorkingSize.height;

    const isCanvasObjsChanged = canvasObjects !== prevProps.canvasObjects;

    const shouldAppendFooterObj = (!isHaveFooter || isCanvasWorkingSizeChanged) &&
      currentTemplate.hasFooter;

    if (shouldAppendFooterObj) {
      this.appendFooterObj();
    }

    if (currentTemplate.hasFooter && !canvasObjects.some(obj => (
      obj.type === DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT.type
    ))  && !shouldAppendFooterObj) {
      console.log(canvasObjects);

      appendPDFfooter({
        appendFooterImageObject,
        appendRectangleObject,
        appendTextObject,
        currentTemplate,
        footerInfo
      });
    }

    if (isCanvasWorkingSizeChanged) {
      canvasInit({
        canvas,
        context,
        canvasWidth: canvasWorkingSize.width,
        canvasHeight: canvasWorkingSize.height,
      });
    }

    if (
      isCanvasObjsChanged ||
      activeObjectId !== prevProps.activeObjectId ||
      defaultParams !== prevProps.defaultParams ||
      scrollPosition !== prevProps.scrollPosition ||
      userMode !== prevProps.userMode ||
      actionMode !== prevProps.actionMode ||
      context !== prevContext ||
      canvas !== prevCanvas ||
      zoom !== prevProps.zoom ||
      isLoading !== prevProps.isLoading ||
      currentTemplate.hasFooter !== prevProps.currentTemplate.hasFooter
    ) {
      canvasDrawEverything({
        zoom,
        canvas,
        context,
        canvasWorkingSize,
        canvasBackgroundColor: defaultParams.canvasBackgroundColor,
        canvasObjects,
        activeObjectId,
        actionMode,
        scrollPosition,
        windowSize: this.props.windowSize,
        addImageElementToObj,
        hasFooter: currentTemplate.hasFooter
      });
    }

    if (
      currentTemplate.value &&
      currentTemplate.value !== prevProps.currentTemplate.value
    ) {
      getTemplate(currentTemplate.value, token);
    }
  }

  // componentDidMount() {
  //   const { canvasObjects } = this.props;

  //   const isHaveFooter = !!canvasObjects.some(
  //     (o) => o.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type
  //   );

  //   if (!isHaveFooter) {
  //     this.appendFooterObj();
  //   }
  // }

  appendFooterObj = async () => {
    try {
      const {
        appendFooterImageObject,
        appendRectangleObject,
        currentTemplate,
        appendTextObject,
        footerInfo
      } =
        this.props;
      // setCanvasLoading(true);

      // const imageElement = await getFooterImagePdfTemplate();
      await appendPDFfooter({
        appendFooterImageObject,
        appendRectangleObject,
        appendTextObject,
        currentTemplate,
        footerInfo
      });
      // setCanvasLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  onPointerDown = (event) => {
    event.preventDefault();
    const canvas = this.props.canvasRef.current;
    const context = this.props.contextRef.current;
    const initialDrawingPositionRef = this.initialDrawingPositionRef;
    const {
      canvasWorkingSize,
      scrollPosition,
      userMode,
      canvasObjects,
      activeObjectId,
      defaultParams,
      actionMode,
      setActionMode,
      setActiveObjectId,
      setUserMode,
      appendRectangleObject,
      appendFreeDrawObject,
      appendEllipseObject,
      appendTextObject,
      zoom,
      addImageElementToObj,
      containerRef,
      currentTemplate,
    } = this.props;
    

    const activeObject = canvasObjects.find(
      (canvasObject) => canvasObject.id === activeObjectId
    );

    if (!canvas || !context) return;

    const clientX =
      "clientX" in event ? event.clientX : event.touches[0].clientX;
    const clientY =
      "clientY" in event ? event.clientY : event.touches[0].clientY;

    const offsetX = containerRef.current.getBoundingClientRect().left;
    const offsetY = containerRef.current.getBoundingClientRect().top;

    const relativeMousePosition = getRelativeMousePositionOnCanvas({
      windowMouseX: clientX - offsetX,
      windowMouseY: clientY - offsetY,
      canvasWorkingSize,
      scrollPosition,
      zoom,
    });

    initialDrawingPositionRef.current = {
      x: relativeMousePosition.relativeMouseX,
      y: relativeMousePosition.relativeMouseY,
    };

    const createdObjectId = generateUniqueId();

    switch (userMode) {
      case "icon":
      case "image":
      case "select": {
        let isResizing = false;
        // Resize object
        if (activeObject) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { position, ...boxes } = getControlPoints({
            canvasObject: activeObject,
            zoom,
          });
          Object.entries(boxes).forEach(([boxName, box]) => {
            const isWithinBounds = isCursorWithinRectangle({
              x: box.x,
              y: box.y,
              width: box.width,
              height: box.height,
              relativeMouseX: this.initialDrawingPositionRef.current.x,
              relativeMouseY: this.initialDrawingPositionRef.current.y,
            });
            if (isWithinBounds) {
              isResizing = true;
              setActionMode({
                type: "isResizing",
                option: boxName.split("Box")[0],
              });
            }
          });
        }
        if (!isResizing) {
          const clickedObjects = canvasObjects.filter((canvasObject) => {
            return isCursorWithinRectangle({
              x: canvasObject.x,
              y: canvasObject.y,
              width: canvasObject.width,
              height: canvasObject.height,
              relativeMouseX: initialDrawingPositionRef.current.x,
              relativeMouseY: initialDrawingPositionRef.current.y,
            });
          });
          const clickedObject = clickedObjects[clickedObjects.length - 1];
          const isFooter =
            clickedObject && (
              clickedObject.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type ||
              clickedObject.type === DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT.type ||
              clickedObject.type === DEFAULT_CANVAS_FOOTER_NAME_OBJECT.type ||
              clickedObject.type === DEFAULT_CANVAS_FOOTER_MB_OBJECT.type ||
              clickedObject.type === DEFAULT_CANVAS_FOOTER_PIB_OBJECT.type ||
              clickedObject.type === DEFAULT_CANVAS_FOOTER_LINK_OBJECT.type
            );

          const wasClickInsideWorkingCanvas = isCursorWithinRectangle({
            x: 0,
            y: 0,
            width: canvasWorkingSize.width,
            height: canvasWorkingSize.height,
            relativeMouseX: initialDrawingPositionRef.current.x,
            relativeMouseY: initialDrawingPositionRef.current.y,
          });
          const shouldClearSelection =
            (!wasClickInsideWorkingCanvas &&
              clickedObject &&
              clickedObject.id !== activeObjectId) ||
            isFooter;

          setActiveObjectId(
            shouldClearSelection
              ? null
              : clickedObject
              ? clickedObject.id
              : null
          );
          if (clickedObject && !isFooter) {
            setUserMode("select");
            setActionMode({ type: "isMoving" });
          } else {
            setActionMode({ type: "isPanning" });
          }
        }
        canvasDrawEverything({
          canvas,
          context,
          canvasWorkingSize,
          canvasBackgroundColor: defaultParams.canvasBackgroundColor,
          canvasObjects,
          activeObjectId,
          actionMode,
          scrollPosition,
          zoom,
          windowSize: this.props.windowSize,
          addImageElementToObj,
          hasFooter: currentTemplate.hasFooter
        });
        break;
      }
      case "free-draw": {
        appendFreeDrawObject({
          id: createdObjectId,
          x: initialDrawingPositionRef.current.x,
          y: initialDrawingPositionRef.current.y,
          width: 0,
          height: 0,
          strokeColorHex: defaultParams.strokeColorHex,
          strokeWidth: 1,
          opacity: 100,
          freeDrawPoints: [
            {
              x: initialDrawingPositionRef.current.x,
              y: initialDrawingPositionRef.current.y,
            },
          ],
        });
        setActiveObjectId(createdObjectId);
        setActionMode({ type: "isDrawing" });
        break;
      }
      case "rectangle": {
        appendRectangleObject({
          id: createdObjectId,
          x: initialDrawingPositionRef.current.x,
          y: initialDrawingPositionRef.current.y,
          width: 0,
          height: 0,
          backgroundColorHex: defaultParams.backgroundColorHex,
          strokeColorHex: defaultParams.strokeColorHex,
          strokeWidth: 0,
          opacity: 100,
          borderRadius: 0,
        });
        setActiveObjectId(createdObjectId);
        setActionMode({ type: "isDrawing" });
        break;
      }
      case "ellipse": {
        appendEllipseObject({
          id: createdObjectId,
          x: initialDrawingPositionRef.current.x,
          y: initialDrawingPositionRef.current.y,
          width: 0,
          height: 0,
          backgroundColorHex: defaultParams.backgroundColorHex,
          strokeColorHex: defaultParams.strokeColorHex,
          strokeWidth: 0,
          opacity: 100,
          borderRadius: 0,
        });
        setActiveObjectId(createdObjectId);
        setActionMode({ type: "isDrawing" });
        break;
      }
      case "text": {
        appendTextObject({
          id: createdObjectId,
          x: initialDrawingPositionRef.current.x,
          y: initialDrawingPositionRef.current.y,
          width: 200,
          height: 100,
          text: "Add text",
          textAlignHorizontal: "center",
          textAlignVertical: "middle",
          textJustify: false,
          fontColorHex: defaultParams.fontColorHex,
          fontSize: 44,
          fontStyle: "normal",
          fontWeight: "normal",
          fontVariant: "normal",
          fontLineHeightRatio: 1,
          opacity: 100,
        });
        setActiveObjectId(createdObjectId);
        setUserMode("select");
        setActionMode(null);
        break;
      }
      default:
        break;
    }
  };

  onPointerMove = (event) => {
    event.preventDefault();
    const canvas = this.props.canvasRef.current;
    const context = this.props.contextRef.current;
    const initialDrawingPositionRef = this.initialDrawingPositionRef;
    const distanceBetweenTouchesRef = this.distanceBetweenTouchesRef;
    const previousTouchRef = this.previousTouchRef;

    const {
      canvasWorkingSize,
      scrollPosition,
      userMode,
      activeObjectId,
      actionMode,
      moveCanvasObject,
      resizeCanvasObject,
      updateScrollPosition,
      appendFreeDrawPointToCanvasObject,
      updateCanvasObject,
      incrementZoom,
      zoom,
      decrementZoom,
      containerRef,
    } = this.props;

    if (!canvas || !context || !actionMode) return;

    const clientX =
      "clientX" in event ? event.clientX : event.touches[0].clientX;
    const clientY =
      "clientY" in event ? event.clientY : event.touches[0].clientY;

    const finger0PageX =
      "touches" in event && event.touches.length
        ? event.touches[0].pageX
        : null;
    const finger0PageY =
      "touches" in event && event.touches.length
        ? event.touches[0].pageY
        : null;

    const finger1PageX =
      "touches" in event && event.touches.length
        ? event.touches[1].pageX
        : null;
    const finger1PageY =
      "touches" in event && event.touches.length
        ? event.touches[1].pageY
        : null;

    if (
      finger0PageX !== null &&
      finger0PageY !== null &&
      finger1PageX !== null &&
      finger1PageY !== null
    ) {
      const distanceBetweenTouches = Math.hypot(
        finger0PageX - finger1PageX,
        finger0PageY - finger1PageY
      );

      if (distanceBetweenTouchesRef.current) {
        if (distanceBetweenTouches > distanceBetweenTouchesRef.current) {
          incrementZoom(1);
        } else if (distanceBetweenTouches < distanceBetweenTouchesRef.current) {
          decrementZoom(1);
        }
      }

      distanceBetweenTouchesRef.current = distanceBetweenTouches;
    }

    const movementX =
      "movementX" in event
        ? event.movementX
        : previousTouchRef.current.pageX
        ? event.touches[0].pageX - previousTouchRef.current.pageX
        : 0;

    const movementY =
      "movementY" in event
        ? event.movementY
        : previousTouchRef.current.pageY
        ? event.touches[0].pageY - previousTouchRef.current.pageY
        : 0;

    if ("touches" in event) {
      previousTouchRef.current = event.touches[0];
    }

    const offsetX = containerRef.current.getBoundingClientRect().left;
    const offsetY = containerRef.current.getBoundingClientRect().top;

    const relativeMousePosition = getRelativeMousePositionOnCanvas({
      windowMouseX: clientX - offsetX,
      windowMouseY: clientY - offsetY,
      canvasWorkingSize,
      scrollPosition,
      zoom,
    });

    const finalX = relativeMousePosition.relativeMouseX;
    const finalY = relativeMousePosition.relativeMouseY;

    switch (userMode) {
      case "select": {
        if (activeObjectId && actionMode.type === "isMoving") {
          moveCanvasObject({
            id: activeObjectId,
            deltaPosition: {
              deltaX: movementX / (zoom / 100),
              deltaY: movementY / (zoom / 100),
            },
            canvasWorkingSize,
          });
        } else if (
          activeObjectId &&
          actionMode.type === "isResizing" &&
          actionMode.option
        ) {
          resizeCanvasObject({
            id: activeObjectId,
            actionModeOption: actionMode.option,
            delta: {
              deltaX: movementX / (zoom / 100),
              deltaY: movementY / (zoom / 100),
            },
            canvasWorkingSize,
          });
        } else if (actionMode.type === "isPanning") {
          updateScrollPosition({
            deltaX: movementX,
            deltaY: movementY,
          });
        }
        break;
      }
      case "free-draw": {
        if (activeObjectId) {
          appendFreeDrawPointToCanvasObject(activeObjectId, {
            x: finalX,
            y: finalY,
          });
        }
        break;
      }
      case "rectangle":
      case "ellipse": {
        if (activeObjectId) {
          const topLeftX = Math.min(
            initialDrawingPositionRef.current.x,
            finalX
          );
          const topLeftY = Math.min(
            initialDrawingPositionRef.current.y,
            finalY
          );

          const width = Math.abs(initialDrawingPositionRef.current.x - finalX);
          const height = Math.abs(initialDrawingPositionRef.current.y - finalY);

          updateCanvasObject(activeObjectId, {
            x: topLeftX,
            y: topLeftY,
            width,
            height,
          });
        }
        break;
      }
      default: {
        break;
      }
    }
  };

  onPointerUp = (event) => {
    event.preventDefault();
    const canvas = this.props.canvasRef.current;
    const context = this.props.contextRef.current;
    const previousTouchRef = this.previousTouchRef;
    const distanceBetweenTouchesRef = this.distanceBetweenTouchesRef;

    const {
      canvasWorkingSize,
      scrollPosition,
      userMode,
      canvasObjects,
      activeObjectId,
      defaultParams,
      actionMode,
      setUserMode,
      setActionMode,
      zoom,
      addImageElementToObj,
      currentTemplate,
    } = this.props;
    setActionMode(null);

    const activeObject = canvasObjects.find(
      (canvasObject) => canvasObject.id === activeObjectId
    );

    if (!canvas || !context) return;

    previousTouchRef.current = null;

    if ("touches" in event) {
      distanceBetweenTouchesRef.current = 0;
    }

    switch (userMode) {
      case "select": {
        break;
      }
      case "text": {
        break;
      }
      case "free-draw": {
        context.closePath();
        if (activeObject) {
          const dimensions = getDimensionsFromFreeDraw({
            freeDrawObject: activeObject,
          });
          updateCanvasObject(activeObject.id, {
            width: dimensions.width,
            height: dimensions.height,
            addImageElementToObj,
          });
        }
        setUserMode("select");
        canvasDrawEverything({
          canvas,
          context,
          canvasWorkingSize,
          canvasBackgroundColor: defaultParams.canvasBackgroundColor,
          canvasObjects,
          activeObjectId,
          actionMode,
          scrollPosition,
          zoom,
          windowSize: this.props.windowSize,
          addImageElementToObj,
          hasFooter: currentTemplate.hasFooter
        });
        break;
      }
      case "rectangle":
      case "ellipse": {
        setUserMode("select");
        canvasDrawEverything({
          canvas,
          context,
          zoom,
          canvasWorkingSize,
          canvasBackgroundColor: defaultParams.canvasBackgroundColor,
          canvasObjects,
          activeObjectId,
          actionMode,
          scrollPosition,
          windowSize: this.props.windowSize,
          addImageElementToObj,
          hasFooter: currentTemplate.hasFooter
        });
        break;
      }
      default: {
        break;
      }
    }
  };

  render() {
    const {
      canvasWorkingSize,
      userMode,
      actionMode,
      scrollPosition,
      windowSize,
      zoom,
      isLoading,
    } = this.props;

    if (isLoading) {
      return (
        <div className="canvas">
          <NewLoader />
        </div>
      );
    }

    return (
      <div
        className="canvas canvas-main"
        id={CANVAS_UNIQUE_ID}
        style={{
          cursor: getCursorFromUserMode({ userMode, actionMode }),
        }}
        ref={this.props.containerRef}
        onPointerDown={this.onPointerDown}
        onPointerMove={this.onPointerMove}
        onPointerUp={this.onPointerUp}
        onTouchStart={this.onPointerUp}
        onTouchMove={this.onPointerMove}
        onTouchEnd={this.onPointerDown}
      >
        <canvas
          id={CANVAS_CONTROLS_OVERLAY}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 11,
          }}
          width={windowSize.width}
          height={windowSize.height}
        />
        <div
          className="canvas__wrapper"
          style={{
            position: "absolute",
            top: scrollPosition.y,
            transform: `scale(${zoom / 100})`,
            left: scrollPosition.x,
            width: canvasWorkingSize.width,
            height: canvasWorkingSize.height,
          }}
        >
          <canvas
            ref={this.props.setRef}
            style={{ zIndex: 10 }}
            width={canvasWorkingSize.width}
            height={canvasWorkingSize.height}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ canvas, auth }) => ({
  ...canvas,
  token: auth.user.token,
});

const mapDispatchToProps = (dispatch) => ({
  incrementZoom: (zoom) => dispatch(incrementZoom(zoom)),
  decrementZoom: (zoom) => dispatch(decrementZoom(zoom)),
  setActionMode: (params) => dispatch(setActionMode(params)),
  setActiveObjectId: (id) => dispatch(setActiveObjectId(id)),
  setUserMode: (mode) => dispatch(setUserMode(mode)),
  appendRectangleObject: (obj) => dispatch(appendRectangleObject(obj)),
  appendFreeDrawObject: (obj) => dispatch(appendFreeDrawObject(obj)),
  appendEllipseObject: (obj) => dispatch(appendEllipseObject(obj)),
  appendTextObject: (obj) => dispatch(appendTextObject(obj)),
  moveCanvasObject: (obj) => dispatch(moveCanvasObject(obj)),
  resizeCanvasObject: (obj) => dispatch(resizeCanvasObject(obj)),
  updateScrollPosition: (delta) => dispatch(updateScrollPosition(delta)),
  getTemplate: (pdfTemplateId, userToken) =>
    dispatch(getTemplate(pdfTemplateId, userToken)),
  appendFreeDrawPointToCanvasObject: (id, point) =>
    dispatch(appendFreeDrawPointToCanvasObject(id, point)),
  updateCanvasObject: (id, obj) => dispatch(updateCanvasObject(id, obj)),
  addImageElementToObj: (id, imageElement) =>
    dispatch(updateCanvasObject(id, { imageElement })),
  appendFooterImageObject: (obj) => dispatch(appendFooterImageObject(obj)),
  setCanvasLoading: (isLoading) => dispatch(setCanvasLoading(isLoading)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Canvas);
