import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";
import { getOptionsForAsyncSelect } from "./getOptionsForAsyncSelect";

export const getPdfTemplateTextValuesAsyncFunc = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (inputValue) =>
    axios
      .get("/PdfTemplate/TemplateValues", { headers: getHeaders(user.token) })
      .then(({ data }) => {
        let options = [];

        if (data.isSuccess) {
          options = [
            ...options,
            ...getOptionsForAsyncSelect(data.result, {
              labelKey: "description",
              returnDataKey: "item",
              valueKey: "value",
            }),
          ].filter(({ label }) =>
            label.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        return options;
      })
      .catch((e) => {
        console.log(e);
      });
};
