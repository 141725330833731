import React, {Component} from 'react';

import '../BarCodes.sass'

class ErrorModal extends Component {
    render() {
        return (
            <div>
                <strong>Error!</strong>
                Something went wrong.<br />
                Review data and try again.
            </div>
        );
    }
}

export default ErrorModal;
