import * as actionTypes from '../actionTypes/promotersTypes';
import axios from '../../axios-instance';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import { getHeaders } from "../../helpers/getHeaders";

const getPromotersStart = () => ({type: actionTypes.GET_PROMOTERS_START});
const getPromotersSuccess = events => ({type: actionTypes.GET_PROMOTERS_SUCCESS, payload: events});
const getPromotersFail = error => ({type: actionTypes.GET_PROMOTERS_FAIL, error});

export const getPromoters = (userToken, searchPromoterValue, skip, take) => dispatch => {
    dispatch(getPromotersStart());
    axios.get(`/Users?search=${searchPromoterValue}&skip=${skip}&take=${take}`, {headers: getHeaders(userToken)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getPromotersSuccess(result));
        })
        .catch(err => {
            dispatch(getPromotersFail(err));
        })
};

const getPromoterStart = () => ({type: actionTypes.GET_PROMOTER_START});
const getPromoterSuccess = data => ({type: actionTypes.GET_PROMOTER_SUCCESS, payload: data});
const getPromoterFail = error => ({type: actionTypes.GET_PROMOTER_FAIL, error});

export const getPromoter = (userToken, promoterId) => dispatch => {
    dispatch(getPromoterStart());
    return axios.get(`/Users/${promoterId}`, {headers: getHeaders(userToken)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getPromoterSuccess(result));
        })
        .catch(err => {
            Swal({
                title: "Error!",
                text: err,
                type: "error",
                timer: 3000
            });
            dispatch(getPromoterFail(err));
        })
};

const setPromoterScanbitCostStart = () => ({type: actionTypes.SET_PROMOTER_SCANBIT_COST_START});
const setPromoterScanbitCostSuccess = () => ({type: actionTypes.SET_PROMOTER_SCANBIT_COST_SUCCESS});
const setPromoterScanbitCostFail = error => ({type: actionTypes.SET_PROMOTER_SCANBIT_COST_FAIL, error});

export const setPromoterScanbitCost = (userToken, promoterId, data) => dispatch => {
    dispatch(setPromoterScanbitCostStart());
    return axios.put(
        `/Users/${promoterId}`,
        data,
        {headers: getHeaders(userToken)}
        )
        .then(response => {
            if (response.data.isSuccess) {
                return response.data;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(setPromoterScanbitCostSuccess(result));
            toast.success("Scanbit price was successfully changed");
            return result;
        })
        .catch(err => {
            Swal({
                title: "Error!",
                text: err,
                type: "error",
                timer: 3000
            });
            dispatch(setPromoterScanbitCostFail(err));
        })
};

const banPromoterStart = () => ({type: actionTypes.BAN_PROMOTER_START});
const banPromoterSuccess = () => ({type: actionTypes.BAN_PROMOTER_SUCCESS});
const banPromoterFail = () => ({type: actionTypes.BAN_PROMOTER_FAIL});

export const banPromoter = (userToken, promoterId, data) => dispatch => {
    dispatch(banPromoterStart());
    return axios.put(`/Users/${promoterId}`, data, {headers: getHeaders(userToken)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(banPromoterSuccess(result));
            return result;
        })
        .catch(err => {
            dispatch(banPromoterFail(err));
        })
};

const signInPromoterAsAdminStart = () => ({type: actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_START});
const signInPromoterAsAdminSuccess = promoterAuthInfo => ({type: actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_SUCCESS, payload: promoterAuthInfo});
const signInPromoterAsAdminFail = error => ({type: actionTypes.SIGN_IN_PROMOTER_AS_ADMIN_FAIL, error});

export const signInPromoterAsAdmin = (userToken, promoterId) => dispatch => {
    dispatch(signInPromoterAsAdminStart());
    return axios.post(`/Authorization/LoginPromoterAsSuperadmin?userId=${promoterId}`, {}, {headers: getHeaders(userToken)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;
            }
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(signInPromoterAsAdminSuccess(result));
            return result;
        })
        .catch(err => {
            dispatch(signInPromoterAsAdminFail(err));
        })
};
