import  * as actionsTypes from '../actionTypes/paymentsActionTypes';
import axios from '../../axios-instance';
import { getHeaders } from "../../helpers/getHeaders";

const getCreditCardStart = () => ({type: actionsTypes.GET_CREDIT_CARD_START});
const getCreditCardSuccess = card => ({type: actionsTypes.GET_CREDIT_CARD_SUCCESS, payload: {card}});
const getCreditCardFail = () => ({type: actionsTypes.GET_CREDIT_CARD_FAIL});

export const getCreditCard = token => dispatch => {
  dispatch(getCreditCardStart());
  return axios.get(`PaymentMethods`, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);

    })
    .then(result => {
      dispatch(getCreditCardSuccess(result));
    })
    .catch(err => {
      dispatch(getCreditCardFail(err));
    })
};

const updateCreditCardStart = () => ({type: actionsTypes.UPDATE_CREDIT_CARD_START});
const updateCreditCardSuccess = card => ({type: actionsTypes.UPDATE_CREDIT_CARD_SUCCESS, payload: {card}});
const updateCreditCardFail = () => ({type: actionsTypes.UPDATE_CREDIT_CARD_FAIL});

export const updateCreditCard = (creditCardToken, dropin, token) => dispatch => {
  dispatch(updateCreditCardStart);
  axios.put('PaymentMethods/CreditCard', {...creditCardToken}, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
          if (response.data.result.action.type === 'redirect') {
              return response.data.result.action.url
          }
        // return response.data.result;
      }
      throw new Error(response.data.errorMessage);
    })
    .then(result => {
      dispatch(updateCreditCardSuccess(result));
    })
    .catch(err => {
      dispatch(updateCreditCardFail());
    })
};

const getAllPaymentStart = () => ({type: actionsTypes.GET_ALL_PAYMENTS_START});
const getAllPaymentSuccess = payments => ({type: actionsTypes.GET_ALL_PAYMENTS_SUCCESS, payload: {payments}});
const getAllPaymentFail = () => ({type: actionsTypes.GET_ALL_PAYMENTS_FAIL});

export const getAllPayments = token => dispatch => {
  dispatch(getAllPaymentStart());
  return axios.get(`Payment/Payments`, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);

    })
    .then(result => {
      dispatch(getAllPaymentSuccess(result));
    })
    .catch(err => {
      dispatch(getAllPaymentFail(err));
    })
};

const getScanbitsCountStart = () => ({type: actionsTypes.GET_SCANBITS_COUNT_START});
const getScanbitsCountSuccess = count => ({type: actionsTypes.GET_SCANBITS_COUNT_SUCCESS, payload: {count}});
const getScanbitsCountFail = () => ({type: actionsTypes.GET_SCANBITS_COUNT_FAIL});

export const getScanbitsCount = token => dispatch => {
  dispatch(getScanbitsCountStart());
  return axios.get(`Users/ScanbitsCount`, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);

    })
    .then(result => {
      dispatch(getScanbitsCountSuccess(result));
    })
    .catch(err => {
      dispatch(getScanbitsCountFail(err));
    })
};

const payNowStart = () => ({type: actionsTypes.PAY_NOW_START});
const payNowSuccess = result => ({type: actionsTypes.PAY_NOW_SUCCESS, payload: {result}});
const payNowFail = () => ({type: actionsTypes.PAY_NOW_FAIL});

export const payNow = token => dispatch => {
  dispatch(payNowStart());
  return axios.post('/Payment/PayNow',{}, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);

    })
    .then(result => {
      dispatch(payNowSuccess(result));
      return {isSuccess: true};
    })
    .catch(err => {
      dispatch(payNowFail());
      return {isSuccess: false, errMessage: err.message}
    })
};

const paymentStart = () => ({type: actionsTypes.PAYMENT_START});
const paymentSuccess = result => ({type: actionsTypes.PAYMENT_SUCCESS, payload: {result}});
const paymentFail = () => ({type: actionsTypes.PAYMENT_FAIL});

export const payment = (token, scanbitsAddCount) => dispatch => {
  dispatch(paymentStart);
  return axios.post(`/Payment?scanbitsAddCount=${scanbitsAddCount}`,{}, {headers: getHeaders(token)})
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;}
      throw new Error(response.data.errorMessage);
    })
    .then(result => {
      dispatch(paymentSuccess(result));
      return {isOK: true}
    })
    .catch(err => {
      dispatch(paymentFail());
      return {isOK: false, errMessage: err.message}
    })
};

const getPdfStart = () => () => ({type: actionsTypes.GET_PDF_START});
const getPdfSuccess = () => () => ({type: actionsTypes.GET_PDF_SUCCESS});
const getPdfFail = () => () => ({type: actionsTypes.GET_PDF_FAIL});

export const getPdf = (token, paymentId, usersDateTime) => dispatch => {
  dispatch(getPdfStart());
  return axios.get(`/Payment/${paymentId}/Pdf`, {
    headers: getHeaders(token),
    params: {
      usersDateTime
    }
  })
    .then(response => {
      if (response.data.isSuccess) {
        return response.data.result;
      }
      throw new Error(response.data.errorMessage);

    })
    .then(result => {
      dispatch(getPdfSuccess());
      return result;
    })
    .catch(err => {
      dispatch(getPdfFail(err));
    })
};

const getScanbitPriceStart = () => ({type: actionsTypes.GET_USER_PAYMENT_INFO_START});
const getScanbitPriceSuccess = result => ({type: actionsTypes.GET_USER_PAYMENT_INFO_SUCCESS, payload: result});
const getScanbitPriceFail = () => ({type: actionsTypes.GET_USER_PAYMENT_INFO_FAIL});

export const getScanbitPrice = (token) => dispatch => {
    dispatch(getScanbitPriceStart());

    return axios.get(`/Users/self`, {headers: getHeaders(token)})
        .then(response => {
            if (response.data.isSuccess) {
                return response.data.result;}
            throw new Error(response.data.errorMessage);
        })
        .then(result => {
            dispatch(getScanbitPriceSuccess(result));
            return {isOK: true}
        })
        .catch(err => {
            dispatch(getScanbitPriceFail());
            return {isOK: false, errMessage: err.message}
        })
};
