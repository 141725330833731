import * as actionTypes from "../actionTypes/statisticsTypes";

const initialState = {
  events: [],
  selectedEvent: null,
  sellingReports: [],
  scaningReports: [],
  selectedTab: 0,
  loading: true,
  eventsLoading: true,
};

const statistics = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVENTS:
      return {
        ...state,
        eventsLoading: true,
      };
    case actionTypes.GET_EVENTS_SUCCESS:
      return {
        ...state,
        eventsLoading: false,
        loading: false,
        events: action.payload,
      };
    case actionTypes.GET_EVENTS_FAIL:
      return {
        ...state,
        events: [],
        eventsLoading: false,
      };
    case actionTypes.SET_SELECTED_EVENT:
      return {
        ...state,
        selectedEvent: action.payload,
      };
    case actionTypes.SET_SELECTED_TAB:
      return {
        ...state,
        selectedTab: action.payload,
      };
    case actionTypes.GET_REPORTS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_REPORTS_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case actionTypes.GET_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default statistics;
