import React, { Component } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ScanbitsEmptyState from "../../../components/Payments/ScanbitsEmptyState/ScanbitsEmptyState";
import PleaseWaitModal from "../modals/PleaseWaitModal";
import ScanbitsDetails from "../../../components/Payments/ScanbitsDetails/ScanbitsDetails";

import "./Scanbits.sass";
import {
  getScanbitPrice,
  payment,
} from "../../../store/actions/paymentsActions";
import connect from "react-redux/es/connect/connect";
import { toast } from "react-toastify";
import NewLoader from "../../../components/NewLoader/NewLoader";

const MySwal = withReactContent(Swal);

class Scanbits extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTutorialShow: !JSON.parse(localStorage.getItem("isTutorialShow")),
      value: 10,
    };
  }

  componentDidMount() {
    const { token } = this.props.auth.user;
    this.props.getScanbitPriceCurrentPromoter(token);
  }

  showModal = () => {
    MySwal.fire({
      html: <PleaseWaitModal message="Your tickets are uploading" />,
      customClass: "please-wait-modal",
    });

    const { payment, auth } = this.props;
    payment(auth.user.token, this.state.value).then(({ isOK, errMessage }) => {
      if (isOK) {
        MySwal.close();
        this.setState({ value: 0 });
        toast.success("Your payment was successful.");
      } else if (!errMessage.includes("401")) {
        Swal({
          title: "Error!",
          text: errMessage,
          type: "error",
          timer: 3000,
        });
      }
    });
  };

  gotItHandle = () => {
    this.setState({
      isTutorialShow: false,
    });
    localStorage.setItem("isTutorialShow", "true");
  };

  inputCheck = (value) => {
    const match = /(\d{0,9})/g.exec(value.replace(/[^\d]/g, ""));
    return match[1];
  };

  onInputChange = (event) => {
    const checkValue = this.inputCheck(event.target.value);
    const value = checkValue.length ? checkValue : "";
    this.setState({ value });
  };

  handleKeyPress = (event) => {
    if (event.key === "-") {
      event.preventDefault();
    }
  };

  scanbitsDetailsRender = () => {
    const { value } = this.state;
    const { userInfo, isLoading } = this.props;

    if (isLoading) {
      return <NewLoader isFullScreen />;
    } else {
      return (
        <ScanbitsDetails
          disabledButton={this.props.disabledButton}
          showModal={this.showModal}
          value={value}
          onChange={this.onInputChange}
          onKeyPress={this.handleKeyPress}
          scanBitPrice={
            userInfo && userInfo.scanBitPrice ? userInfo.scanBitPrice : 0.1
          }
          isLoading={isLoading}
        />
      );
    }
  };

  render() {
    const { isTutorialShow } = this.state;
    return (
      <div>
        {isTutorialShow ? (
          <ScanbitsEmptyState gotItHandle={this.gotItHandle} />
        ) : (
          this.scanbitsDetailsRender()
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ auth, payments: { userInfo, loading } }) => ({
  auth,
  userInfo,
  isLoading: loading,
});

const mapDispatchToProps = (dispatch) => ({
  payment: (token, value) => dispatch(payment(token, value)),
  getScanbitPriceCurrentPromoter: (userToken) =>
    dispatch(getScanbitPrice(userToken)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Scanbits);
