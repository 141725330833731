import React from "react";
import { useShowActionsMenu } from "../../../../../components/UI/ActionsMenu/hooks/useShowActionsMenu";
import { IconButton } from "../../../../../components/UI/IconButton/IconButton";


export const ManagementAction = ({
  ticket,
  cancelTicketHandler,
  showRefundTicketModal,
  changeNameHandler,
  lostTicketHandler,
  changeSeatHandler,
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const isButtonDisabled = ticket.status !== 1 && ticket.status !== 4

  const menuItems = [];

  if (ticket.status !== 3 && ticket.allowRefund) {
    menuItems.push({
      text: "Refund",
      onClick: showRefundTicketModal,
      data: ticket,
    });
  };

  if (ticket.status !== 2 && ticket.allowCancel) {
    menuItems.push({
      text: "Cancel",
      onClick: cancelTicketHandler,
      data: ticket,
    });
  };

  if (ticket.status !== 5 && ticket.allowLost) {
    menuItems.push({
      text: "Lost",
      onClick: lostTicketHandler,
      data: ticket,
    });
  };

  if (ticket.allowChangeName) {
    menuItems.push({
      text: "Change Name",
      onClick: changeNameHandler,
      data: ticket,
    });
  }

  if (ticket.allowChangeSeat) {
    menuItems.push({
      text: "Change Seat",
      onClick: changeSeatHandler,
      data: ticket,
    });
  }

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block">
      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
        disabled={isButtonDisabled}
      />
    </div>
  );
};
