import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";
import { getOptionsForAsyncSelect } from "./getOptionsForAsyncSelect";

export const emptyEmailTemplateOption = {
  value: "DefaultTicket",
  label: "DefaultTicket",
};

export const getEmailTemplatesAsyncFunc = (withEmptyOption = false) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (inputValue) =>
    axios
      .get("/Templates/EmailTemplate", { headers: getHeaders(user.token) })
      .then(({ data }) => {
        let options = withEmptyOption ? [emptyEmailTemplateOption] : [];

        if (data.result && data.result.length) {
          options = [
            ...options,
            ...getOptionsForAsyncSelect(data.result),
          ].filter(({ label }) =>
            label.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        return options;
      })
      .catch((e) => {
        console.log(e);
      });
};
