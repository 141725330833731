export const GET_PROVIDERS_START = 'GET_PROVIDERS_START';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAIL = 'GET_PROVIDERS_FAIL';

export const GET_PROVIDER_CONFIG_START = 'GET_PROVIDER_CONFIG_START';
export const GET_PROVIDER_CONFIG_SUCCESS = 'GET_PROVIDER_CONFIG_SUCCESS';
export const GET_PROVIDER_CONFIG_FAIL = 'GET_PROVIDER_CONFIG_FAIL';

export const UPLOAD_TICKETS_TABLE_START = 'UPLOAD_TICKETS_TABLE_START';
export const UPLOAD_TICKETS_TABLE_SUCCESS = 'UPLOAD_TICKETS_TABLE_SUCCESS';
export const UPLOAD_TICKETS_TABLE_FAIL = 'UPLOAD_TICKETS_TABLE_FAIL';

export const UPLOAD_BARDCODES_START = 'UPLOAD_BARDCODES_START';
export const UPLOAD_BARDCODES_SUCCESS = 'UPLOAD_BARDCODES_SUCCESS';
export const UPLOAD_BARDCODES_FAIL = 'UPLOAD_BARDCODES_FAIL';
