import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import withRouter from 'react-router/es/withRouter';

import Scanbits from '../Scanbits/Scanbits';
import ROUTES from '../../constants/routes';
import Faq from '../../containers/FAQ/FAQ';
import Events from '../../containers/Events/Events';
import Emails from '../../containers/Emails/Emails';
import Sidebar from '../Sidebar/Sidebar';
import TicketDetail from '../../containers/TicketDetail/TicketDetail';
import ScanTeams from '../../containers/ScanTeams/ScanTeams';
import Payments from '../../containers/Payments/Payments';
import AddTeams from '../../containers/ScanTeams/AddTeams/AddTeams';
import Promoters from '../../containers/Promoters/Promoters';
import BarCodes from '../../containers/BarCodes/BarCodes';
import Page404 from '../Page404/Page404';
// import Dashboard from "../../containers/Dashboard/Dashboard";
import SendBuyEmail from '../../containers/TicketActions/SendBuyEmail';
import GeneratePdfs from '../../containers/TicketActions/GeneratePdfs';
import Statistics from '../../containers/Statistics/Statistics';
import SatsScan from '../../containers/SatsScan/SatsScan';
import SatsSells from '../../containers/SatsSells/SatsSells';
import MembershipManagment from '../../containers/MembershipManagment/MembershipManagment';
import BalancePage from '../../containers/BalancePage/BalancePage';
import NewEvent from '../../containers/Events/NewEvent/NewEvent';

const AuthorizedLayout = ({ location }) => {
  const shouldScanbitsDisplayed = location.pathname === ROUTES.PAYMENTS;

  return localStorage.getItem('user') ? (
    <Fragment>
      <Sidebar />
      {shouldScanbitsDisplayed && <Scanbits />}
      <Switch>
        <Route path={ROUTES.FAQ} component={Faq} />
        <Route path={ROUTES.EVENTS} component={Events} />
        <Route path={ROUTES.SCANTEAMS} exact component={ScanTeams} />
        <Route path={ROUTES.SCANTEAMS_ADD} exact component={AddTeams} />
        <Route path={ROUTES.SCANTEAMS_VIEW} exact component={AddTeams} />
        <Route path={ROUTES.TICKET_DETAIL} exact component={TicketDetail} />
        <Route path={ROUTES.PAYMENTS} component={Payments} />
        <Route path={ROUTES.ADD_TICKET} component={TicketDetail} />
        <Route path={ROUTES.PROMOTERS} component={Promoters} />
        <Route path={ROUTES.ADD_BAR_CODE} exact component={BarCodes} />
        <Route
          path={ROUTES.MEMBERSHIP_MANAGMENT}
          exact
          component={MembershipManagment}
        />

        <Route path={ROUTES.BALANCE} exact component={BalancePage} />
        {/* <Route path={ROUTES.DASHBOARD} exact component={Dashboard} /> */}
        <Route path={ROUTES.STATISTICS} exact component={Statistics} />
        <Route path={ROUTES.STATS_SCANNING} exact component={SatsScan} />
        <Route path={ROUTES.STATS_SELLING} exact component={SatsSells} />
        <Route path={ROUTES.SEND_BUY_EMAIL} exact component={SendBuyEmail} />
        <Route path={ROUTES.GENERATE_PDFS} exact component={GeneratePdfs} />
        <Route path={ROUTES.EMAILS} exact component={Emails} />

        <Route path={ROUTES.NEW_ADD_EVENT} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_INFO} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_TICKETS} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_PDF} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_EMAIL} exact component={NewEvent} />
        <Route
          path={ROUTES.NEW_EVENT_TRANSACTIONS}
          exact
          component={NewEvent}
        />
        <Route
          path={ROUTES.NEW_EVENT_SELL}
          exact
          component={NewEvent}
        />
        <Route
          path={ROUTES.NEW_EVENT_SEATS}
          exact
          component={NewEvent}
        />
        <Route path={ROUTES.NEW_EVENT_REPORTS} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_STATISTICS} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_RESELLING} exact component={NewEvent} />
        <Route path={ROUTES.NEW_EVENT_MANAGEMENT} exact component={NewEvent} />

        <Route path={ROUTES.NEW_TICKET_DETAIL} exact component={NewEvent} />
        <Route path={ROUTES.NEW_ADD_TICKET} exact component={NewEvent} />
        <Route path={ROUTES.NEW_ADD_BAR_CODE} exact component={NewEvent} />
        <Route path={ROUTES.NEW_GENERATE_PDFS} exact component={NewEvent} />
        <Route path={ROUTES.NEW_SEND_BUY_EMAIL} exact component={NewEvent} />

        <Route path='/' component={Page404} />
      </Switch>
    </Fragment>
  ) : (
    <Redirect to='/login' />
  );
};

export default withRouter(AuthorizedLayout);
