import React from "react";

import ReactTable from "react-table";
import NewLoader from "../../../components/NewLoader/NewLoader";

import "../../Statistics/StatsInfo/StatsInfo.sass";

function Table({ groupByTime, data = [], loading }) {
  if (loading) {
    return <NewLoader />;
  }

  const columns = groupByTime
    ? []
    : [
        {
          Header: "Ticket Name",
          accessor: "ticketName",
          width: 280,
        },
        {
          Header: "Daily Scan",
          accessor: "dailyTicketScans",
        },
        {
          Header: "SP scan",
          accessor: "seasonPassScans",
        },
        {
          Header: "Total scan",
          accessor: "totalScans",
        },
      ];

  return (
    <div className="statistics__table">
      <ReactTable
        columns={columns}
        data={data}
        style={{ height: "575px" }}
        showPagination={false}
        resizable={false}
        defaultPageSize={data.length}
      />
    </div>
  );
}

export default Table;
