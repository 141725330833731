import React, { useCallback, useEffect, useState } from "react";
import { ChromePicker, GithubPicker, TwitterPicker } from "react-color";

import { ColorPickerProps, ColorPickerDefaultProps } from "./ColorPickerProps";
import ColorPickerStyles, { cssResolver } from "./ColorPickerStyles";

const styles = ColorPickerStyles;

const ColorPicker = ({ ...props }) => {
  const {
    resetDefaultButton,
    colorShowType,
    pickerType,
    specialColors,
    variant,
    size,
    className,
    style,
    id,
    name,
    value,
    disabled,
    autoFocus,
    fullWidth,
    placeholder,
    onFocus,
    onBlur,
    onClick,
    onChange,
  } = props;

  const [pickerOpen, setPickerOpen] = useState(false);
  const [defaultComponentValue, setDefaultComponentValue] = useState(value);
  const [inputValue, setInputValue] = useState(value);

  const handleColorPickerOpen = () => {
    disabled !== true && setPickerOpen(!pickerOpen);
    disabled !== true && onFocus && onFocus();
    disabled !== true && onClick && onClick();
  };
  const handleCloseColorPicker = () => {
    setPickerOpen(false);
    onBlur && onBlur();
  };
  const handleColorChangeSubmit = (color) => {
    handleChange(color.hex);
    pickerType === "github" && setPickerOpen(false);
  };

  const setColor = useCallback((value) => {
    setDefaultComponentValue(value);
    setInputValue(value);
  }, []);

  const handleChange = (value) => {
    setInputValue(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    inputValue && inputValue.length === 0 && setInputValue("#");
  }, [inputValue]);

  useEffect(() => {
    value === "reset" && setInputValue(defaultComponentValue);
    !defaultComponentValue && setColor(value);
    value && setInputValue(value);
  }, [value]);

  return (
    <div className="colorPickerArea" style={{ ...style }}>
      <div
        className={`${cssResolver(styles.generalArea)} ${
          className || ""
        } generalArea`}
        style={{ width: fullWidth && "100%" }}
      >
        <div
          className={`${cssResolver(styles.colorSquare)} colorSquare`}
          onClick={handleColorPickerOpen}
        >
          <div
            className={`${cssResolver(
              styles.colorInnerSquare
            )} colorSquareInner`}
            style={{
              borderRadius: colorShowType === "circle" && "50%",
              background: inputValue,
            }}
          />
        </div>
        {pickerOpen && (
          <div className={cssResolver(styles.colorPickerArea)}>
            <div
              className={cssResolver(styles.colorPickerBg)}
              onClick={handleCloseColorPicker}
            />
            {(!pickerType || (pickerType && pickerType === "chrome")) && (
              <ChromePicker
                onChange={handleColorChangeSubmit}
                color={inputValue}
              />
            )}
            {pickerType === "twitter" && (
              <TwitterPicker
                onChange={handleColorChangeSubmit}
                color={inputValue}
                colors={specialColors}
              />
            )}
            {pickerType === "github" && (
              <GithubPicker
                onChange={handleColorChangeSubmit}
                color={inputValue}
                colors={specialColors}
              />
            )}
          </div>
        )}
        <input
          className={`${cssResolver(styles.inputArea)} ${
            size === "small"
              ? cssResolver(styles.inputSmallPadding)
              : size === "large"
              ? cssResolver(styles.inputLargePadding)
              : cssResolver(styles.inputMediumPadding)
          } ${
            variant === "filled"
              ? cssResolver(styles.inputFilledPadding)
              : variant === "outlined"
              ? cssResolver(styles.inputOutlinedPadding)
              : cssResolver(styles.inputStandardPadding)
          } colorInput`}
          autoComplete="off"
          maxLength={7}
          id={id}
          name={name}
          value={inputValue}
          disabled={disabled}
          autoFocus={autoFocus}
          style={{ width: fullWidth && "100%" }}
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onBlur}
          onClick={onClick}
          onChange={(e) => handleChange(e.target.value)}
        />
        {resetDefaultButton !== false &&
          defaultComponentValue !== inputValue && (
            <div
              className={`${cssResolver(
                styles.resetButtonArea
              )} resetButtonArea`}
            >
              <div
                className={`${cssResolver(styles.iconButton)} resetButton`}
                onClick={() => handleChange(defaultComponentValue)}
              >
                <svg
                  className={`${cssResolver(styles.iconSvg)} resetIcon`}
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                >
                  <path d="M13 3c-4.97 0-9 4.03-9 9H1l3.89 3.89.07.14L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.28 2.54.72-1.21-3.5-2.08V8H12z"></path>
                </svg>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

ColorPicker.propTypes = ColorPickerProps;

ColorPicker.defaultProps = ColorPickerDefaultProps;

export default ColorPicker;
