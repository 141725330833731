import React from "react";

import "./SeasonPass.sass";

export const SeasonPassFieldLabel = ({
  children,
  label,
  shouldWrapInLabel = true,
  isRequired = false,
  className,
}) => {
  if (!shouldWrapInLabel) {
    return (
      <div className={`seasonpass__field ${className}`}>
        <span className="seasonpass__field-text">
          <span className="seasonpass__field-text-label">{label}</span>
          {isRequired && (
            <span className="seasonpass__field-text-required">
              Required Field
            </span>
          )}
        </span>
        {children}
      </div>
    );
  }

  return (
    <label className={`seasonpass__field ${className}`}>
      <span className="seasonpass__field-text">
        <span className="seasonpass__field-text-label">{label}</span>
        {isRequired && (
          <span className="seasonpass__field-text-required">
            Required Field
          </span>
        )}
      </span>
      {children}
    </label>
  );
};
