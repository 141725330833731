import React, {Component} from 'react';

import '../BarCodes.sass'

class ImportantModal extends Component {
    render() {
        const { all, updated, duplicate } = this.props;
        return (
            <div>
                <strong>It's important!</strong>
                Check any ticket from this list before the event!
                <div className="success-content">
                    <p>Success!</p>
                    <div className="results">
                        <p>All tickets in file: <span>{all - duplicate}</span></p>
                        <p>Updated tickets: <span>{updated}</span></p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ImportantModal;
