import React, {Component} from 'react';
import {connect} from 'react-redux';
import {history} from '../../../helpers/history';
import { withRouter } from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import queryString from 'query-string'
import {changePassword} from '../../../store/actions/authActions';
import { getRegion } from '../../../helpers/getRegion';

class NewPassword extends Component {
    constructor(props) {
        super(props);

        const values = queryString.parse(this.props.location.search);

        this.state = {
            email: values.Email,
            token: values.token,
            password: '',
            confirmPassword: ''
        };
    }

    componentDidUpdate(prevProps){
        if (this.props.auth.error !== prevProps.auth.error) {
            if (this.props.auth.error.response) {
                this.setState({errors: [this.props.auth.error.response.data]}, () => {
                    this.setState({error: Object.values(this.state.errors[0])[0][0]})
                });
            } else {
                this.setState({error: this.props.auth.error.message})
            }
        }
    }

    redirectToLogin = () => this.props.history.push({pathname: ROUTES.LOGIN});

    inputChangeHandler = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    };

    changePassword = () => {
        const {email, token, password, confirmPassword} = this.state;
        const {changePassword} = this.props;
        const data = {
            email,
            passwordRecoveryToken: token,
            newPassword: password
        };

        if (password === confirmPassword) {
            changePassword(data)
                .then(response => {
                    if (response && response.data.isSuccess) {
                        history.push(ROUTES.LOGIN);
                    } else if (response && !response.data.isSuccess) {
                        this.setState({error: response.data.errorMessage})
                    }
                });
        } else {
            this.setState({error: 'Passwords don\'t match'})
        }
    };

    render() {
        const {email, password, confirmPassword, error} = this.state;
        const region = getRegion();
        return (
            <div className="auth forgot-password">
                <div className="auth__logo">
                  <img
                    className="auth__logo-img"
                    src={`/${region.theme}/logo.png`}
                    alt={region.title}
                  />
                  <div className="auth__logo-title">{region.title}</div>
                </div>
                <div className="auth__title">Recover password for</div>
                <div className="forgot-password__username">{email}</div>
                <form>
                    <p className="form-error">{error}</p>
                    <input type="password"
                           name="password"
                           placeholder="New password"
                           minLength="8"
                           maxLength="16"
                           onChange={this.inputChangeHandler}
                    />
                    <input type="password"
                           name="confirmPassword"
                           placeholder="Repeat password"
                           minLength="8"
                           maxLength="16"
                           onChange={this.inputChangeHandler}
                    />
                </form>
                <div className="forgot-password__buttons-wrapper">
                    <button type="button" className="btn btn-secondary" onClick={this.redirectToLogin}>Back to login</button>
                    <button type="button"
                            className="btn btn-primary"
                            onClick={this.changePassword}
                            disabled={!password || !confirmPassword}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth}) => ({auth});

const mapDispatchToProps = dispatch => ({
    changePassword: data => {return dispatch(changePassword(data))}
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NewPassword));
