import React, { useState } from 'react';
import './ScanbitsModal.sass';
import numberNormalize from "../../helpers/priceNormalize";


const ScanbitsModal = (props) => {
    let scanBitPriceFromProps = props.currentPromoter.scanBitPrice;
    if (!props.currentPromoter.scanBitPrice) {
        scanBitPriceFromProps = 0.1;
    }
    const [scanBitPrice, setScanBitPrice] = useState(scanBitPriceFromProps);

    const handleSubmit = (e) => {
        e.preventDefault()
        if (scanBitPrice !== props.currentPromoter.scanBitPrice){
            props.onSubmit(scanBitPrice)
        } else {
            props.handleCloseModal()
        }
    }

    const handleInput = (e) => {
        const res = numberNormalize(e.target.value);
        setScanBitPrice(res)
    }

    const isSaveDisable = parseFloat(scanBitPrice) === 0 ? false : !parseFloat(scanBitPrice);
    const {name, email, phone, scanbitsBalance, spentScanbits} = props.userData

        return (
            <div className="scanbits-modal">
                <div className="scanbits-modal__header">
                    <h2 className="scanbits-modal__title">Promoter Info</h2>
                </div>

                <div className="scanbits-modal__content">
                    <div className="scanbits-modal__item">
                        <span className="item__title">Promoter Name</span>
                        <span className="item__data">{name}</span>
                    </div>
                    <div className="scanbits-modal__item">
                        <span className="item__title">Email</span>
                        <span className="item__data">{email}</span>
                    </div>
                    <div className="scanbits-modal__item">
                        <span className="item__title">Phone</span>
                        <span className="item__data">{phone}</span>
                    </div>
                    <div className="scanbits-modal__item">
                        <span className="item__title">Balance</span>
                        <span className="item__data">{scanbitsBalance}</span>
                    </div>
                    <div className="scanbits-modal__item">
                        <span className="item__title">Spent Scanbits</span>
                        <span className="item__data">{spentScanbits}</span>
                    </div>

                    <div className="scanbits-modal__item">
                        <span className="item__data">1 Scanbit = 1 scanned ticket</span>
                        <span className="item__data">The default cost for a scanbit is <strong>0.1  EUR</strong></span>
                    </div>

                    <form className="scanbits-modal__form" onSubmit={handleSubmit}>
                        <span className="item__title">Scanbit Price</span>
                        <input
                            name="login"
                            type="text"
                            placeholder="Cost for a scanbit"
                            value={scanBitPrice}
                            onChange={handleInput}
                        />
                       <div className={"scanbits-modal__btn-container"}>
                           <button
                               className="btn-default"
                               type="button"
                               onClick={props.handleCloseModal}
                           >
                               Cancel
                           </button>
                           <button
                               className="btn-primary"
                               type="submit"
                               disabled={isSaveDisable}
                           >
                               Save
                           </button>
                       </div>
                    </form>
                </div>
            </div>
        );
};

export default ScanbitsModal
