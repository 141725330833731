import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";
import { getOptionsForAsyncSelect } from "./getOptionsForAsyncSelect";

export const emptyPdfTemplateOption = {
  value: null,
  label: "Empty Pdf Template",
};

export const getPdfTemplatesAsyncFunc = (
  eventId,
  withEmptyOption = false,
  emptyOption = emptyPdfTemplateOption
) => {
  const user = JSON.parse(localStorage.getItem("user"));

  return (inputValue) =>
    axios
      .get(`/PdfTemplate/EventTemplates/${eventId}`, { headers: getHeaders(user.token) })
      .then(({ data }) => {
        let options = withEmptyOption ? [emptyOption] : [];

        if (data.result && data.result.length) {
          options = [
            ...options,
            ...getOptionsForAsyncSelect(data.result, {
              labelKey: "name",
              returnDataKey: "item",
              valueKey: "id",
            }),
          ].filter(({ label }) =>
            label.toLowerCase().includes(inputValue.toLowerCase())
          );
        }
        return options;
      })
      .catch((e) => {
        console.log(e);
      });
};
