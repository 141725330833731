import React, { Fragment } from "react";
import Link from "react-router-dom/es/Link";

export const Breadcrumbs = ({ routes }) => {
  return (
    <>
      {routes.map((x, index) => {
        const isLast = index === routes.length - 1;

        return (
          <Fragment key={index}>
            {x.path ? (
              <>
                <Link to={x.path}>{x.name}</Link>
                {!isLast && " / "}
              </>
            ) : (
              <span>{x.name}</span>
            )}
          </Fragment>
        );
      })}
    </>
  );
};
