import { useState } from "react";

export const useLazyLoadOnTableScroll = (pageSize, itemsCount) => {
  const [page, setPage] = useState(0);
  const [lastScrollHeight, setLastScrollHeight] = useState(0);

  const handleTableScroll = (e) => {
    const element = e.target;
    const scrollTopLimit = element.scrollHeight - element.clientHeight * 1.3;
    const pages = Math.ceil(itemsCount / pageSize);

    if (
      element.scrollHeight > lastScrollHeight &&
      scrollTopLimit < element.scrollTop &&
      itemsCount !== 0 &&
      page < pages - 1
    ) {
      setPage((prevPage) => prevPage + 1);
      setLastScrollHeight(element.scrollHeight);
    }
  };

  const resetPage = () => {
    setPage(0);
    setLastScrollHeight(0);
  };

  return [page, handleTableScroll, resetPage];
};
