import React from 'react';

import './ScanbitsEmptyState.sass';
import ScanbitImage from '../../../images/pic_empty_screen_scanbit.png';

const scanbitsEmptyState = ({gotItHandle}) =>
    <div className="scanbits-empty-state">
        <h2 className="scanbits-empty-state__title">What is Scanbit?</h2>
        <img src={ScanbitImage} alt="Scanbit"/>
        <p className="text">Scanbit is an item inside Entree for having opportunity to scan tickets.</p>
        <p className="text">1 Scanbit = 1 scanned ticket. You can buy more scanbits at any time.</p>
        <button
            type="button"
            className="btn-primary"
            onClick={gotItHandle}
        >
            <span>Got it!</span>
        </button>
    </div>;

export default scanbitsEmptyState;
