interface SeatsIoTicket {
    id: string,
    label: string,
    category: {
        label: string
    },
    labels: {
        displayedLabel: string,
        own: string,
        parent: string,
        section: string,
    }
}

interface NoSeatTicket {
    capacity: number,
    price: number,
    isAvailableToSale: boolean,
    allowedToSell: number,
    categoryKey: string,
    count: number,
    hasSeats: boolean,
    name: string,
}

interface EntreeTicket {
    id: number,
    sacansCount: number,
    customerName: string,
    customerPhone: string,
    customerEmail: string,
    customerAddresst: string,
    customerGender: string,
    customerAge: string,
    area: string,
    row: string,
    side: string,
    seat: string
}

export function isSeatsIoTicket(ticket: any): boolean {
    return !!ticket
        && 'label' in ticket
        && 'category' in ticket
        && 'labels' in ticket
        && 'label' in ticket['category']
        && 'own' in ticket['labels']
        && 'parent' in ticket['labels']
        && 'section' in ticket['labels']
}

export function isNoSeatTicket(ticket: any): boolean {
    return !!ticket
        && 'count' in ticket
        && 'price' in ticket
        && !('area' in ticket)
        && !isNfcTicket(ticket)
}

export function isPlainSeat(ticket: any): boolean {
    return !!ticket
        && 'area' in ticket
        && 'side' in ticket
        && 'row' in ticket
        && 'seat' in ticket
        && 'fixedDiscount' in ticket
        && 'price' in ticket
        && !isNfcTicket(ticket)
}

export function isNfcTicket(ticket: any): boolean {
    return !!ticket
        && 'masterTicket' in ticket
}

export function hasSeatTicket(ticket: any): boolean {
    return !!ticket
        && 'area' in ticket
        && 'row' in ticket
        && 'seat' in ticket
        && ticket.seat
}

export function entreeTicketToSeatsIointerface(ticket, ticketTemplate) {
    const hasSeats = ticketTemplate.hasSeats;

    if (!hasSeats) {
        return {
            allowedToSell: 1,
            capacity: 1,
            categoryKey: "",
            count: 1,
            hasSeats: false,
            isAvailableToSale: true,
            name: ticketTemplate.name,
            price: ticketTemplate.price,
            ticketTemplateId: ticketTemplate.ticketTemplateId
        }
    }

    const hasSide = ticket.side === 'L' || ticket.side === 'R'
    const label = ticket.area + (hasSide && `-${ticket.side}`) + `-${ticket.row}-${ticket.seat}`

    return {
        category: {
            label: ticketTemplate.name,
        },
        id: ticket.id,
        label: '',
        labels: {
            displayedLabel: label,
            own: ticket.seat,
            parent: ticket.row,
            section: ticket.area + hasSide ? `-${ticket.side}` : '',
        },
        pricing: {
            formattedPrice: ticketTemplate.price,
            price: ticketTemplate.price,
        },
        price: ticketTemplate.price,
        seatId: label
    }
}

export function getSeatLabel(ticket: { area: string, side: string | undefined, row: string, seat: string }) {
    return `${ticket.area} ${getSeatSideLabel(ticket.side)} ${ticket.row} ${ticket.seat}`
}

export function getSeatSideLabel(side) {
    return side === 'L' && 'Left'
        || side === 'R' && 'Right'
        || side === 'M' && 'Center'
        || '';
}