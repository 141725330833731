import React from 'react';
import Collapsible from 'react-collapsible';

import './Collapsible.sass';

export const collapsible = props => {
    return (
        <Collapsible trigger={props.trigger}>
            {props.text}
        </Collapsible>
    );
};

export default collapsible;

