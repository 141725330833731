import React, { Component } from "react";
import { withRouter } from "react-router";

class TestIframe extends Component {
  render() {
    return (
      <div>
        <iframe
          title="Test pay"
          allow=""
          fra
          src="https://dev-app.fanceetickets.com/SeasonPassMember"
          width={"100%"}
          height={"800px"}
          frameborder="0"
        ></iframe>
      </div>
    );
  }
}
export default withRouter(TestIframe);
