import React from "react";
import { getRegion } from "../../../helpers/getRegion";

import "./TermsAndConditionsModal.scss";

const TermsAndConditionsModal = () => {
  const { terms } = getRegion();

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="terms" dangerouslySetInnerHTML={{ __html: terms }}>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsModal;
