import React, { Component } from "react";

import axios from "../../axios-instance";
import Container from "../../components/UI/Container/Container";
import ROUTES from "../../constants/routes";

import debounce from "lodash.debounce";

import { showErrorModal } from "../../modals";
import { SellingEventsCard } from "./SellingEventsCard/SellingEventsCard";

import "./SellingEventsList.sass";

export default class SellingEventsList extends Component {
  state = {
    search: "",
    events: [],
  };

  handleGoToEvent = (slug) => {
    this.props.history.push({
      pathname: ROUTES.SELLING.replace(":slug", slug).replace(":id?", ""),
    });
  };

  handleSearch = debounce((value) => {
    this.setState({
      search: value,
    });
  }, 500);

  getEvents = async () => {
    try {
      console.log(this.props, "sss");
      const { iframeSlug } = this.props.match.params;
      const { data } = await axios.get(
        `/Events/InSell?iframeSlug=${iframeSlug}`
      );
      if (data.isSuccess) {
        this.setState({ events: data.result });
      }
    } catch (e) {
      showErrorModal({
        showConfirmButton: false,
      });
      console.log(e);
    }
  };

  componentDidMount() {
    this.getEvents();
  }

  render() {
    const { events, search } = this.state;

    const searchedEvents = events.filter((event) =>
      event.name.toLowerCase().includes(search.toLowerCase())
    );

    return (
      <div className="selling-events__wrapper">
        <Container className="selling-events">
          <div className="selling-events__logo">
            <img src="/images/img-logo.svg" alt="Logo" />
          </div>
          <div className="selling-events__header">
            <h4>Upcoming events list</h4>
            <input
              type="text"
              placeholder="Search"
              onChange={(e) => this.handleSearch(e.target.value)}
            />
          </div>
          <div className="selling-events__hero">
            {searchedEvents.map((event) => (
              <SellingEventsCard
                {...event}
                handleClick={() => this.handleGoToEvent(event.slug)}
              />
            ))}
          </div>
        </Container>
      </div>
    );
  }
}
