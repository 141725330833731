import * as actionTypes from "../actionTypes/ticketGenerationTypes";

const initialState = {
  actionsList: [],
  loading: false,
  error: {},
};

const ticketGeneration = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.DELETE_ACTION:
    case actionTypes.STOP_ACTION:
    case actionTypes.START_ACTION:
    case actionTypes.GET_ACTIONS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_ACTIONS_SUCCESS:
      return {
        ...state,
        actionsList: action.payload,
        loading: false,
      };
    case actionTypes.START_ACTION_FAIL:
    case actionTypes.STOP_ACTION_FAIL:
    case actionTypes.GET_ACTIONS_FAIL:
    case actionTypes.DELETE_ACTION_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case actionTypes.STOP_ACTION_SUCCESS:
    case actionTypes.START_ACTION_SUCCESS:
    case actionTypes.DELETE_ACTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ticketGeneration;
