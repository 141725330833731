import * as ActionTypes from '../actionTypes/eventsTypes';
import EventDataModel from "./models/EventDataModel";

const initialState = new EventDataModel();

const eventData = (state = initialState, action) => {
	switch (action.type) {
		case ActionTypes.UPDATE_EVENT_SELL_STATISTIC:
			return state.withUpdatedSellData(action.payload);
		case ActionTypes.UPDATE_EVENT_SCAN_STATISTIC:
			return state.withUpdatedScanData(action.payload);
		default:
			return state;
	}
};

export default eventData;

export const getNextDaySellData = (state, date) => {
	return state.eventData.getSellStatisticForDay(date);
};

export const getNextDayScanData = (state, date) => {
	return state.eventData.getScanStatisticForDay(date);
};


export const getNextSellTooltipDayData = (state, date) => {
	return state.eventData.getSellStatisticDetailsDayData(date);
};

export const getNextScanTooltipDayData = (state, date) => {
	return state.eventData.getScanStatisticDetailsDayData(date);
};

export const getAvailableSellDates = (state) => {
	return state.eventData.getSellDatesWithData();
};

export const getAvailableScanDates = (state) => {
	return state.eventData.getScanDatesWithData();
};

export const getSellTicketName = (state, key) => {
	return state.eventData.getSellTicketName(key);
};

export const getScanTicketName = (state, key) => {
	return state.eventData.getScanTicketName(key);
};

export const getSellTeamName = (state, key) => {
	return state.eventData.getSellTeamName(key);
};

export const getScanTeamName = (state, key) => {
	return state.eventData.getScanTeamName(key);
};

export const getSellDeviceName = (state, key) => {
	return state.eventData.getSellDeviceName(key);
};

export const getScanDeviceName = (state, key) => {
	return state.eventData.getScanDeviceName(key);
};

//TODO bad approach, extend or remove
const chartColors = [
	'#C51062', '#F39C12', '#0B5345', '#D98880', '#3498DB', '#E74C3C', '#616A6B', '#6201EA', '#7D3C98', '#CC33FF',
	'#641E16', '#1ABC9C', '#0000FF', '#34495E', '#D35400', '#AED6F1', '#186A3B', '#33FF00', '#7E5109', '#F4D03F',
	'#AAB7B8', '#512E5F', '#27AE60', '#F8C471', '#21618C', '#990033', '#9A7D0A', '#C39BD3'
];

export const getColorsMap = (state) => {
	return new Map([
		["ticket", new Map(state.eventData.getSellTicketNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))],
		["team", new Map(state.eventData.getSellTeamNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))],
		["device", new Map(state.eventData.getSellDeviceNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))]
	]);
};

export const getColorsMapScan = (state) => {
	return new Map([
		["ticket", new Map(state.eventData.getScanTicketNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))],
		["team", new Map(state.eventData.getScanTeamNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))],
		["device", new Map(state.eventData.getScanDeviceNamesArr().map((v ,i) => [`${v}`, chartColors[i]]))]
	]);
};
