import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import ROUTES from '../../../constants/routes';
import {changePasswordRequest} from '../../../store/actions/authActions';
import {toast} from 'react-toastify';
import browserTabTitleOverride from '../../../helpers/tabTitleOverride';
import { getRegion } from '../../../helpers/getRegion';

import './RecoverPassword.sass'

class RecoverPassword extends Component {
    state = {
        email: '',
        isChangePasswordRequest: false,
        isSend: false
    };

    componentDidMount() {
        browserTabTitleOverride();
    }

    componentDidUpdate(prevProps){
        if (this.props.auth.error && this.props.auth.error !== prevProps.auth.error) {
            if (this.props.auth.error.response) {
                this.setState({errors: [this.props.auth.error.response.data]}, () => {
                    this.setState({error: Object.values(this.state.errors[0])[0][0]})
                });
            } else {
                this.setState({error: this.props.auth.error.message})
            }
        }
    }

    redirectToLogin = () => this.props.history.push({pathname: ROUTES.LOGIN});

    inputChangeHandler = event => {
        const {name, value} = event.target;

        this.setState({[name]: value});
    };

    changePasswordRequest = (e) => {
        const {email, isChangePasswordRequest} = this.state;
        const {changePasswordRequest} = this.props;
        const data = {
            email
        };

        this.setState({
            isSend: !this.state.isSend
        });

        changePasswordRequest(data)
            .then(response => {
                if (response && response.data.isSuccess) {
                    this.setState({isChangePasswordRequest: true}, () => {
                        if (isChangePasswordRequest) {
                            toast.dismiss(256);
                            toast.success('Message was sent again.', {
                                toastId: 256
                            });
                        }
                    });
                } else if (response && !response.data.isSuccess) {
                    e.preventDefault()
                    this.setState({
                        isSend: !this.state.isSend
                    });
                    this.setState({ error: response.data.errorMessage });
                } else {
                    e.preventDefault()
                    this.setState({
                        isSend: !this.state.isSend
                    });
                }
            });
    };

    render() {
        const {email, isChangePasswordRequest, error} = this.state;
        const region = getRegion();
        return (
            <div className="auth forgot-password">
                <div className="auth__logo">
                  <img
                    className="auth__logo-img"
                    src={`/${region.theme}/logo.png`}
                    alt={region.title}
                  />
                  <div className="auth__logo-title">{region.title}</div>
                </div>
                {
                    isChangePasswordRequest
                        ? <div className="forgot-password__info">
                            Reset link was sent to your email
                            <br/>
                            If message has not come, click
                            {' '}
                            <Link to={ROUTES.RECOVER_PASSWORD}
                                  onClick={this.changePasswordRequest}
                            >
                                here
                            </Link>
                            <br/>
                            to send reset link again
                            <Link className="auth__link" to={ROUTES.LOGIN}>Sign in</Link>
                        </div>

                        : <div>
                            <div className="auth__title">Recover password</div>
                            <form>
                                <p className="form-error">{error}</p>
                                <input type="email"
                                       name="email"
                                       placeholder="Email"
                                       onChange={this.inputChangeHandler}
                                       onKeyPress={event => {
                                           if (event.key === 'Enter') {
                                               event.preventDefault();
                                               this.changePasswordRequest();
                                           }
                                       }}
                                />
                            </form>
                            <div className="forgot-password__buttons-wrapper">
                                <button type="button"
                                        className="btn btn-secondary"
                                        onClick={this.redirectToLogin}
                                >
                                    Back to login
                                </button>
                                <button type="button"
                                    className="btn btn-primary"
                                    onClick={e => this.changePasswordRequest(e)}
                                    disabled={!email || this.state.isSend}
                                >
                                    Request reset link
                                </button>
                            </div>
                        </div>

                }
            </div>
        );
    }
}

RecoverPassword.propTypes = {};

const mapStateToProps = ({auth}) => ({auth});

const mapDispatchToProps = dispatch => ({
    changePasswordRequest: data => dispatch(changePasswordRequest(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
