import React, { useLayoutEffect, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";

import { DEFAULT_TEAMPLAE } from "../../../constants/canvas";
import {
  getTemplates,
  getTemplate,
  resetCanvasObjects,
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setCurrentTemplate,
  setScrollPosition,
  setZoom,
  getFooterInfo,
} from "../../../store/actions/canvasActions";
import { canvasSetCenter } from "../../Canvas/helpers";

import FieldLabel from "../../FieldLabel/FieldLabel";

const TemplateControl = ({
  currentTemplate,
  contextRef,
  canvasRef,
  windowSize,
  templates,
  isTemplatesLoading,
  setCurrentTemplate,
  setCanvasWorkingWidth,
  setCanvasWorkingHeight,
  setScrollPosition,
  setZoom,
  getTemplates,
  getTemplate,
  resetCanvasObjects,
  currentEvent,
  user,
  getFooterInfo,
}) => {
  const handleTemplate = (newOption) => {
    const { value } = newOption;

    if (!value) {
      setCurrentTemplate(DEFAULT_TEAMPLAE);
    } else {
      getTemplate(value, user.token);
    }
    if (!newOption.value) {
      resetCanvasObjects();
    }
    if (newOption.item) {
      const { item } = newOption;
      const { width, height } = item;

      setCanvasWorkingWidth(width);
      setCanvasWorkingHeight(height);

      canvasSetCenter({
        canvas: canvasRef.current,
        context: contextRef.current,
        canvasWorkingSize: { width, height },
        setScrollPosition,
        windowSize,
        setZoom,
      });
      return;
    }
  };
  const handleTemplateName = (e) => {
    const { value } = e.target;
    setCurrentTemplate({ ...currentTemplate, label: value });
  };

  useLayoutEffect(() => {
    if (currentTemplate.label) {
      return
    }

    if (currentEvent.id) {
      getTemplates(currentEvent.id)
        .then((res) => {
          if (res) {
            const currentPdfTemplate = res.find(
              ({ value }) => value === currentEvent.pdfTemplateId,
            );

            const { value } = currentPdfTemplate;
    
            getTemplate(value, user.token);
          }
        });

      getFooterInfo();
    }
  }, [currentEvent.id]);

  useEffect(() => {
    const { width, height } = currentTemplate;

    canvasSetCenter({
      canvas: canvasRef.current,
      context: contextRef.current,
      canvasWorkingSize: { width, height },
      setScrollPosition,
      windowSize,
      setZoom,
    }); 
  }, [currentTemplate.value])

  return (
    <>
      <FieldLabel
        className="canvas-template-select"
        label="Template Name"
        isRequired={
          !currentTemplate.label ||
          currentTemplate.label === DEFAULT_TEAMPLAE.label
        }
      >
        <input
          type="text"
          value={
            currentTemplate.label === DEFAULT_TEAMPLAE.label
              ? ""
              : currentTemplate.label
          }
          onChange={handleTemplateName}
        />
      </FieldLabel>
      <FieldLabel className="canvas-template-select" label="Template">
        <Select
          isLoading={isTemplatesLoading}
          options={templates}
          value={currentTemplate}
          onChange={handleTemplate}
          classNamePrefix="custom-select"
          className="custom-select"
        />
      </FieldLabel>
    </>
  );
};

const mapStateToProps = ({
  auth: { user },
  canvas: { currentTemplate, templates, isTemplatesLoading },
  events: { currentEvent },
}) => {
  return {
    user,
    currentTemplate,
    templates,
    isTemplatesLoading,
    currentEvent,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (eventId) => dispatch(getTemplates(eventId)),
  getTemplate: (templateId, token) => dispatch(getTemplate(templateId, token)),
  setCurrentTemplate: (template) => dispatch(setCurrentTemplate(template)),
  setCanvasWorkingWidth: (width) => dispatch(setCanvasWorkingWidth(width)),
  setCanvasWorkingHeight: (height) => dispatch(setCanvasWorkingHeight(height)),
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
  resetCanvasObjects: () => dispatch(resetCanvasObjects()),
  getFooterInfo: () => dispatch(getFooterInfo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplateControl);
