import getImageElementFromUrl from "../../../helpers/getImageElementFromUrl";

const renderRoundedImage = async ({
  context,
  x,
  y,
  width,
  height,
  opacity,
  imageUrl,
  imageElement,
  borderRadius,
  addImageElementToObj,
  id,
}) => {
  let img = null;

  const isImageObj = imageElement instanceof Image;

  if (isImageObj) {
    img = imageElement;
  } else if (imageUrl) {
    img = await getImageElementFromUrl(imageUrl);
    id && addImageElementToObj(id, img);
  }

  if (!img) {
    return;
  }

  context.globalAlpha = opacity / 100;
  context.save();
  context.beginPath();
  context.moveTo(x + borderRadius, y);
  context.lineTo(x + width - borderRadius, y);
  context.quadraticCurveTo(x + width, y, x + width, y + borderRadius);
  context.lineTo(x + width, y + height - borderRadius);
  context.quadraticCurveTo(
    x + width,
    y + height,
    x + width - borderRadius,
    y + height
  );
  context.lineTo(x + borderRadius, y + height);
  context.quadraticCurveTo(x, y + height, x, y + height - borderRadius);
  context.lineTo(x, y + borderRadius);
  context.quadraticCurveTo(x, y, x + borderRadius, y);
  context.closePath();
  context.clip();
  context.drawImage(img, x, y, width, height);
  context.restore();
};

export default renderRoundedImage;
