import React, {Component} from 'react';

import Container from '../../components/UI/Container/Container';

import './FAQ.sass';
import PageTitle from '../../components/UI/PageTitle/PageTitle';
import Trigger from "../../components/UI/Collapsible/Trigger/Trigger";
import Collapsible from "../../components/UI/Collapsible/Collapsible";
import browserTabTitleOverride from '../../helpers/tabTitleOverride';

class Faq extends Component {
    componentDidMount() {
        browserTabTitleOverride();
    }

    render() {
        const trigger = (
            <Trigger title="Some textSome textSome textSome textSome textSome textSome textSome textSome textSome textSome textSome textSome textSome text" />
        );

        const faqText = (
            <div>
                <p>This is the collapsible content. It can be any element or React component you like.</p>
                <p>It can even be another Collapsible component. Check out the next section!</p>
            </div>
        );

        return (
           <Container className="faq">
               <PageTitle>FAQ</PageTitle>
               <h2 className="faq__title">Something you may ask us</h2>

               <Collapsible text={faqText} trigger={trigger} />
               <Collapsible text={faqText} trigger={trigger} />
               <Collapsible text={faqText} trigger={trigger} />
               <Collapsible text={faqText} trigger={trigger} />

           </Container>
        );
    }
}

Faq.propTypes = {};

export default Faq;
