import Swal from "sweetalert2";
import ls from 'localstorage-slim';
import axios from "../axios-instance";
import { getHeaders } from "./getHeaders";

const postFileToServer = async (url, file) => {
  const body = new FormData();
  body.append("image", file);

  const user = ls.get("user");
  
  return axios
    .post(url, body, {
      headers: getHeaders(user.token),
    })
    .then((response) => {
      if (response.data.isSuccess) {
        return response;
      }

      throw new Error(response.data.errorMessage);
    })
    .catch((err) => {
      Swal({
        title: "Error!",
        text: err,
        type: "error",
        timer: 3000,
      });

      console.error(err);
    });
};

export default postFileToServer;
