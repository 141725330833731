import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = ({
                    children,
                    checked,
                    onChange,
                    isDisabled,
                    name,
                    id,
                    related
                  }) => {

  return (
    <div className="checkbox">
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
        data-related={related}
      />
        <label htmlFor={id}>{children}</label>
    </div>
  );
};

CheckBox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  children: PropTypes.any,
  isDisabled: PropTypes.bool,
  related: PropTypes.array,

};

CheckBox.defaultProps = {
  children: '',
  isDisabled: false,
  related: [],
}

export default CheckBox;