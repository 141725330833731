import moment from "moment";
import React from "react";
import {
  Bar,
  BarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import NewLoader from "../../../components/NewLoader/NewLoader";

import "./index.sass";

const Dot = ({ cx, cy }) => (
  <circle
    cx={cx}
    cy={cy}
    r={6}
    fill="#FFF"
    stroke="#6071B5"
    strokeWidth="3px"
  />
);

export const Graphic = ({ data, loading }) => {
  if (!data.length && !loading) {
    return (
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          height: "500px",
        }}
      >
        No data
      </div>
    );
  }
  return (
    <div className="stats-scan__graphic">
      {loading ? (
        <NewLoader />
      ) : (
        <ResponsiveContainer width="100%" height={450}>
          <BarChart data={data}>
            <XAxis dataKey="time" tick={<CustomizedAxisTick />} />
            <YAxis dataKey="count" />
            <Bar dataKey="count" fill="#6071B5" />
            <Tooltip
              labelFormatter={(value) =>
                `Time: ${moment(value).format("h:mm")}`
              }
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} textAnchor="end" fill="#666">
        {moment(payload.value).format("h:mm")}
      </text>
    </g>
  );
};
