import { showInfoModal } from "./showInfoModal";
import SomethingWentWrong from "../images/something-went-wrong.svg";

export const showErrorModal = ({ ...options }) =>
  showInfoModal({
    image: SomethingWentWrong,
    title: "Something Went Wrong",
    imageAltText: "Something went wrong",
    showConfirmButton: true,
    confirmButtonText: "Try Again",
    ...options,
  });
