import {
  DEFAULT_CANVAS_OBJECT,
  DEFAULT_CANVAS_PARAMS,
  DEFAULT_CANVAS_QRCODE_OBJECT,
  DEFAULT_CANVAS_WORKING_SIZE,
  DEFAULT_TEAMPLAE,
  DEFAULT_ZOOM,
  MAX_ZOOM,
  MIN_ZOOM,
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  topItemsValues,
} from "../../constants/canvas";
import * as actionTypes from "../actionTypes/canvasActionTypes";

// canvasReducer.js
const curateObjectModifications = (newObject, existing) => {
  const hasNegativeSize = newObject.width < 1 || newObject.height < 1;
  if (hasNegativeSize) {
    return existing;
  }
  const isTextWithLessThanThreshold =
    newObject.type === "text" && newObject.width < newObject.fontSize;
  return isTextWithLessThanThreshold ? existing : newObject;
};

const initialState = {
  canvasObjects: [],
  activeObjectId: null,
  canvasWorkingSize: DEFAULT_CANVAS_WORKING_SIZE,
  defaultParams: DEFAULT_CANVAS_PARAMS,
  scrollPosition: { x: 0, y: 0 },
  templates: [DEFAULT_TEAMPLAE],
  isTemplatesLoading: true,
  userMode: "select",
  actionMode: null,
  zoom: DEFAULT_ZOOM,
  currentTemplate: {},
  isLoading: false,
  textValues: [],
  isTextValuesLoading: true,
  footerInfo: {
    link: "",
    mb: "",
    name: "",
    pib: "",
  },
};

//TODO: move to helpers
export const getObjectsOrder = (objs = []) => {
  const topItems = [];
  const otherItems = [];

  console.log(objs);
  

  for (const obj of objs) {
    const topItemValue = topItemsValues.find(({ type }) => obj.type === type);

    if (!!topItemValue) {
      if (topItemValue.unique) {
        const itemIndex = topItems.findIndex(
          (o) => o.type === topItemValue.type
        );
        if (itemIndex >= 0) {
          topItems[itemIndex] = obj;
          continue;
        }
        topItems.push(obj);
        continue;
      }
      topItems.unshift(obj);
      continue;
    }

    otherItems.push(obj);
  }

  return [...otherItems, ...topItems];
};

const canvasReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CANVAS_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case actionTypes.GET_TEMPLATE:
      return {
        ...state,
        isLoading: true,
      };
    case actionTypes.SAVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.GET_TEMPLATE_SUCCESS:
      return {
        ...state,
        canvasObjects: action.payload,
        isLoading: false,
      };
    case actionTypes.GET_TEMPLATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case actionTypes.RESET_ZOOM:
      return { ...state, zoom: DEFAULT_ZOOM };

    case actionTypes.GET_TEMPLATE_TEXT_VALUES:
      return { ...state, isTextValuesLoading: true };

    case actionTypes.GET_TEMPLATE_TEXT_VALUES_SUCCESS:
      return {
        ...state,
        isTextValuesLoading: false,
        textValues: action.payload,
      };

    case actionTypes.GET_TEMPLATE_TEXT_VALUES_FAIL:
      return { ...state, isTextValuesLoading: false };

    case actionTypes.GET_TEMPLATES:
      return { ...state, isTemplatesLoading: true };

    case actionTypes.GET_TEMPLATES_SUCCESS:
      return { ...state, isLoading: false, isTemplatesLoading: false, templates: action.payload };

    case actionTypes.GET_TEMPLATES_FAIL:
      return { ...state, isTemplatesLoading: false };

    case actionTypes.SET_TEMPLATE:
      return { ...state, currentTemplate: action.payload };

    case actionTypes.GET_FOOTER_INFO_SUCCESS:
      return { ...state, footerInfo: action.payload };

      case actionTypes.RESET_TEMPLATES_STATE:
      return initialState;

    case actionTypes.SET_ZOOM:
      return { ...state, zoom: action.payload };

    case actionTypes.DECREMENT_ZOOM: {
      const newZoom = state.zoom - action.payload;
      return { ...state, zoom: newZoom < MIN_ZOOM ? MIN_ZOOM : newZoom };
    }

    case actionTypes.INCREMENT_ZOOM: {
      const newZoom = state.zoom + action.payload;
      return { ...state, zoom: newZoom > MAX_ZOOM ? MAX_ZOOM : newZoom };
    }
    case actionTypes.APPEND_RECTANGLE_OBJECT:
    case actionTypes.APPEND_ELLIPSE_OBJECT:
    case actionTypes.APPEND_FREE_DRAW_OBJECT:
    case actionTypes.APPEND_TEXT_OBJECT:
    case actionTypes.APPEND_ICON_OBJECT:
    case actionTypes.APPEND_IMAGE_OBJECT: {
      const { objectType, object } = action.payload;
      return {
        ...state,
        canvasObjects: getObjectsOrder([
          ...state.canvasObjects,
          {
            ...DEFAULT_CANVAS_OBJECT,
            type: objectType,
            ...object,
          },
        ]),
      };
    }
    case actionTypes.APPEND_QRCODE_OBJECT: {
      const { objectType, object } = action.payload;

      return {
        ...state,
        canvasObjects: getObjectsOrder([
          ...state.canvasObjects,
          {
            ...DEFAULT_CANVAS_QRCODE_OBJECT,
            type: objectType,
            ...object,
          },
        ]),
      };
    }
    case actionTypes.APPEND_FOOTER_OBJECT: {
      const { objectType, object } = action.payload;

      return {
        ...state,
        canvasObjects: getObjectsOrder([
          ...state.canvasObjects,
          {
            ...DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
            type: objectType,
            ...object,
          },
        ]),
      };
    }
    case actionTypes.UPDATE_CANVAS_OBJECT: {
      const { id, object } = action.payload;
      return {
        ...state,
        canvasObjects: state.canvasObjects.map((existing) =>
          existing.id === id
            ? curateObjectModifications({ ...existing, ...object }, existing)
            : existing
        ),
      };
    }

    // case actionTypes.APPEND_FREE_DRAW_POINT_TO_CANVAS_OBJECT:
    //   return {
    //     ...state,
    //     canvasObjects: state.canvasObjects.map((existing) => {
    //       if (existing.id === action.payload.id) {
    //         const existingFreeDrawPoints = existing.freeDrawPoints || [];
    //         return {
    //           ...existing,
    //           freeDrawPoints: [...existingFreeDrawPoints, action.payload.point],
    //         };
    //       }
    //       return existing;
    //     }),
    //   };

    case actionTypes.DELETE_CANVAS_OBJECT:
      return {
        ...state,
        canvasObjects: state.canvasObjects.filter(
          (existing) => existing.id !== action.payload
        ),
      };

    case actionTypes.MOVE_CANVAS_OBJECT:
      return {
        ...state,
        canvasObjects: state.canvasObjects.map((existing) => {
          if (existing.id === action.payload.id) {
            return {
              ...existing,
              x: existing.x + action.payload.deltaPosition.deltaX,
              y: existing.y + action.payload.deltaPosition.deltaY,
            };
          }
          return existing;
        }),
      };

    case actionTypes.RESIZE_CANVAS_OBJECT:
      return {
        ...state,
        canvasObjects: state.canvasObjects.map((existing) => {
          if (existing.id === action.payload.id) {
            const { actionModeOption, delta } = action.payload;

            switch (actionModeOption) {
              case "topLeft":
                return {
                  ...existing,
                  x: existing.x + delta.deltaX,
                  y: existing.y + delta.deltaY,
                  width: existing.width - delta.deltaX,
                  height: existing.height - delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x - (point.x - existing.x) * growthRatioX,
                  //     y: point.y - (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };

              case "topCenter":
                return {
                  ...existing,
                  y: existing.y + delta.deltaY,
                  height: existing.height - delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x,
                  //     y: point.y - (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };

              case "topRight":
                return {
                  ...existing,
                  width: existing.width + delta.deltaX,
                  y: existing.y + delta.deltaY,
                  height: existing.height - delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x + (point.x - existing.x) * growthRatioX,
                  //     y: point.y - (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };

              case "middleLeft":
                return {
                  ...existing,
                  x: existing.x + delta.deltaX,
                  width: existing.width - delta.deltaX,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   return {
                  //     x: point.x - (point.x - existing.x) * growthRatioX,
                  //     y: point.y,
                  //   };
                  // }),
                };

              case "middleRight":
                return {
                  ...existing,
                  width: existing.width + delta.deltaX,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   return {
                  //     x: point.x + (point.x - existing.x) * growthRatioX,
                  //     y: point.y,
                  //   };
                  // }),
                };

              case "bottomLeft":
                return {
                  ...existing,
                  x: existing.x + delta.deltaX,
                  width: existing.width - delta.deltaX,
                  height: existing.height + delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x - (point.x - existing.x) * growthRatioX,
                  //     y: point.y + (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };

              case "bottomCenter":
                return {
                  ...existing,
                  height: existing.height + delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x,
                  //     y: point.y + (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };

              case "bottomRight":
              default:
                return {
                  ...existing,
                  width: existing.width + delta.deltaX,
                  height: existing.height + delta.deltaY,
                  // freeDrawPoints: existing.freeDrawPoints.map((point) => {
                  //   const growthRatioX = delta.deltaX / existing.width;
                  //   const growthRatioY = delta.deltaY / existing.height;
                  //   return {
                  //     x: point.x + (point.x - existing.x) * growthRatioX,
                  //     y: point.y + (point.y - existing.y) * growthRatioY,
                  //   };
                  // }),
                };
            }
          }
          return existing;
        }),
      };

    case actionTypes.SET_CANVAS_OBJECT_LAYER_INDEX: {
      const { id, layerIndex } = action.payload;
      if (layerIndex < 0 || layerIndex > state.canvasObjects.length) {
        return state;
      }
      const updatedCanvasObjects = state.canvasObjects.map(
        (existing, index) => {
          if (existing.id === id) {
            return state.canvasObjects[layerIndex];
          }
          if (index === layerIndex) {
            return state.canvasObjects.find((o) => o.id === id);
          }
          return existing;
        }
      );

      return {
        ...state,
        canvasObjects: getObjectsOrder(updatedCanvasObjects),
      };
    }

    case actionTypes.SET_CANVAS_OBJECT_LAYER_TO_TOP: {
      const { id } = action.payload;

      return {
        ...state,
        canvasObjects: getObjectsOrder([
          ...state.canvasObjects.filter((o) => o.id !== id),
          state.canvasObjects.find((o) => o.id === id),
        ]),
      };
    }

    case actionTypes.SET_CANVAS_OBJECT_LAYER_TO_BOTTOM: {
      const { id } = action.payload;

      return {
        ...state,
        canvasObjects: getObjectsOrder([
          state.canvasObjects.find((o) => o.id === id),
          ...state.canvasObjects.filter((o) => o.id !== id),
        ]),
      };
    }

    case actionTypes.RESET_CANVAS_OBJECTS:
      return {
        ...state,
        canvasObjects: [],
      };

    case actionTypes.SET_ACTIVE_OBJECT_ID: {
      return {
        ...state,
        activeObjectId: action.payload,
      };
    }

    case actionTypes.SET_CANVAS_WORKING_WIDTH:
      return {
        ...state,
        canvasWorkingSize: {
          ...state.canvasWorkingSize,
          width: action.payload,
        },
      };
    case actionTypes.SET_CANVAS_WORKING_HEIGHT:
      return {
        ...state,
        canvasWorkingSize: {
          ...state.canvasWorkingSize,
          height: action.payload,
        },
      };

    case actionTypes.SET_DEFAULT_PARAMS:
      return {
        ...state,
        defaultParams: {
          ...state.defaultParams,
          ...action.payload,
        },
      };

    case actionTypes.SET_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: action.payload,
      };
    case actionTypes.UPDATE_SCROLL_POSITION:
      return {
        ...state,
        scrollPosition: {
          x: state.scrollPosition.x + action.payload.deltaX,
          y: state.scrollPosition.y + action.payload.deltaY,
        },
      };

    case actionTypes.SET_USER_MODE:
      return {
        ...state,
        userMode: action.payload,
      };

    case actionTypes.SET_ACTION_MODE:
      return {
        ...state,
        actionMode: action.payload,
      };
    default:
      return state;
  }
};

export default canvasReducer;
