import { getCustomURLs } from "../helpers/getCustomURLs";
import { getFooterInfo } from "../store/actions/canvasActions";

export const CANVAS_UNIQUE_ID = "CANVAS_UNIQUE_ID";
export const CANVAS_CONTROLS_OVERLAY = "CANVAS_CONTROLS_OVERLAY";

export const DEFAULT_CANVAS_WORKING_SIZE = {
  width: 1240,
  height: 1754,
};

export const DEFAULT_ZOOM = 100;
export const MIN_ZOOM = 10;
export const MAX_ZOOM = 200;

export const DEFAULT_CANVAS_PARAMS = {
  canvasBackgroundColor: "#FFFFFF",
  backgroundColorHex: "#2A4FB4",
  strokeColorHex: "#1CD71C",
  fontColorHex: "#000000",
  activeColorPicker: "SketchPicker",
  searchQueryIcons: "",
  searchQueryImages: "",
  sizePreset: null,
};

export const DEFAULT_TEAMPLAE = {
  value: null,
  label: "New Pdf Template",
  width: DEFAULT_CANVAS_WORKING_SIZE.width,
  height: DEFAULT_CANVAS_WORKING_SIZE.height,
  hasFooter: true
};

export const DEFAULT_CANVAS_OBJECT = {
  type: "",
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  backgroundColorHex: "#000000",
  strokeColorHex: "#000000",
  strokeWidth: 1,
  opacity: 100,
  borderRadius: 0,
  // freeDrawPoints: [],
  text: "",
  textJustify: false,
  textAlignHorizontal: "center",
  textAlignVertical: "middle",
  fontColorHex: "#000000",
  fontSize: 48,
  fontFamily: "sans-serif",
  fontStyle: "normal",
  fontVariant: "normal",
  fontWeight: "normal",
  fontLineHeightRatio: 1,
  svgPath: "",
  imageUrl: "",
  imageElement: null,
};

const { API_URL } = getCustomURLs();

export const DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT = {
  type: "footerImage",
  x: 0,
  y: 0,
  width: DEFAULT_CANVAS_WORKING_SIZE.width,
  height: 60,
  opacity: 100,
  // imageUrl: `${API_URL}/PdfTemplate/FooterImage`,
};

export const DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT = {
  type: "footerRectangle",
  backgroundColorHex: "#C0C7E2",
  opacity: 100,
  borderRadius: 0,
  height: DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
};

export const DEFAULT_CANVAS_FOOTER_NAME_OBJECT = {
  type: "footerName",
  fontColorHex: "#3F3F41",
  fontSize: "30",
  height: DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
  textAlignHorizontal: "center",
  textAlignVertical: "middle",
  opacity: 200
};

export const DEFAULT_CANVAS_FOOTER_MB_OBJECT = {
  ...DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  type: "footerMB",
};

export const DEFAULT_CANVAS_FOOTER_PIB_OBJECT = {
  ...DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  type: "footerPIB",
};

export const DEFAULT_CANVAS_FOOTER_LINK_OBJECT = {
  ...DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  type: "footerLINK",
};

export const DEFAULT_CANVAS_QRCODE_OBJECT = {
  type: "qrCode",
  x: 0,
  y: 0,
  borderRadius: 30,
  width: 290,
  height: 290,
  opacity: 100,
  imageUrl:
    "https://api.fanceetickets.com/chart?chs=290x290&cht=qr&chl=%7BBarcode%7D&choe=UTF-8",
};

export const topItemsValues = [
  { type: DEFAULT_CANVAS_QRCODE_OBJECT.type },
  { type: DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type, unique: true },
  { type: DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT.type, unique: true },
  { type: DEFAULT_CANVAS_FOOTER_NAME_OBJECT.type, unique: true },
  { type: DEFAULT_CANVAS_FOOTER_MB_OBJECT.type, unique: true },
  { type: DEFAULT_CANVAS_FOOTER_PIB_OBJECT.type, unique: true },
  { type: DEFAULT_CANVAS_FOOTER_LINK_OBJECT.type, unique: true },
];
