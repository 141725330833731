import queryString from "query-string";

import * as actionTypes from "../actionTypes/statisticsTypes";
import axios from "../../axios-instance";
import { getHeaders } from "../../helpers/getHeaders";

import { getOptionsForAsyncSelect } from "../../helpers/getOptionsForAsyncSelect";

const objectToArray = (rows) => {
  return Object.keys(rows).map((key) => ({ ...rows[key] }));
};

export const setSelectedEvent = (value) => (dispatch) => {
  const user = JSON.parse(localStorage.getItem("user"));

  getReportByTicketTemplatesAtEvent(value.value, user.token)(dispatch);

  dispatch({
    type: actionTypes.SET_SELECTED_EVENT,
    payload: value,
  });
};

export const setTab = (tab) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_SELECTED_TAB,
    payload: tab,
  });
};

export const getEvents = () => (dispatch) => {
  const events = [
    { name: "One Event", id: 304 },
    { name: "Two Event", id: 288 },
  ];

  dispatch({
    type: actionTypes.GET_EVENTS_SUCCESS,
    payload: events,
  });

  setSelectedEvent(
    getOptionsForAsyncSelect(events, {
      labelKey: "name",
      valueKey: "id",
    })[0]
  )(dispatch);
};

export const getReportByScanteamAtPeriod =
  (request, token, asCsv = false) =>
  (dispatch) => {
    const query = queryString.stringify({ asCsv }, { encode: false });

    dispatch({ type: actionTypes.GET_REPORTS });

    return axios
      .post(`EventReports/ByScanteamAtPeriod?${query}`, request, {
        headers: getHeaders(token),
      })
      .then(({ data }) => {
        let rows = [];
        if (data.reportRows) {
          rows = objectToArray(data.reportRows);
        }
        dispatch({
          type: actionTypes.GET_REPORTS_SUCCESS,
          payload: { scaningReports: rows },
        });
        return rows;
      })
      .catch((e) => {
        dispatch({ type: actionTypes.GET_REPORTS_FAIL });
      });
  };

export const getReportByTicketTemplatesAtEvent =
  (eventId, token, asCsv = false) =>
  (dispatch) => {
    const query = queryString.stringify({ asCsv, eventId }, { encode: false });
    dispatch({ type: actionTypes.GET_REPORTS });
    return axios
      .post(`EventReports/ByTicketTemplatesAtEvent?${query}`, null, {
        headers: getHeaders(token),
      })
      .then(({ data }) => {
        let rows = [];
        if (data.reportRows) {
          rows = objectToArray(data.reportRows);
        }
        dispatch({
          type: actionTypes.GET_REPORTS_SUCCESS,
          payload: { sellingReports: rows },
        });
        return rows;
      })
      .catch((e) => {
        dispatch({ type: actionTypes.GET_REPORTS_FAIL });
      });
  };

export const getReportByScanteamAtPeriodGroupedByTime =
  (request, token, asCsv = false) =>
  (dispatch) => {
    const query = queryString.stringify({ asCsv }, { encode: false });

    dispatch({ type: actionTypes.GET_REPORTS });

    return axios.post(
      `EventReports/ByScanteamAtPeriodGroupedByTime?${query}`,
      request,
      {
        headers: getHeaders(token),
      }
    );
  };

export const getReportBySellsAtPeriodGroupedByTime =
  (request, token, asCsv = false) =>
  (dispatch) => {
    const query = queryString.stringify({ asCsv }, { encode: false });

    dispatch({ type: actionTypes.GET_REPORTS });

    return axios.post(
      `EventReports/BySellsAtPeriodGroupedByTime?${query}`,
      request,
      {
        headers: getHeaders(token),
      }
    );
  };
