import * as actionTypes from '../actionTypes/paymentsActionTypes';

const initialState = {
    paymentMethodsResponse: null,
    records: null,
    scanbitsCount: 0,
    userInfo: null,
    loading: false
};

const payments = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
      case actionTypes.GET_CREDIT_CARD_START:
          return {...state, loading: true};
      case actionTypes.GET_CREDIT_CARD_SUCCESS:
          return {
              ...state,
              loading: false,
              paymentMethodsResponse: payload.card,
          };
      case actionTypes.GET_CREDIT_CARD_FAIL:
          return {...state, loading: false};


      case actionTypes.UPDATE_CREDIT_CARD_START:
          return {...state, loading: true};
      case actionTypes.UPDATE_CREDIT_CARD_SUCCESS:
          return {
              ...state,
              loading: false,
              paymentMethodsResponse: payload.card,
          };
      case actionTypes.UPDATE_CREDIT_CARD_FAIL:
          return {...state, loading: false};

      case actionTypes.CREATE_CREDIT_CARD_START:
          return {...state, loading: true};
      case actionTypes.CREATE_CREDIT_CARD_SUCCESS:
          return {
              ...state,
              loading: false,
              paymentMethodsResponse: payload.card,
          };
      case actionTypes.CREATE_CREDIT_CARD_FAIL:
          return {...state, loading: false};


      case actionTypes.GET_ALL_PAYMENTS_START:
          return {...state, loading: true};
      case actionTypes.GET_ALL_PAYMENTS_SUCCESS:
          return {
              ...state,
              loading: false,
              records: payload.payments,
          };
      case actionTypes.GET_ALL_PAYMENTS_FAIL:
          return {...state, loading: false, records:[]};

      case actionTypes.GET_SCANBITS_COUNT_START:
          return {...state, loading: true};
      case actionTypes.GET_SCANBITS_COUNT_SUCCESS:
          return {
              ...state,
              loading: false,
              scanbitsCount: payload.count,
          };
      case actionTypes.GET_SCANBITS_COUNT_FAIL:
          return {...state, loading: false};

      case actionTypes.PAY_NOW_START:
          return {...state, loading: true};
      case actionTypes.PAY_NOW_SUCCESS:
          return {
              ...state,
              loading: false,
              records: [
                  payload.result,
                  ...state.records,
              ],
              scanbitsCount: state.scanbitsCount + payload.result.scanbitsAmount,
          };
      case actionTypes.PAY_NOW_FAIL:
          return {
              ...state,
              loading: false,
          };

      case actionTypes.PAYMENT_START:
          return {...state, loading: true};
      case actionTypes.PAYMENT_SUCCESS:
          return {
              ...state,
              loading: false,
              records: [
                  payload.result,
                  ...state.records,
              ],
              scanbitsCount: state.scanbitsCount + payload.result.scanbitsAmount,
          };
      case actionTypes.PAYMENT_FAIL:
          return {
              ...state,
              loading: false,
          };
      case actionTypes.GET_USER_PAYMENT_INFO_START:
          return {...state, loading: true};
      case actionTypes.GET_USER_PAYMENT_INFO_SUCCESS:
          return {
              ...state,
              loading: false,
              userInfo: payload,
          };
      case actionTypes.GET_USER_PAYMENT_INFO_FAIL:
          return {
              ...state,
              loading: false,
          };
          
      default:
          return {...state};

  }
};

export default payments;
