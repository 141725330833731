import * as actionTypes from '../actionTypes/authTypes.js';

const user = JSON.parse(localStorage.getItem('user'));

const initialState = {
  loading: false,
  loggedIn: !!user,
  user: user ? user : {},
  loadingRegister: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REGISTRATION_START:
      return {
        ...state,
        loadingRegister: true,
      };
    case actionTypes.REGISTRATION_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
        },
        loadingRegister: false,
      };
    case actionTypes.REGISTRATION_FAIL:
      return {
        ...state,
        error: action.error,
        loadingRegister: false,
      };

    case actionTypes.SIGN_IN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          ...action.payload,
          },
        error: '',
        loggedIn: true,
        loading: false,
      };
    case actionTypes.SIGN_IN_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case actionTypes.LOGOUT_START:
      return {
        ...state,
        user: {},
        loggedIn: false,
        error: action.error ? action.error : null,
      };

    case actionTypes.REFRESH_TOKEN_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.user,
        },
        loading: false,
      };
    case actionTypes.REFRESH_TOKEN_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.CHANGE_PASSWORD_REQUEST_START:
      return {
        ...state,
      };
    case actionTypes.CHANGE_PASSWORD_REQUEST_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.CHANGE_PASSWORD_REQUEST_FAIL:
      return {
        ...state,
        error: action.error,
      };

    case actionTypes.CHANGE_PASSWORD_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        error: null
      }

    default:
      return state;
  }
};

export default reducer;
