export const GET_EMAILS = "GET_EMAILS";
export const GET_EMAILS_SUCCESS = "GET_EMAILS_SUCCESS";
export const GET_EMAILS_FAIL = "GET_EMAILS_FAIL";

export const GET_EMAIL = "GET_EMAIL";
export const GET_EMAIL_SUCCESS = "GET_EMAIL_SUCCESS";
export const GET_EMAIL_FAIL = "GET_EMAIL_FAIL";

export const RESEND_EMAIL = "RESEND_EMAIL";
export const RESEND_EMAIL_SUCCESS = "RESEND_EMAIL_SUCCESS";
export const RESEND_EMAIL_FAIL = "RESEND_EMAIL_FAIL";

export const GET_PROBLEMATIC_EMAILS_COUNT_SUCCESS = "GET_PROBLEMATIC_EMAILS_COUNT_SUCCESS";

export const UPDATE_PROBLEMATIC_EMAIL = "UPDATE_PROBLEMATIC_EMAIL";
export const UPDATE_PROBLEMATIC_EMAIL_SUCCESS = "UPDATE_PROBLEMATIC_EMAIL_SUCCESS";
export const UPDATE_PROBLEMATIC_EMAIL_FAIL = "UPDATE_PROBLEMATIC_EMAIL_FAIL";
