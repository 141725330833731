export const GET_EVENTS = "GET_STATISTICS_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_STATISTICS_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_STATISTICS_EVENTS_FAIL";

export const SET_SELECTED_EVENT = "SET_STATISTICS_SELECTED_EVENT";

export const SET_SELECTED_TAB = "SET_STATISTICS_SELECTED_TAB";

export const GET_REPORTS = "GET_STATISTICS__REPORTS";
export const GET_REPORTS_SUCCESS = "GET_STATISTICS_REPORTS_SUCCESS";
export const GET_REPORTS_FAIL = "GET_STATISTICS_REPORTS_FAIL";
