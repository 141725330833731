import React, {Component} from 'react';

import '../Payments.sass'

class PleaseWaitModal extends Component {
    render() {
        return (
            <p>
                <strong>Please wait</strong>
                Processing payment...
            </p>
        );
    }
}

export default PleaseWaitModal;
