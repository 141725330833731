import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";

import App from "./App";
import rootReducer from "./store/reducers/rootReducer";
import ActionsMenuProvider from "./components/UI/ActionsMenu/store/ActionsMenuProvider";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk, logger))
);

const APP = (
  <Provider store={store}>
    <BrowserRouter>
      <ActionsMenuProvider>
        <App />
      </ActionsMenuProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(APP, document.getElementById("root"));

export default store;
