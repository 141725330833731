import React from 'react';

import './PageTitle.sass';

//TODO remove children method
//in future: check if it can be independent
const PageTitle = ({children, notAbsolute, labels}) => {
    if (labels) {
        return (
          <div className={`page-title ${notAbsolute ? 'page-title--not-absolute' : ''}`}>
              {labels.map((item, key) =>
                key === 0 ? item : [<span key="separator" className={"page-title-divider"}> / </span>, item])}
          </div>
        );
    }
    return (
      <div className={`page-title ${notAbsolute ? 'page-title--not-absolute' : ''}`}>
        {children}
      </div>
    );
};

export default PageTitle;