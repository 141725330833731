import isEqual from "lodash.isequal";
import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCanvasObject } from "../../../store/actions/canvasActions";
import FieldLabel from "../../FieldLabel/FieldLabel";

class FrameControl extends Component {
  state = {
    x: "",
    y: "",
    height: "",
    width: "",
  };

  componentDidMount() {
    const { activeObject } = this.props;
    const { width, height, x, y } = activeObject;
    this.setState({
      x: x.toFixed(),
      y: y.toFixed(),
      height: height.toFixed(),
      width: width.toFixed(),
    });
  }

  componentDidUpdate(prevProps) {
    const { activeObject: prevActiveObj } = prevProps;
    const { activeObject } = this.props;
    const isActiveObjectEqual = isEqual(activeObject, prevActiveObj);

    if (!isActiveObjectEqual && activeObject) {
      const { x, y, height, width } = activeObject;
      this.setState({
        x: x.toFixed(),
        y: y.toFixed(),
        height: height.toFixed(),
        width: width.toFixed(),
      });
    }
  }

  handleValueChange(key, value) {
    if (!Number.isFinite(value)) return;

    const { activeObject, updateCanvasObject, activeObjectId } = this.props;

    const updatedObject = { ...activeObject, [key]: value };

    updateCanvasObject(activeObjectId, updatedObject);
  }

  handleChangeX = (e) => {
    const { value } = e.target;
    this.setState({ x: value });
    if (value) {
      this.handleValueChange("x", parseFloat(value));
    }
  };
  handleChangeY = (e) => {
    const { value } = e.target;
    this.setState({ y: value });

    if (value) {
      this.handleValueChange("y", parseFloat(value));
    }
  };
  handleChangeWidth = (e) => {
    const { value } = e.target;
    this.setState({ width: value });

    if (value) {
      this.handleValueChange("width", parseFloat(value));
    }
  };
  handleChangeHeight = (e) => {
    const { value } = e.target;
    this.setState({ height: value });

    if (value) {
      this.handleValueChange("height", parseFloat(value));
    }
  };

  render() {
    const { height, width, x, y } = this.state;

    return (
      <div className="frame-control">
        <h5 className="control-title">Frame</h5>
        <div className="frame-control__grid">
          <FieldLabel className="frame-control__field" label="X">
            <input type="text" value={x} onChange={this.handleChangeX} />
          </FieldLabel>
          <FieldLabel className="frame-control__field" label="Y">
            <input type="text" value={y} onChange={this.handleChangeY} />
          </FieldLabel>
          <FieldLabel className="frame-control__field" label="W">
            <input
              type="text"
              value={width}
              onChange={this.handleChangeWidth}
            />
          </FieldLabel>
          <FieldLabel className="frame-control__field" label="H">
            <input
              type="text"
              value={height}
              onChange={this.handleChangeHeight}
            />
          </FieldLabel>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ canvas }) => ({
  userMode: canvas.userMode,
  activeObjectId: canvas.activeObjectId,
  canvasObjects: canvas.canvasObjects,
  activeObject: canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  ),
});

const mapDispatchToProps = (dispatch) => ({
  updateCanvasObject: (id, object) => dispatch(updateCanvasObject(id, object)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FrameControl);
