import React from 'react';
import {Link} from 'react-router-dom';

import './Page404.sass';
import NotFoundImage from '../../images/pic_404.png';

const page404 = () => {
    return (
        <div className="not-found">
            <h2>Sorry, page is not found</h2>
            <img src={NotFoundImage} alt="not found"/>
            <Link to="/">
                <button className="btn-primary">Back to main</button>
            </Link>
        </div>
    );
};

export default page404;
