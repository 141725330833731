import React, { useEffect, useState } from "react";
import Select from "react-select";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import { SeasonPassFieldLabel } from "../../SeasonPass/SeasonPassFieldLabel";
import moment from "moment";

import "../OpenSell.sass";
import { days, month, years } from "../../SeasonPass/constants";
import { isValidEmail } from "../../../helpers/validators";

const genders = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

export const FillFields = ({
  fields,
  checkboxeFields,
  handleChange,
  shouldCheckCheckboxes = true,
  handleChangeCheckbox,
  showTermsAndConditionsModal,
  requiredFields = [],
}) => {
  const [pickerData, setPickerData] = useState({
    year: null,
    month: null,
    days: null,
  });

  const [validEmail, setValidEmail] = useState(true);

  useEffect(() => {
    setValidEmail(isValidEmail(fields.buyerEmail) || fields.buyerEmail === "");
  }, [fields.buyerEmail]);

  const checkIsRequired = (key) => {
    return requiredFields.some((name) => name === key) && !fields[key];
  };

  useEffect(() => {
    const isNoEmpty = Object.keys(pickerData).every((key) => !!pickerData[key]);

    if (!isNoEmpty && fields.birthday) {
      setPickerData({
        year: moment(fields.birthday).year(),
        month: moment(fields.birthday).month(),
        days: +moment(fields.birthday).format("D"),
      });
    }
  }, [fields.birthday]);

  const handleChangeDate = (type, date) => {
    let newPickerData = { ...pickerData, [type]: date };

    setPickerData(newPickerData);

    const isNoEmpty = Object.keys(newPickerData).every(
      (key) => typeof newPickerData[key] === "number"
    );

    if (isNoEmpty) {
      const birthday = moment()
        .year(newPickerData.year)
        .month(newPickerData.month)
        .set("D", newPickerData.days)
        .toDate();

      handleChange("birthday", birthday);
    }
  };

  return (
    <div className="partizan-open-selling__step">
      <div className="partizan-open-selling__step-hero">
        <div className="partizan-open-selling__fields-group">
          <SeasonPassFieldLabel
            label="Full Name"
            isRequired={checkIsRequired("fullName")}
          >
            <input
              className="seasonpass__field-input"
              name="fullName"
              placeholder="Enter your full name"
              onChange={(e) => handleChange("fullName", e.target.value)}
              type="text"
              value={fields.fullName}
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel
            label="Gender"
            isRequired={checkIsRequired("gender")}
          >
            <Select
              options={genders}
              name="gender"
              placeholder="Select your gender"
              value={genders.find(({ value }) => fields.gender === value)}
              onChange={(value) => handleChange("gender", value.value)}
              classNamePrefix="custom-select"
              className="partizan-open-selling__select"
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel
            label="Birth Date"
            shouldWrapInLabel={false}
            isRequired={checkIsRequired("birthday")}
          >
            <div className="partizan-open-selling__picker-wrapper">
              <Select
                options={years}
                placeholder="Year"
                value={years.find(({ value }) => pickerData.year === value)}
                onChange={(value) => handleChangeDate("year", value.value)}
                classNamePrefix="custom-select"
                className="partizan-open-selling__select"
              />
              <Select
                options={month}
                placeholder="Month"
                value={month.find(({ value }) => pickerData.month === value)}
                onChange={(value) => handleChangeDate("month", value.value)}
                classNamePrefix="custom-select"
                className="partizan-open-selling__select"
              />
              <Select
                options={days}
                placeholder="Day"
                value={days.find(({ value }) => pickerData.days === value)}
                onChange={(value) => handleChangeDate("days", value.value)}
                classNamePrefix="custom-select"
                className="partizan-open-selling__select"
              />
            </div>
          </SeasonPassFieldLabel>
        </div>

        <div className="partizan-open-selling__fields-group">
          <SeasonPassFieldLabel
            label="Street and House Number"
            isRequired={checkIsRequired("physicalAddress")}
          >
            <input
              className="seasonpass__field-input"
              placeholder="Enter your street and house number"
              name="physicalAddress"
              onChange={(e) => handleChange("physicalAddress", e.target.value)}
              type="text"
              value={fields.physicalAddress}
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel
            label="City"
            isRequired={checkIsRequired("city")}
          >
            <input
              className="seasonpass__field-input"
              name="city"
              placeholder="Enter your city"
              onChange={(e) => handleChange("city", e.target.value)}
              type="text"
              value={fields.city}
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel
            label="Postal Code"
            isRequired={checkIsRequired("postalCode")}
          >
            <input
              className="seasonpass__field-input"
              name="postalCode"
              placeholder="Enter your postal code"
              onChange={(e) => handleChange("postalCode", e.target.value)}
              type="text"
              value={fields.postalCode}
            />
          </SeasonPassFieldLabel>
        </div>

        <div className="seasonpass__grouped-fields">
          <SeasonPassFieldLabel
            label="Phone Number"
            isRequired={checkIsRequired("phoneNumber")}
          >
            <input
              className="seasonpass__field-input"
              name="phoneNumber"
              placeholder="Enter your phone number"
              onChange={(e) => handleChange("phoneNumber", e.target.value)}
              type="text"
              value={fields.phoneNumber}
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel
            label="Buyer Email"
            isRequired={checkIsRequired("buyerEmail")}
          >
            <input
              className="seasonpass__field-input"
              name="buyerEmail"
              placeholder="Enter your email"
              onChange={(e) => handleChange("buyerEmail", e.target.value)}
              type="text"
              value={fields.buyerEmail}
            />
            {!validEmail && (
              <span className="input-valid-error">
                This is an invalid email
              </span>
            )}
          </SeasonPassFieldLabel>
        </div>

        <div className="seasonpass__grouped-fields">
          <SeasonPassFieldLabel label="Company Vat Number">
            <input
              className="seasonpass__field-input"
              name="companyVatNumber"
              placeholder="Enter company vat number"
              onChange={(e) => handleChange("companyVatNumber", e.target.value)}
              type="text"
              value={fields.companyVatNumber}
            />
          </SeasonPassFieldLabel>

          <SeasonPassFieldLabel label="Company Name">
            <input
              className="seasonpass__field-input"
              name="companyName"
              placeholder="Enter company company name"
              onChange={(e) => handleChange("companyName", e.target.value)}
              type="text"
              value={fields.companyName}
            />
          </SeasonPassFieldLabel>
        </div>

        {shouldCheckCheckboxes && (
          <CheckBox
            checked={checkboxeFields.termsAndConditionsChecked}
            id="terms-and-conditions-checkbox"
            name="terms-and-conditions-checkbox"
            onChange={() => handleChangeCheckbox("termsAndConditionsChecked")}
          >
            <span className="partizan-open-selling__text-medium-small">
              I agree with
              <span
                className="partizan-open-selling__terms-of-service-text"
                onClick={showTermsAndConditionsModal}
              >
                Terms of Service
              </span>
            </span>
          </CheckBox>
        )}
      </div>
    </div>
  );
};
