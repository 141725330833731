import React, { Component } from "react";
import { connect } from "react-redux";
import { updateCanvasObject } from "../../../store/actions/canvasActions";
import FieldLabel from "../../FieldLabel/FieldLabel";
import PageTitle from "../../UI/PageTitle/PageTitle";

class OpacityControl extends Component {
  handleOpacityChange = (event) => {
    const { activeObject, updateCanvasObject } = this.props;
    const value = event.target.value;
    if (!isNaN(value) && value >= 0 && value <= 100) {
      updateCanvasObject(activeObject.id, {
        opacity: value,
      });
    }
  };

  render() {
    const { activeObject } = this.props;

    if (!activeObject) {
      return null;
    }

    return (
      <FieldLabel className="control-field" label="Opacity">
        <input
          type="number"
          value={activeObject.opacity}
          onChange={this.handleOpacityChange}
          placeholder="Opacity"
        />
      </FieldLabel>
    );
  }
}

const mapStateToProps = (state) => {
  const { canvas } = state;
  const activeObject = canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  );
  return {
    activeObject,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCanvasObject: (id, updates) =>
    dispatch(updateCanvasObject(id, updates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OpacityControl);
