import React from 'react';

import './BillingEmptyState.sass';
import EmptyStateImage from '../../../images/pic_bill.png'

const billingEmptyState = () =>
    <div className="billings-empty-state">
        <h2 className="billings-empty-state__title">There are no invoices</h2>
        <img src={EmptyStateImage} alt="No invoices"/>
    </div>;

export default billingEmptyState;
