import React from "react";

import "./FieldLabel.sass"

const FieldLabel = ({
  children,
  label = "",
  shouldWrapInLabel = true,
  isRequired = false,
  className = "",
}) => {

    const labelClassName = ["field-label", className].join(" ")
    const textWrapperClassName = ["field-label__text-wrapper", `${className}__text-wrapper`].join(" ")
    const textClassName = ["field-label__text", `${className}__text`].join(" ")
    const textRequiredClassName = ["field-label__text_required", `${className}__text_required`].join(" ")

  if (!shouldWrapInLabel) {
    return (
      <div className={labelClassName}>
        <span className={textWrapperClassName}>
          <span className={textClassName}>{label}</span>
          {isRequired && (
            <span className={textRequiredClassName}>Required Field</span>
          )}
        </span>
        {children}
      </div>
    );
  }

  return (
    <label className={labelClassName}>
      <span className={textWrapperClassName}>
        <span className={textClassName}>{label}</span>
        {isRequired && (
          <span className={textRequiredClassName}>Required Field</span>
        )}
      </span>
      {children}
    </label>
  );
};

export default FieldLabel;
