import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { canvasSetCenter } from "../Canvas/helpers";
import { useShowActionsMenu } from "../UI/ActionsMenu/hooks/useShowActionsMenu";
import {
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setScrollPosition,
  setZoom,
  setCurrentTemplate,
  updateCanvasObject,
} from "../../store/actions/canvasActions";

import "./CavasSizesMenu.sass";
import { DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT } from "../../constants/canvas";

const sizeOptions = [
  {
    value: {
      width: 1240,
      height: 1754,
    },
    label: "1240 x 1754",
  },
  {
    value: {
      width: 1600,
      height: 650,
    },
    label: "1600 x 650",
  },
];

const CavasSizesMenu = ({
  canvasRef,
  contextRef,
  windowSize,
  setScrollPosition,
  setCanvasWorkingHeight,
  setCanvasWorkingWidth,
  setZoom,
  currentTemplate,
  setCurrentTemplate,
  updateCanvasObject,
  canvasObjects
}) => {
  const [currSize, setCurrSize] = useState({
    label: ``,
    value: {}
  });

  const handleChange = ({ value: { width, height } }) => {
    setCanvasWorkingHeight(height);
    setCanvasWorkingWidth(width);

    const footerObj = canvasObjects.find((o) => o.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type);

    if (footerObj) {
      updateCanvasObject(footerObj, { width, y: height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height })
    }

    setCurrSize(
      {
        label: `${width} x ${height}`,
        value: {
          width,
          height,
        }
      }
    );

    setCurrentTemplate({ ...currentTemplate, width, height })

    canvasSetCenter({
      canvas: canvasRef.current,
      context: contextRef.current,
      canvasWorkingSize: { width, height },
      setScrollPosition,
      windowSize,
      setZoom,
    });
  };

  useEffect(() => {
    const { width, height } = currentTemplate;
    setCanvasWorkingHeight(height);
    setCanvasWorkingWidth(width);

    if (width) {
      setCurrSize(
        {
          label: `${width} x ${height}`,
          value: {
            width,
            height,
          }
        }
      );
    }
  }, [currentTemplate])

  return (
    <div className="canvas-size-menu">
      <Select
        options={sizeOptions}
        name="sizeOptions"
        classNamePrefix="custom-select"
        value={currSize}
        defaultValue={sizeOptions[0]}
        onChange={handleChange}
        className="custom-select"
      />
    </div>
  );
};

const mapStateToProps = ({ canvas: { currentTemplate, canvasObjects } }) => {
  return { currentTemplate, canvasObjects };
};

const mapDispatchToProps = (dispatch) => ({
  setCanvasWorkingWidth: (width) => dispatch(setCanvasWorkingWidth(width)),
  setCanvasWorkingHeight: (height) => dispatch(setCanvasWorkingHeight(height)),
  setScrollPosition: (scrollPosition) =>
    dispatch(setScrollPosition(scrollPosition)),
  setZoom: (zoom) => dispatch(setZoom(zoom)),
  setCurrentTemplate: (template) => dispatch(setCurrentTemplate(template)),
  updateCanvasObject: (id, obj) => dispatch(updateCanvasObject(id, obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CavasSizesMenu);
