import React, { Component } from "react";
import Select from "react-select";

import "./SecondTab.sass";

class SecondTab extends Component {
  render() {
    const { providers, handleChange, searchInputHandle, searchValue } =
      this.props;
    const options = [];
    providers.forEach((provider) => {
      options.push({ label: provider.name, value: provider.id });
    });

    const customSelectStyles = {
      control: (base) => ({
        ...base,
        cursor: "pointer",
      }),
    };
    return (
      <div className="second-tab">
        <div className="input-block">
          <Select
            placeholder="Enter a ticket provider name"
            options={options}
            classNamePrefix="select-provider"
            onChange={(value) => {
              handleChange("selectedProvider", value);
            }}
            styles={customSelectStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "#6071B5",
                primary25: "#F2F4FE",
                primary50: "#F2F4FE",
                primary75: "#F2F4FE",
              },
            })}
            onInputChange={searchInputHandle}
            inputValue={searchValue}
          />
        </div>
      </div>
    );
  }
}

export default SecondTab;
