import React from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from '../../../../../axios-instance';
import { toast } from 'react-toastify';
import { getHeaders } from '../../../../../helpers/getHeaders';
import { getSeatDescription } from '../../../../../helpers/getSeatDescription';

const MySwal = withReactContent(Swal);

export const ActionTicketModal = ({
  ticket,
  token,
  actionWordList,
  endpoint,
  getData,
  setLoading
}) => {
  const seat = {
    area: ticket.area,
    side: ticket.side,
    row: ticket.row,
    seat: ticket.seat,
    ticketTemplate: {
      ticketName: ticket.ticketName,
    },
  };

  const actionTicketHandler = () => {
    MySwal.fire({
      title: `Are you sure you want to ${actionWordList[0]} ticket?`,
      html,
      showConfirmButton: false,
      customClass: 'confirm-refund-tickets-modal',
    });
  };

  const secondActionTicketHandler = () => {
    setLoading(true);
    MySwal.close();

    axios
      .post(endpoint, [ticket.id], {
        headers: getHeaders(token),
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data) {
            toast.success(`Ticket successfully ${actionWordList[1]}`);

            getData();

            return;
          } else {
            throw new Error(`Ticket not ${actionWordList[1]}`);
          }
        }
      })
      .catch((err) => {
        toast.error(`Ticket not ${actionWordList[1]}`);
      })
      .finally(() => setLoading(false));
  };

  const html = (
    <>
      <div className='seats-list'>
        <span>Ticket that will be {actionWordList[1]}:</span>
        <span key={seat.id}>{getSeatDescription(seat)}</span>
      </div>
      <div className='swal2-actions'>
        <button
          className='swal2-cancel swal2-styled'
          onClick={() => MySwal.close()}
        >
          Cancel
        </button>
        <button
          className='swal2-confirm swal2-styled'
          onClick={secondActionTicketHandler}
        >
          {actionWordList[0] === 'cancel' ? 'Confirm' : (
            actionWordList[0][0].toUpperCase() + actionWordList[0].slice(1)
          )}
        </button>
      </div>
    </>
  );

  return (
    <>
      <div className='label-block'>
        <span className='swal2-label'>Name</span>
        <span className='swal2-label'>
          <b>{ticket.fullName}</b>
        </span>
      </div>
      <div className='label-block'>
        <label className='swal2-label'>Email</label>
        <label className='swal2-label'>
          <b>{ticket.customerEmail}</b>
        </label>
      </div>
      <div className='label-block'>
        <label className='swal2-label'>Ticket that will be {actionWordList[1]}</label>
        <label className='swal2-label'>
          <b>{getSeatDescription(seat)}</b>
        </label>
      </div>
      <div className='swal2-actions'>
        <button
          className='swal2-cancel swal2-styled'
          onClick={() => MySwal.close()}
        >
          Cancel
        </button>
        <button
          className='swal2-confirm swal2-styled'
          onClick={actionTicketHandler}
        >
          {actionWordList[0] === 'cancel' ? 'Confirm' : (
            actionWordList[0][0].toUpperCase() + actionWordList[0].slice(1)
          )}
        </button>
      </div>
    </>
  );
};
