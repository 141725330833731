export const GET_ACTIONS = "GET_ACTIONS";
export const GET_ACTIONS_SUCCESS = "GET_ACTIONS_SUCCESS";
export const GET_ACTIONS_FAIL = "GET_ACTIONS_FAIL";

export const STOP_ACTION = "STOP_ACTION";
export const STOP_ACTION_SUCCESS = "STOP_ACTION_SUCCESS";
export const STOP_ACTION_FAIL = "STOP_ACTION_FAIL";

export const START_ACTION = "START_ACTION";
export const START_ACTION_SUCCESS = "START_ACTION_SUCCESS";
export const START_ACTION_FAIL = "START_ACTION_FAIL";

export const DELETE_ACTION = "DELETE_ACTION";
export const DELETE_ACTION_SUCCESS = "DELETE_ACTION_SUCCESS";
export const DELETE_ACTION_FAIL = "DELETE_ACTION_FAIL";
