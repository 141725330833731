export const GET_EMAIL_TEMPLATES_START = "GET_EMAIL_TEMPLATES_START";
export const GET_EMAIL_TEMPLATES_SUCCESS = "GET_EMAIL_TEMPLATES_SUCCESS";
export const GET_EMAIL_TEMPLATES_FAIL = "GET_EMAIL_TEMPLATES_FAIL";

export const GET_EVENTS_START = "GET_EVENTS_START";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_FAIL = "GET_EVENTS_FAIL";

export const ADD_EVENT_START = "ADD_EVENT_START";
export const ADD_EVENT_SUCCESS = "ADD_EVENT_SUCCESS";
export const ADD_EVENT_FAIL = "ADD_EVENT_FAIL";

export const RESET_EVENTS_ERROR = "RESET_EVENTS_ERROR";

export const GET_EVENT_START = "GET_EVENT_START";
export const GET_EVENT_SUCCESS = "GET_EVENT_SUCCESS";
export const GET_EVENT_FAIL = "GET_EVENT_FAIL";

export const CREATE_TICKET_START = "CREATE_TICKET_START";
export const CREATE_TICKET_SUCCESS = "CREATE_TICKET_SUCCESS";
export const CREATE_TICKET_FAIL = "CREATE_TICKET_FAIL";

export const CREATE_TICKET_RULE_START = "CREATE_TICKET_RULE_START";
export const CREATE_TICKET_RULE_SUCCESS = "CREATE_TICKET_RULE_SUCCESS";
export const CREATE_TICKET_RULE_FAIL = "CREATE_TICKET_RULE_FAIL";

export const UPDATE_TICKET_RULE_START = "UPDATE_TICKET_RULE_START";
export const UPDATE_TICKET_RULE_SUCCESS = "UPDATE_TICKET_RULE_SUCCESS";
export const UPDATE_TICKET_RULE_FAIL = "UPDATE_TICKET_RULE_FAIL";

export const REMOVE_TICKET_RULE_START = "REMOVE_TICKET_RULE_START";
export const REMOVE_TICKET_RULE_SUCCESS = "REMOVE_TICKET_RULE_SUCCESS";
export const REMOVE_TICKET_RULE_FAIL = "REMOVE_TICKET_RULE_FAIL";

export const GET_TICKET_START = "GET_TICKET_START";
export const GET_TICKET_SUCCESS = "GET_TICKET_SUCCESS";
export const GET_TICKET_FAIL = "GET_TICKET_FAIL";

export const GET_UPDATE_TICKET_START = "GET_UPDATE_TICKET_START";
export const GET_UPDATE_TICKET_SUCCESS = "GET_UPDATE_TICKET_SUCCESS";
export const GET_UPDATE_TICKET_FAIL = "GET_UPDATE_TICKET_FAIL";

export const REMOVE_TICKET_START = "REMOVE_TICKET_START";
export const REMOVE_TICKET_SUCCESS = "REMOVE_TICKET_SUCCESS";
export const REMOVE_TICKET_FAIL = "REMOVE_TICKET_FAIL";

export const DELETE_EVENT_START = "DELETE_EVENT_START";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAIL = "DELETE_EVENT_FAIL";

export const UPDATE_EVENT_START = "UPDATE_EVENT_START";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAIL = "UPDATE_EVENT_FAIL";

export const CLEAR_CURRENT_EVENT = "CLEAR_CURRENT_EVENT";

export const GET_EVENT_SELL_STATS_START = "GET_EVENT_SELL_STATS_START";
export const GET_EVENT_SELL_STATS_FAIL = "GET_EVENT_SELL_STATS_FAIL";

export const UPDATE_EVENT_SELL_STATISTIC = "UPDATE_EVENT_SELL_STATISTIC";

export const GET_EVENT_SCAN_STATS_START = "GET_EVENT_SCAN_STATS_START";
export const GET_EVENT_SCAN_STATS_FAIL = "GET_EVENT_SCAN_STATS_FAIL";

export const UPDATE_EVENT_SCAN_STATISTIC = "UPDATE_EVENT_SCAN_STATISTIC";
