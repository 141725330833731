import React from "react";

import ReactTable from "react-table";
import NewLoader from "../../../components/NewLoader/NewLoader";

import "../../Statistics/StatsInfo/StatsInfo.sass";

function Table({ groupByTime, data = [], loading }) {
  if (loading) {
    return <NewLoader />;
  }

  const columns = groupByTime
    ? []
    : [
        {
          Header: "Ticket Name",
          accessor: "ticketName",
          width: 280,
        },
        {
          Header: "Cash",
          accessor: "byCash",
        },
        {
          Header: "Pin",
          accessor: "byPin",
        },
        {
          Header: "Online",
          accessor: "byOnline",
        },
        // {
        //   Header: "App login",
        //   accessor: "appLogin",
        // },
      ];

  return (
    <div className="statistics__table">
      <ReactTable
        columns={columns}
        data={data}
        style={{ height: "575px" }}
        showPagination={false}
        resizable={false}
        defaultPageSize={data.length}
      />
    </div>
  );
}

export default Table;
