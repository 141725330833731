import getImageElementFromUrl from "../../../helpers/getImageElementFromUrl";

export default async function renderImage({
  context,
  x,
  y,
  width,
  height,
  opacity,
  imageElement,
  imageUrl,
  addImageElementToObj,
  id,
}) {
  context.globalAlpha = opacity / 100;

  const isImageObj = imageElement && imageElement instanceof Image;
  if (isImageObj) {
    context.drawImage(imageElement, x, y, width, height);
  } else if (imageUrl) {
    const img = await getImageElementFromUrl(imageUrl);
    id && addImageElementToObj(id, img);
    context.drawImage(img, x, y, width, height);
  }

  context.globalAlpha = 1;
}
