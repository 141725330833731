import React, { Component } from "react";
import ReactTable from "react-table";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import moment from "moment";

import PageTitle from "../../../components/UI/PageTitle/PageTitle";
import BillingsMobileTable from "../../../components/Payments/BillingsMobileTable";
import PleaseWaitModal from "../modals/PleaseWaitModal";
import BillingEmptyState from "../../../components/Payments/BillingEmptyState/BillingEmptyState";

import "./Payment.sass";
import {
  createCreditCard,
  getAllPayments,
  getCreditCard,
  updateCreditCard,
  payNow,
  getPdf,
} from "../../../store/actions/paymentsActions";
import connect from "react-redux/es/connect/connect";
import PayNow from "../../../components/Payments/PayNow/PayNow";
import AdyenDropin from "../../../components/AdyenDropin/AdyenDropin";

const currencySymbols = ["$", "A$", "₴", " €"];

const MySwal = withReactContent(Swal);

class Payment extends Component {
  state = {
    isCardValid: false,
    cardData: null,
    storeCardPaymentMethod: false,
  };

  showModal = () => {
    MySwal.fire({
      html: <PleaseWaitModal message="Your tickets are uploading" />,
      customClass: "please-wait-modal",
    });
    const {
      auth: {
        user: { token },
      },
      payNow,
    } = this.props;
    payNow(token).then(({ isSuccess, errMessage }) => {
      if (isSuccess) {
        MySwal.close();
      } else if (!errMessage.includes("401")) {
        Swal({
          title: "Error!",
          text: errMessage,
          type: "error",
          timer: 3000,
        });
      }
    });
  };

  submitClick = (props) => {
    const { getPdf, auth } = this.props;
    const billingId = props.original ? props.original.id : props.id;
    getPdf(auth.user.token, billingId, moment().format()).then((result) => {
      const win = window.open(result, "_blank");
      win.focus();
    });
  };

  componentDidMount() {
    const {
      auth: {
        user: { token },
      },
      getCreditCard,
      getAllPayments,
    } = this.props;
    getCreditCard(token);
    getAllPayments(token);
  }

  handleOnChange = (state) => {
    this.setState({
      isCardValid: state.isValid,
      cardData: state.data.paymentMethod,
      storeCardPaymentMethod: state.data.storePaymentMethod,
    });
  };

  render() {
    const columns = [
      {
        Header: "Item",
        accessor: "scanbitsAmount",
      },
      {
        Header: "Price",
        accessor: "moneyAmount",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "",
        id: "download",
        Cell: (props) => (
          <button
            className="btn-default"
            onClick={() => {
              this.submitClick(props);
            }}
          >
            Download PDF
          </button>
        ),
      },
    ];

    const { payments } = this.props;

    let records = [];

    if (payments.records && payments.records.length > 0) {
      records = payments.records.map((value) => {
        const item = { ...value };
        item.date = moment.utc(item.date).local().format("DD MMMM YYYY HH:mm");
        item.moneyAmount = `${item.moneyAmount} ${
          currencySymbols[item.currency - 1]
        }`;
        return item;
      });
    }

    return (
      <div className="payment">
        {payments.scanbitsCount < 0 ? (
          <PayNow showModal={this.showModal} />
        ) : (
          ""
        )}

        <div className="billing">
          {payments.paymentMethodsResponse !== null ? (
            <AdyenDropin
              onChange={(state) => this.handleOnChange(state)}
              paymentMethodsResponse={payments.paymentMethodsResponse}
              token={this.props.auth.user.token}
              history={this.props.history}
              handleDisabledButton={this.props.handleDisabledButton}
            />
          ) : (
            "loading..."
          )}
          <PageTitle>Billing</PageTitle>
          {payments.records && payments.records.length ? (
            <ReactTable
              data={records}
              columns={columns}
              showPagination={false}
              minRows={0}
              sortable={false}
            />
          ) : (
            <BillingEmptyState />
          )}
          {payments.records ? (
            <BillingsMobileTable
              submitClick={this.submitClick}
              billings={records}
            />
          ) : (
            <BillingEmptyState />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, payments }) => ({ auth, payments });

const mapDispatchToProps = (dispatch) => ({
  getCreditCard: (token) => {
    dispatch(getCreditCard(token));
  },
  updateCreditCard: (creditCardToken, dropin, token) => {
    dispatch(updateCreditCard(creditCardToken, dropin, token));
  },
  getAllPayments: (creditCardToken, token) => {
    dispatch(getAllPayments(creditCardToken, token));
  },
  payNow: (token) => dispatch(payNow(token)),
  getPdf: (token, id, currentTime) => dispatch(getPdf(token, id, currentTime)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
