import React from "react";
import { showErrorModal, showInfoModal } from "../../../modals";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import ThanksForYourPurchase from "../../../images/thanks-for-your-purchase.svg";
import moment from "moment";

const MySwal = withReactContent(swal);

export const showSuccessPaymentModal = (
    {
        buyerEmail,
        additionalPaymentStatus,
        paymentDate,
        transactionId,
        authCode
    }) => {

      MySwal.fire({
        html: (
          <div className="season-pass-info-modal">
            <div className="season-pass-info-modal__body">
              <h3>Thanks for Your Purchase!</h3>
              <img src={ThanksForYourPurchase} alt="Thanks For Your Purchase" />
              <p>We will send all the details to your inbox <b>{buyerEmail}</b> and look forward to meeting you</p>
              <p className="season-pass-info-modal__additionalText">
                (Payment Status: {additionalPaymentStatus || 'null'},
                Payment Date: {moment(paymentDate).format("DD MMMM YYYY HH:mm")},
                Transaction Id: {transactionId  || 'null'}, Auth Code: {authCode  || 'null'})
              </p>
            </div>
          </div>
        ),
        width: "900px",
        allowEscapeKey: false,
        padding: "0px 0px 48px 0px",
        customClass: "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        allowOutsideClick: false,
        allowEnterKey: false,
      })

    /* showInfoModal({
        image: ThanksForYourPurchase,
        title: "Thanks for Your Purchase!",
        text: `We will send all the details to your inbox (${buyerEmail}) and look forward to meeting you`,
        additionalText: `(Payment Status: ${additionalPaymentStatus
            }, Payment Date: ${moment(paymentDate).format(
                "DD MMMM YYYY HH:mm"
            )}, Transaction Id: ${transactionId}, Auth Code: ${authCode
            })`,
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
    }); */
}

export const showErrorPaymentModal = ({
    additionalPaymentStatus,
    paymentDate,
    transactionId,
    authCode
}) => {

    showErrorModal({
        text: `It seems to have failed to complete the payment, please try again`,
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        additionalText: `(Payment Status: ${additionalPaymentStatus
            }, Payment Date: ${moment(paymentDate).format(
                "DD MMMM YYYY HH:mm"
            )}, Transaction Id: ${transactionId}, Auth Code: ${authCode
            })`,
    });
}

export const showProcessingPaymentModal = ({
    transactionId,
}) => {
    showInfoModal({
        image: ThanksForYourPurchase,
        text: `Your transaction is is processing`,
        customClass:
            "seasonpass__modal_info seasonpass__modal partizan-info-modal",
        additionalText: `(Transaction Id: ${transactionId})`,
    });
}