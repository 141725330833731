import React from 'react';

import './PromotersEmptyState.sass';
import EmptyStateImage from '../../../images/promoters_empty_screen.png';

const PromotersEmptyState = () =>
    <div className="promoters-empty-state">
        <h2 className="promoters-empty-state__title">There are no promoters</h2>
        <img src={EmptyStateImage} alt="No invoices"/>
    </div>;

export default PromotersEmptyState;
