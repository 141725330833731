import axios from "axios";

export const getTokenFormPaymentApi = async ({
  username = "anonymous",
  password = "anonymous",
  merchantId = "",
  socialMedia = null,
}) => {
  return await axios.post(
    `${process.env.REACT_APP_PAYMENT_API_LINK}/api/auth/login`,
    { username, password, merchantId, socialMedia }
  );
};
