import React, {Component} from 'react';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import TabTicket from './CustomTooltipTabs/TabTicket';
import TabTeam from './CustomTooltipTabs/TabTeam';
import TabDevice from './CustomTooltipTabs/TabDevice';
import TabTicketInitial from './CustomTooltipTabs/TabTicketInitial';

import './CustomTooltip.sass'
import TabTeamInitial from './CustomTooltipTabs/TabTeamInitial';
import TabDeviceInitial from './CustomTooltipTabs/TabDeviceInitial';

class CustomTooltip extends Component {

    handleChangeTab = tabType => this.props.handleChangeTab(tabType);

    render() {
        const {
            active,
            payload,
            tooltipData,
            getTicketName,
            getTeamName,
            deviceName,
            isActiveByClick,
            selectedTooltipData,
            selectedPayload,
            initialPayloadTicket,
            initialPayloadTeam,
            initialPayloadDevice
        } = this.props;

        let finalTooltipData = tooltipData;
        if (tooltipData && Object.keys(tooltipData).length === 0 && selectedTooltipData) {
            finalTooltipData = selectedTooltipData;
        }

        let finalPayload = payload;
        if (payload && payload.length === 0) {
            finalPayload = selectedPayload;
        }

        return (
            <div>
                <Tabs className="stats__chart-tabs tooltip">
                    <TabList>
                        <Tab onClick={() => this.handleChangeTab("ticket")}>Ticket</Tab>
                        <Tab onClick={() => this.handleChangeTab("team")}>Team</Tab>
                        <Tab onClick={() => this.handleChangeTab("device")}>Device</Tab>
                    </TabList>
                    <TabPanel>
                        {active || isActiveByClick ?
                    <TabTicket
                        payload={finalPayload}
                        getName={getTicketName}
                        getTeamName={getTeamName}
                        data={finalTooltipData["ticket"]}
                    />
                    :
                    <TabTicketInitial
                        payload={initialPayloadTicket}
                        getName={getTicketName}
                        getTeamName={getTeamName}
                        data={finalTooltipData["ticket"]}
                    />}
                    </TabPanel>
                    <TabPanel>
                        {active || isActiveByClick ?
                    <TabTeam
                        payload={finalPayload}
                        getName={getTeamName}
                        getTicketName={getTicketName}
                        data={finalTooltipData["team"]}
                    />
                    :
                    <TabTeamInitial
                        payload={initialPayloadTeam}
                        getName={getTeamName}
                        getTicketName={getTicketName}
                        data={finalTooltipData["team"]}
                    />}
                    </TabPanel>
                    <TabPanel>
                        {active || isActiveByClick ?
                    <TabDevice
                        payload={finalPayload}
                        getDeviceName={deviceName}
                        getTicketName={getTicketName}
                        data={finalTooltipData["device"]}
                    />
                    :
                    <TabDeviceInitial
                        payload={initialPayloadDevice}
                        getDeviceName={deviceName}
                        getTicketName={getTicketName}
                        data={finalTooltipData["device"]}
                    />}
                    </TabPanel>
                </Tabs>
        </div>)
    }
}

export default CustomTooltip;
