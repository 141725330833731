import React, { createContext, useState } from "react";

import { ActionsMenu } from "../ActionsMenu/ActionsMenu";

export const ActionsMenuContext = createContext({
  showMenu: (options) => {},
  hideMenu: () => {},
});

const initialOptions = {
  isShownMenu: false,
  items: [],
  position: {},
};

const ActionsMenuProvider = ({ children }) => {
  const [currentOptions, setCurrentOptions] = useState(initialOptions);

  const showMenu = (options) =>
    setCurrentOptions({ isShownMenu: true, ...options });

  const hideMenu = () => setCurrentOptions(initialOptions);

  const value = {
    showMenu,
    hideMenu,
  };

  return (
    <ActionsMenuContext.Provider value={value}>
      {children}
      <ActionsMenu hideMenu={hideMenu} options={currentOptions} />
    </ActionsMenuContext.Provider>
  );
};

export default ActionsMenuProvider;
