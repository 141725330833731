import React, {Fragment} from 'react';
import {Route, Redirect} from 'react-router-dom';
import ROUTES from '../../constants/routes';
import Registration from '../../containers/Auth/Registration/Registration';
import RegistrationSuccess from '../../containers/Auth/Registration/RegistrationSuccess';
import Login from '../../containers/Auth/Login/Login';
import RecoverPassword from '../../containers/Auth/RecoverPassword/RecoverPassword';
import NewPassword from '../../containers/Auth/RecoverPassword/NewPassword';

const NotAuthorizedLayout = () => {
    return localStorage.getItem('user')
        ? <Redirect to="/events" />
        : <Fragment>
            <Route path={ROUTES.REGISTRATION} component={Registration}/>
            <Route path={ROUTES.REGISTRATION_SUCCESS} component={RegistrationSuccess}/>
            <Route path={ROUTES.LOGIN} component={Login}/>
            <Route path={ROUTES.RECOVER_PASSWORD} component={RecoverPassword}/>
            <Route path={ROUTES.NEW_PASSWORD} component={NewPassword}/>
        </Fragment>
};

export default NotAuthorizedLayout;
