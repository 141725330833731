import React, { Component } from "react";
import { connect } from "react-redux";
import {
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_QRCODE_OBJECT,
  DEFAULT_CANVAS_WORKING_SIZE,
  topItemsValues,
} from "../../../constants/canvas";

import {
  appendEllipseObject,
  appendQRCodeObject,
  appendRectangleObject,
  appendTextObject,
  setActionMode,
  setActiveObjectId,
  setCanvasObjectLayerIndex,
  setCanvasObjectLayerToBottm,
  setCanvasObjectLayerToTop,
  setUserMode,
} from "../../../store/actions/canvasActions";
import { generateUniqueId } from "../../Canvas/helpers";
import { useShowActionsMenu } from "../../UI/ActionsMenu/hooks/useShowActionsMenu";
import { IconButton } from "../../UI/IconButton/IconButton";

const LayerControl = ({
  activeObject,
  canvasObjects,
  setCanvasObjectLayerIndex,
  appendRectangleObject,
  defaultParams,
  setActiveObjectId,
  setActionMode,
  appendQRCodeObject,
  setUserMode,
  appendEllipseObject,
  appendTextObject,
  setCanvasObjectLayerToTop,
  setCanvasObjectLayerToBottm,
  activeObjectId,
  currentTemplate,
  scrollPosition,
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const handleMoveBackward = () => {
    const activeObjectLayerIndex = canvasObjects.findIndex(
      (object) => object.id === activeObjectId
    );

    if (activeObjectLayerIndex > 0) {
      setCanvasObjectLayerIndex(activeObjectId, activeObjectLayerIndex - 1);
    }
  };

  const handleMoveForward = () => {
    const activeObjectLayerIndex = canvasObjects.findIndex(
      (object) => object.id === activeObjectId
    );
    if (activeObjectLayerIndex < canvasObjects.length - 1) {
      setCanvasObjectLayerIndex(activeObjectId, activeObjectLayerIndex + 1);
    }
  };

  const handleMoveBotttom = () => {
    setCanvasObjectLayerToBottm(activeObjectId);
  };

  const handleMoveTop = () => {
    setCanvasObjectLayerToTop(activeObjectId);
  };

  const menuItems = [
    {
      text: "Rectangle",
      onClick: () => {
        const createdObjectId = generateUniqueId();
        appendRectangleObject({
          id: createdObjectId,
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          backgroundColorHex: defaultParams.backgroundColorHex,
          strokeColorHex: defaultParams.strokeColorHex,
          strokeWidth: 0,
          opacity: 100,
          borderRadius: 0,
        });
        setUserMode("select");
        setActiveObjectId(createdObjectId);
      },
    },
    {
      text: "Ellipse",
      onClick: () => {
        const createdObjectId = generateUniqueId();
        appendEllipseObject({
          id: createdObjectId,
          x: 0,
          y: 0,
          width: 200,
          height: 200,
          backgroundColorHex: defaultParams.backgroundColorHex,
          strokeColorHex: defaultParams.strokeColorHex,
          strokeWidth: 0,
          opacity: 100,
          borderRadius: 0,
        });
        setUserMode("select");
        setActiveObjectId(createdObjectId);
      },
    },
    {
      text: "Text",
      onClick: () => {
        const createdObjectId = generateUniqueId();
        appendTextObject({
          id: createdObjectId,
          x: 0,
          y: 0,
          width: 200,
          height: 150,
          text: "Add text",
          textAlignHorizontal: "center",
          textAlignVertical: "middle",
          textJustify: false,
          fontColorHex: defaultParams.fontColorHex,
          fontSize: 36,
          fontStyle: "normal",
          fontWeight: "normal",
          fontVariant: "normal",
          fontLineHeightRatio: 1,
          opacity: 100,
        });
        setUserMode("select");
        setActiveObjectId(createdObjectId);
      },
    },
    {
      text: "Image",
      onClick: () => {
        setUserMode("image");
        setActiveObjectId(null);
      },
    },
    {
      text: "QR Code",
      onClick: () => {
        appendQRCodeObject({
          id: generateUniqueId(),
        });
      },
    },
  ];

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  const isQRCode =
    !!activeObject && activeObject.type === DEFAULT_CANVAS_QRCODE_OBJECT.type;

  const isFooterImage =
    !!activeObject &&
    activeObject.type === DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type;

  const isDisable = !activeObject || isQRCode || isFooterImage;

  const filteredCanvasObjects = canvasObjects.filter(
    (o) => !topItemsValues.some(({ type }) => type === o.type)
  );

  const objIndex = filteredCanvasObjects.findIndex(
    (object) => object.id === activeObjectId
  );

  const isElementOnTop = objIndex === filteredCanvasObjects.length - 1;

  const isElementOnBot = objIndex === 0;

  return (
    <div className="layer-control">
      <div className="layer-control__header">
        <h5 className="control-title">Layers</h5>
        <IconButton
          textRight
          onClick={showMenuActions(menuOptions)}
          className="layer-control__add-obj-btn IconButton"
          text="Add object"
          iconName={"plus"}
        />
      </div>
      <div className="layer-control__buttons">
        <IconButton
          iconName="layer-up"
          onClick={handleMoveForward}
          className="btn-primary"
          disabled={isDisable || isElementOnTop}
        />
        <IconButton
          iconName="layer-up-to-top"
          className="btn-primary"
          onClick={handleMoveTop}
          disabled={isDisable || isElementOnTop}
        />
        <IconButton
          onClick={handleMoveBackward}
          className="btn-primary"
          disabled={isDisable || isElementOnBot}
          iconName="layer-down"
        />
        <IconButton
          className="btn-primary"
          onClick={handleMoveBotttom}
          disabled={isDisable || isElementOnBot}
          iconName="layer-down-to-bottom"
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { canvas } = state;
  const activeObject = canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  );
  return {
    canvasObjects: canvas.canvasObjects,
    activeObject,
    activeObjectId: activeObject && activeObject.id,
    defaultParams: canvas.defaultParams,
    currentTemplate: canvas.currentTemplate,
    scrollPosition: canvas.scrollPosition,
  };
};

const mapDispatchToProps = (dispatch) => ({
  appendQRCodeObject: (obj) => dispatch(appendQRCodeObject(obj)),
  setActionMode: (params) => dispatch(setActionMode(params)),
  setUserMode: (mode) => dispatch(setUserMode(mode)),
  appendRectangleObject: (obj) => dispatch(appendRectangleObject(obj)),
  appendEllipseObject: (obj) => dispatch(appendEllipseObject(obj)),
  appendTextObject: (obj) => dispatch(appendTextObject(obj)),
  setActiveObjectId: (id) => dispatch(setActiveObjectId(id)),
  setCanvasObjectLayerIndex: (id, layerIndex) =>
    dispatch(setCanvasObjectLayerIndex(id, layerIndex)),
  setCanvasObjectLayerToTop: (id) => dispatch(setCanvasObjectLayerToTop(id)),
  setCanvasObjectLayerToBottm: (id) =>
    dispatch(setCanvasObjectLayerToBottm(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerControl);
