import React from "react";

import Icon from "../../UI/Icon/Icon";
import { IconButton } from "../../UI/IconButton/IconButton";
import { useShowActionsMenu } from "../../UI/ActionsMenu/hooks/useShowActionsMenu";

export const MembershipManagmentAction = ({
  data,
  showApproveModal,
  copyMemberPageLink,
  editMemberData,
  manageMemberSP,
  showRejectModal
}) => {
  const [showMenuActions] = useShowActionsMenu();

  const menuItems = [];

  if (data.status !== 3) {
    menuItems.push({
      text: "Aprrove",
      onClick: showApproveModal,
      data: data,
    });
  }

  menuItems.push({
    text: "Copy member link",
    onClick: copyMemberPageLink,
    data,
  });
  menuItems.push({
    text: "Edit",
    onClick: editMemberData,
    data,
  });
  menuItems.push({
    text: "Manage SP",
    onClick: manageMemberSP,
    data,
  });
  menuItems.push({
    text: "Reject",
    onClick: showRejectModal,
    data,
  });

  const optionsClassName =
    menuItems.length > 0
      ? "btn-primary IconButton primary trigger-actions-menu"
      : "IconButton gray trigger-actions-menu";

  const optionsIconName = menuItems.length > 0 ? "options" : "options-gray";

  const menuOptions = {
    items: menuItems,
    minMenuHeight: 35 * menuItems.length,
    offsetY: 7,
  };

  return (
    <div className="buttons-block">
      <IconButton
        iconName={optionsIconName}
        className={optionsClassName}
        onClick={showMenuActions(menuOptions)}
      />

      {/* {maximizingOptions && (
        <button
          className={accordionClassName}
          onClick={(e) => {
            handleAccordionClick(e, data);
          }}
        >
          <Icon name={"chevron"} />
        </button>
      )} */}
    </div>
  );
};
