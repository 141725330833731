import React from 'react';

import Icon from '../UI/Icon/Icon';

import '../../containers/Payments/Payment/Payment.sass';

const billingsMobileTable = ({billings, submitClick}) =>
    <div className="billings-mobile-table">
        {
            billings.map(bill => (
                <div className="billings-mobile-table__row"
                     key={bill.id}
                >
                    <div className="billings-mobile-table__left-col">
                        <div className="bill-item">{bill.item}</div>
                        <div className="bill-price">{bill.price}</div>
                        <div className="bill-date">{bill.date}</div>
                    </div>
                    <div className="billings-mobile-table__right-col">
                        <div id={bill.id} className="btn-download" onClick={(evt) => submitClick(evt.currentTarget)}>
                            <Icon name="download"/>
                        </div>
                    </div>
                </div>
            ))
        }
    </div>;

export default billingsMobileTable;
