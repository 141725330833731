import React, { useEffect } from "react";
import Icon from "../../Icon/Icon";

import "./ActionsMenu.sass";

export const ActionsMenu = ({ hideMenu, options }) => {
  const menuClassName = "actions-menu" + (options.isShownMenu ? "" : " hide");

  const hideEventTypes = [
    "scroll",
    "wheel",
    "auxclick",
    "contextmenu",
    "touchStart",
    "dragstart",
  ];

  const handleItemClick = (item) => {
    return (e) => {
      item.onClick(item.data);
      hideMenu();
    };
  };

  const handleClick = (event) => {
    const element = event.target;
    const parent = element.parentElement;

    let className = element.className;

    if (parent) {
      className += parent.className;
    }

    if (className) {
      if (className.indexOf("trigger-actions-menu") === -1) {
        hideMenu();
      }
    } else {
      hideMenu();
    }
  };

  const handleDefaultEvent = (event) => {
    hideMenu();
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      hideMenu();
    }
  };

  useEffect(() => {
    if (options.isShownMenu) {
      hideEventTypes.forEach((eventType) => {
        document.addEventListener(eventType, handleDefaultEvent);
      });

      document.addEventListener("click", handleClick);
      document.addEventListener("keydown", handleKeyDown);
      window.addEventListener("resize", handleDefaultEvent);
      window.addEventListener("popstate", handleDefaultEvent);
    }

    return () => {
      if (options.isShownMenu) {
        hideEventTypes.forEach((eventType) => {
          document.removeEventListener(eventType, handleDefaultEvent);
        });

        document.removeEventListener("click", handleClick);
        document.removeEventListener("keydown", handleKeyDown);
        window.removeEventListener("resize", handleDefaultEvent);
        window.removeEventListener("popstate", handleDefaultEvent);
      }
    };
  }, [options.isShownMenu]);

  return (
    <nav className={menuClassName} style={options.position}>
      {options.items.map((item, index) => {
        const className = "actions-menu-item " + item.className;

        return (
          <div
            key={index}
            className={className}
            onClick={handleItemClick(item)}
          >
            {!!item.iconLeft && !!item.iconName && (
              <Icon name={item.iconName} />
            )}
            {item.text}
            {!!item.iconRight && !!item.iconName && (
              <Icon name={item.iconName} />
            )}
          </div>
        );
      })}
    </nav>
  );
};
