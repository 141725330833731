export const addHeadTags = async ({ url, folder, title }) => {
  const head = document.getElementsByTagName("head")[0];
  const meta = head.getElementsByTagName("meta")[0];
  const html = `
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_16x16.png"
      sizes="16x16"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_32x32.png"
      sizes="32x32"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_60x60.png"
      sizes="60x60"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_76x76.png"
      sizes="76x76"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_96x96.png"
      sizes="96x96"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_128x128.png"
      sizes="128x128"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_144x144.png"
      sizes="144x144"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_180x180.png"
      sizes="180x180"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_196x196.png"
      sizes="196x196"
    />
    <link
      rel="icon"
      type="image/png"
      href="${url}/${folder}/favicon_512x512.png"
      sizes="512x512"
    />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />
    <link rel="manifest" href="${url}/${folder}/manifest.json" />
    <title>${title}</title>
  `
  
  meta.insertAdjacentHTML("afterend", html)
}
