import getPositionFromDrawingPoints from "../../../helpers/getPositionFromDrawingPoints";
import hexToRgba from "../../../helpers/hexToRgba";

export default function renderFreeDrawing({
  context,
  x,
  y,
  strokeColorHex,
  strokeWidth,
  opacity,
  freeDrawPoints,
}) {
  context.strokeStyle = hexToRgba({ hex: strokeColorHex, opacity });

  context.lineCap = "round";
  context.lineWidth = strokeWidth || 1;
  context.beginPath();

  const positionFromDrawingPoints = getPositionFromDrawingPoints({
    freeDrawPoints,
  });

  freeDrawPoints.forEach((point, index) => {
    const realX = x - positionFromDrawingPoints.x + point.x;
    const realY = y - positionFromDrawingPoints.y + point.y;
    if (index === 0) {
      context.moveTo(realX, realY);
    } else {
      context.lineTo(realX, realY);
    }
  });
  context.stroke();
  context.closePath();
}
