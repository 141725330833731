import React, { Component } from "react";
import { connect } from "react-redux";
import { isHexColor } from "../../../helpers/isHexColor";

import {
  setDefaultParams,
  updateCanvasObject,
} from "../../../store/actions/canvasActions";
import FieldLabel from "../../FieldLabel/FieldLabel";
import { DEFAULT_CANVAS_PARAMS } from "../../../constants/canvas";
import isEqual from "lodash.isequal";
import ColorPicker from "../../ColorPicker/ColorPicker";

class BackgroundControl extends Component {
  state = {
    color: "",
  };

  componentDidMount() {
    const { activeObject } = this.props;

    this.setState({ color: activeObject.backgroundColorHex });
  }

  componentDidUpdate(prevProps) {
    const { activeObject: prevActiveObj } = prevProps;
    const { activeObject } = this.props;
    const isActiveObjectEqual = isEqual(activeObject, prevActiveObj);

    if (!isActiveObjectEqual) {
      this.setState({ color: activeObject.backgroundColorHex });
    }
  }

  handleColorChange = (color) => {
    const { activeObject, setDefaultParams, updateCanvasObject } = this.props;

    this.setState({ color });

    if (isHexColor(color)) {
      updateCanvasObject(activeObject.id, {
        backgroundColorHex: color,
      });
      setDefaultParams({
        backgroundColorHex: color,
      });
    }
  };

  render() {
    const { color } = this.state;

    return (
      <FieldLabel
        className="background-control control-field"
        label="Background"
      >
        <ColorPicker
          colorShowType={"circle"}
          className="background-control__color-picker"
          value={color}
          inputType="input"
          onChange={this.handleColorChange}
        />
      </FieldLabel>
    );
  }
}

const mapStateToProps = (state) => {
  const { canvas } = state;
  const activeObject = canvas.canvasObjects.find(
    (object) => object.id === canvas.activeObjectId
  );
  return {
    activeObject,
    defaultParams: canvas.defaultParams,
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCanvasObject: (id, object) => dispatch(updateCanvasObject(id, object)),
  setDefaultParams: (params) => dispatch(setDefaultParams(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackgroundControl);
