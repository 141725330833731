import React from 'react';
import { createPortal } from 'react-dom';
import './Modal.sass';

const modalRoot = document.getElementById( 'modal' );
class Modal extends React.Component {
  constructor( props ) {
    super( props );

    this.element = document.createElement( 'div' );
    this.element.className = 'modal';
  }

  componentDidMount() {
    let body = document.body
    body.classList.add('modal-opened')
    modalRoot.appendChild( this.element );
  }

  componentWillUnmount() {
    let body = document.body
      body.classList.remove('modal-opened')
    modalRoot.removeChild( this.element );
  }

  render() {
    return createPortal( this.props.children, this.element );
  }
}
export default Modal;
