export const TICKETS_IMPORT = {
    NEXT_STEP: 'NEXT_STEP',
    PREV_STEP: 'PREV_STEP',

    GET_PROVIDERS_START:'GET_PROVIDERS_START',
    GET_PROVIDERS_SUCCESS:'GET_PROVIDERS_SUCCESS',
    GET_PROVIDERS_FAIL:'GET_PROVIDERS_FAIL',

    GET_PROVIDERS_EVENTS_START: 'GET_PROVIDERS_EVENTS_START',
    GET_PROVIDERS_EVENTS_SUCCESS: 'GET_PROVIDERS_EVENTS_SUCCESS',
    GET_PROVIDERS_EVENTS_FAIL: 'GET_PROVIDERS_EVENTS_FAIL',

    SELECT_PROVIDERS_EVENT: 'SELECT_PROVIDERS_EVENT',
    SELECT_PROVIDERS_TICKET_TEMPLATE: 'SELECT_PROVIDERS_TICKET_TEMPLATE',

    IMPORT_TICKETS_START: 'IMPORT_TICKETS_START',
    IMPORT_TICKETS_SUCCESS: 'IMPORT_TICKETS_SUCCESS',
    IMPORT_TICKETS_FAIL: 'IMPORT_TICKETS_FAIL',

    TICKETS_IMPORT_CLEAR_STORE: 'TICKETS_IMPORT_CLEAR_STORE',
};
