import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";

export const savePdfTemplateImage = (file) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const body = new FormData();
  body.append("image", file);

  return axios
    .post("/PdfTemplate/Image", body, {
      headers: { ...getHeaders(user.token) },
    })
    .then(({ data }) => {
      if (data.isSuccess) return data.result;
    })
    .catch((e) => {
      console.log(e);
    });
};
