import React, { Component } from "react";
import { connect } from "react-redux";

import Dropzone from "../../../containers/Upload/Dropzone/Dropzone";

import getDimensionsFromImage from "../../../helpers/getDimensionsFromImage";
import getImageElementFromUrl from "../../../helpers/getImageElementFromUrl";
import {
  appendImageObject,
  setActiveObjectId,
  setUserMode,
} from "../../../store/actions/canvasActions";
import { generateUniqueId } from "../../Canvas/helpers";
import fileToBase64 from "../../../helpers/fileToBase64";
import { savePdfTemplateImage } from "../../../helpers/savePdfTemplateImage";

class ImageControl extends Component {
  state = {
    isLoading: false,
  };

  commonPushImageObject = async (url) => {
    const imageElement = await getImageElementFromUrl(url);
    const dimensions = await getDimensionsFromImage({
      context: this.props.contextRef.current,
      imageObject: { x: 0, y: 0, imageElement },
    });
    const createdObjectId = generateUniqueId();
    this.props.appendImageObject({
      id: createdObjectId,
      x: 0,
      y: 0,
      width: dimensions.width,
      height: dimensions.height,
      opacity: 100,
      imageUrl: url,
      imageElement,
    });
    this.props.setActiveObjectId(createdObjectId);
    this.props.setUserMode("select");
  };

  handleDrop = async (files) => {
    this.setState({ isLoading: true });
    try {
      const imageUrl = await savePdfTemplateImage(files[0]);
      if (imageUrl) {
        this.commonPushImageObject(imageUrl);
      }
    } catch (error) {
      console.error(error);
    }
    this.setState({ isLoading: false });
  };

  render() {
    return (
      <div className="image-control">
        <Dropzone
          accept="image/*"
          onFilesAdded={this.handleDrop}
          onButtonEnable={() => {}}
          unsupportedFileFormatHandle={() => {}}
          onlyOneFileErrorHandle={() => {}}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  appendImageObject: (id, object) => dispatch(appendImageObject(id, object)),
  setActiveObjectId: (id) => dispatch(setActiveObjectId(id)),
  setUserMode: (mode) => dispatch(setUserMode(mode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImageControl);
