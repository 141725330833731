import React from 'react';

import './ScanbitsDetails.sass';

const ScanbitsDetails = ({
    showModal,
    value,
    onChange,
    onKeyPress,
    scanBitPrice,
    disabledButton,
}) => {
    return (
    <div className="scanbits-details">
        <p>The more you buy the more you safe!</p>
        <p>1 Scanbit = 1 scanned ticket</p>
        <input
            type="text"
            value={value}
            onChange={onChange}
            onKeyPress={onKeyPress}
        />
        <p>
            <strong>{value ? Number(value * scanBitPrice).toFixed(1) : 0} EUR</strong>
        </p>

        <button
            type="button"
            className="btn-primary"
            onClick={showModal}
            disabled={!parseInt(value) || disabledButton}
        >
            <span>BUY</span>
        </button>
    </div>
    );
}

export default ScanbitsDetails;
