import React, { Component } from 'react';

class BlockedUserModal extends Component {
    render() {
        return (
            <div>
                <img src="/images/img-lock.png" alt="Lock" />
                <p>
                    Please, contact us for more information:
                    {' '}
                    name_of_company@gmail.com
                </p>
            </div>
        );
    }
}

export default BlockedUserModal;
