export const getOptionsForAsyncSelect = (
  data = [],
  keys = { valueKey: "", labelKey: "", returnDataKey: "" }
) => {
  const { labelKey, valueKey, returnDataKey } = keys;
  switch (true) {
    case !!valueKey && !!labelKey:
      return data.map((item) => {
        const newItem = {
          value: item[valueKey],
          label: item[labelKey],
        };
        if (returnDataKey) {
          newItem[returnDataKey] = item;
        }
        return newItem;
      });
    case !!valueKey:
      return data.map((item) => {
        const newItem = {
          value: item[valueKey],
          label: item[valueKey],
        };
        if (returnDataKey) {
          newItem[returnDataKey] = item;
        }
        return newItem;
      });
    case !!labelKey:
      return data.map((item) => {
        const newItem = {
          value: item[labelKey],
          label: item[labelKey],
        };
        if (returnDataKey) {
          newItem[returnDataKey] = item;
        }
        return newItem;
      });
    default:
      return data.map((item) => ({ value: item, label: item }));
  }
};
