export const GET_CREDIT_CARD_START = 'GET_CREDIT_CARD_START';
export const GET_CREDIT_CARD_SUCCESS = 'GET_CREDIT_CARD_SUCCESS';
export const GET_CREDIT_CARD_FAIL = 'GET_CREDIT_CARD_FAIL';

export const CREATE_CREDIT_CARD_START = 'CREATE_CREDIT_CARD_START';
export const CREATE_CREDIT_CARD_SUCCESS = 'CREATE_CREDIT_CARD_SUCCESS';
export const CREATE_CREDIT_CARD_FAIL = 'CREATE_CREDIT_CARD_FAIL';

export const UPDATE_CREDIT_CARD_START = 'UPDATE_CREDIT_CARD_START';
export const UPDATE_CREDIT_CARD_SUCCESS = 'UPDATE_CREDIT_CARD_SUCCESS';
export const UPDATE_CREDIT_CARD_FAIL = 'UPDATE_CREDIT_CARD_FAIL';

export const GET_ALL_PAYMENTS_START = 'GET_ALL_PAYMENTS_START';
export const GET_ALL_PAYMENTS_SUCCESS = 'GET_ALL_PAYMENTS_SUCCESS';
export const GET_ALL_PAYMENTS_FAIL = 'GET_ALL_PAYMENTS_FAIL';

export const GET_SCANBITS_COUNT_START = 'GET_SCANBITS_COUNT_START';
export const GET_SCANBITS_COUNT_SUCCESS = 'GET_SCANBITS_COUNT_SUCCESS';
export const GET_SCANBITS_COUNT_FAIL= 'GET_SCANBITS_COUNT_FAIL';

export const PAY_NOW_START = 'PAY_NOW_START';
export const PAY_NOW_SUCCESS = 'PAY_NOW_SUCCESS';
export const PAY_NOW_FAIL = 'PAY_NOW_FAIL';

export const PAYMENT_START = 'PAYMENT_START';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_FAIL = 'PAYMENT_FAIL';

export const GET_PDF_START = 'GET_PDF_START';
export const GET_PDF_SUCCESS = 'GET_PDF_SUCCESS';
export const GET_PDF_FAIL = 'GET_PDF_FAIL';

export const GET_USER_PAYMENT_INFO_START = 'GET_USER_PAYMENT_INFO_START';
export const GET_USER_PAYMENT_INFO_SUCCESS = 'GET_USER_PAYMENT_INFO_SUCCESS';
export const GET_USER_PAYMENT_INFO_FAIL = 'GET_USER_PAYMENT_INFO_FAIL';
