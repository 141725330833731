import React from "react";
import CheckBox from "../UI/Checkbox/CheckBox";
import Icon from "../UI/Icon/Icon";

import "./ResellingRequestCard.sass";

const ResellingRequestCard = ({
  ticket,
  handleSelect,
  selected,
  disabled,
  resellingBlocked,
}) => {
  const {
    id,
    passType,
    resellStatus,
    area,
    row,
    seat,
    side,
    totalPointsForResale,
  } = ticket;

  const getStatus = () => {
    switch (resellStatus) {
      case 2:
        return (
          <span className="reselling-request-card__status reselling-request-card__status_sold">
            Sold
          </span>
        );
      case 1:
        if (resellingBlocked) {
          return (
            <span className="reselling-request-card__status reselling-request-card__status_not-sold">
              Not sold
            </span>
          );
        }
        return (
          <span className="reselling-request-card__status reselling-request-card__status_in-sale">
            In Sale
          </span>
        );
      default:
        return null;
    }
  };

  const showCheckbox = !resellingBlocked && !disabled;

  const checkboxWarpperStyles =
    resellingBlocked || disabled
      ? {
          justifyContent: "flex-end",
        }
      : {};

  return (
    <div className="reselling-request-card">
      <div className="reselling-request-card__header">
        <h6>{passType}</h6>
        {getStatus()}
      </div>
      <div className="reselling-request-card__hero">
        <div className="reselling-request-card__info">
          <div className="reselling-request-card__info-item">
            <h6>Area:</h6>
            <p>{area}</p>
          </div>
          <div className="reselling-request-card__info-item">
            <h6>Row:</h6>
            <p>{row}</p>
          </div>
          <div className="reselling-request-card__info-item">
            <h6>Seat:</h6>
            <p>{seat}</p>
          </div>
          <div className="reselling-request-card__info-item">
            <h6>Side:</h6>
            <p>{side}</p>
          </div>
        </div>
        <div
          className="reselling-request-card__checkbox-warpper"
          style={checkboxWarpperStyles}
        >
          {showCheckbox && (
            <CheckBox
              id={`reselling-request-card__checkbox-${id}`}
              name="reselling-request-card__checkbox"
              onChange={() => handleSelect(id)}
              checked={selected}
              isDisabled={disabled}
            >
              <span>Submit a ticket for sale</span>
            </CheckBox>
          )}
          <div className="reselling-request-card__coins">
            <span>Total:</span>
            <div className="reselling-request-card__coins-count">
              <Icon name="coins" />
              <span>{totalPointsForResale}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResellingRequestCard;
