import React, { useEffect, useRef, useState } from "react"
import axios from '../../../axios-instance';
import ls from 'localstorage-slim';
import styles from './SeasonPassPersonalInfo.module.sass';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { BuyTicketsOnFutureEventModal } from "./Modals/BuyTicketsOnFutureEvent";
import { getCurrentLanguageTexts } from "../../../constants/seasonPassPersonalInfoLangs";
import { toast } from "react-toastify";
import NewLoader from "../../../components/NewLoader/NewLoader";

const MySwal = withReactContent(Swal);

export function MemberShipTickets({ memberData, ...props }) {
    const [state, setState] = useState({
        events: [
            {
                tickets: [],
                eventData: {}
            },
        ],
        loading: true,
    });

    const txt = getCurrentLanguageTexts();

    useEffect(() => {
        const token = ls.get('member-access-token');

        if (token) {
            axios.get(`/SeasonPassMember/FutureEvents?accessToken=${token}`).then(r => {
                setState({
                    ...state,
                    events: r.data,
                    loading: false
                });
            });
        }
    }, []);

    const buyMoreHandler = (eventId, availiableSeatsCount) => {
        const event = state.events.find(e => e.eventData.eventId === eventId);

        if (event) {
            MySwal.fire({
                title: txt.memberEventsList["Buy tickets"],
                html: (
                    <BuyTicketsOnFutureEventModal
                        availablePoints={0}
                        event={event.eventData}
                        availiableSeatsCount={availiableSeatsCount}
                        memberData={memberData}
                    />
                ),
                showConfirmButton: false,
                customClass: "public-user-modal popup-mode",
            });
        }
    };

    const refuse = async (eventId, seasonPassId) => {
        try {
            const token = ls.get('member-access-token');

            const body = {
                eventId,
                customerToken: token,
                seasonPassIds: [seasonPassId],
            };

            setState(state => {

                const event = state.events.find(e => e.eventData.eventId === eventId);
                const ticket = event.tickets.find(t => t.seasonPassId === seasonPassId);

                ticket.isResaleRequestProcessing = true;

                return {
                    ...state,
                }
            });

            const { data } = await axios.post("/SeasonRefuse/refuse", body);

            toast.success(txt.memberEventsList.resaleBlock['Seat sent to resale']);

            setState(state => {

                const event = state.events.find(e => e.eventData.eventId === eventId);
                const ticket = event.tickets.find(t => t.seasonPassId === seasonPassId);

                ticket.resellStatus = 1
                ticket.isResaleRequestProcessing = false;

                return {
                    ...state,
                }
            });

        } catch (e) {
            toast.error(txt.memberEventsList.resaleBlock['Seat was not sent for resale']);
            setState(state => {

                const event = state.events.find(e => e.eventData.eventId === eventId);
                const ticket = event.tickets.find(t => t.seasonPassId === seasonPassId);

                ticket.isResaleRequestProcessing = false;

                return {
                    ...state,
                }
            });

        }
    };

    const handleRequest = async (eventId, seasonPassId) => {
        MySwal.fire({
            title: txt.memberEventsList.resaleApprovePopup.title,
            text: txt.memberEventsList.resaleApprovePopup.text,
            customClass: "public-user-modal",
            cancelButtonText: txt.generalTxt.cancel,
            confirmButtonText: txt.memberEventsList.resaleApprovePopup["Send to resale"],
            showConfirmButton: true,
            showCancelButton: true,
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                refuse(eventId, seasonPassId);
            }
        });
    };

    return <>
        <section className={styles['membership-tickets-container']}>
            {!state.loading &&
                <ol>
                    {state.events.map((e, i) =>
                        <li key={e.eventData.eventId}>
                            <FutureEventSection event={e}
                                hided={e.tickets.length > 2 && i > 0}
                                buyMoreHandler={buyMoreHandler}
                                sendToResale={handleRequest}
                                membershipsCount={memberData.membershipsCount} />
                        </li>
                    )}
                </ol>
            }

        </section >
    </>
}

function FutureEventSection({ event, buyMoreHandler, membershipsCount, sendToResale, hided = true, ...props }) {
    const seasonPasses = event.tickets.filter(t => t.type === 1);
    const dailyTickets = event.tickets.filter(t => t.type === 2);
    const cancelledTickets = event.tickets.filter(t => t.type === 3);

    const listRef = useRef(null);
    const [state, setState] = useState({
        hided: hided,
        ticketContainerHeight: '18rem',
        selectedSeasonPassId: '',
    });

    useEffect(() => {
        window.addEventListener('resize', () => {
            setState(state => {
                return {
                    ...state,
                    ticketContainerHeight: (state.hided) ? '18rem' : listRef.current.offsetHeight
                }
            });
        })
    }, []);

    useEffect(() => {
        if (listRef.current) {
            setState(state => {
                return {
                    ...state,
                    ticketContainerHeight: (state.hided) ? '18rem' : listRef.current.offsetHeight
                }
            });
        }
    }, [listRef]);

    const openCloseTicketList = () => {
        setState(state => {
            return {
                ...state,
                hided: !state.hided,
                ticketContainerHeight: (!state.hided) ? '18rem' : listRef.current.offsetHeight
            }
        });
    }

    const setHover = (seasonPassId) => {
        setState(state => {
            return {
                ...state,
                selectedSeasonPassId: seasonPassId,
            }
        });
    }

    const setUsual = (seasonPassId) => {
        setState(state => {
            return {
                ...state,
                selectedSeasonPassId: '',
            }
        });
    }

    const availiableSeatsCount = (membershipsCount * 2) - event.tickets.filter(t => t.type === 2).length;
    const txt = getCurrentLanguageTexts();

    return <article className={styles['event-section']}>

        <div className={`${styles['event-header']}`}
        >
            <h3>{event.eventData.eventName}</h3>
        </div>

        <div className={styles['ticket-list-container'] + ' ' + (state.hided ? styles['hided'] : '')}
            style={{ height: state.ticketContainerHeight }}>

            <h4>{txt.memberEventsList['Your tickets on this event']}</h4>

            <ul className={styles['ticket-list']}
                ref={listRef}>

                {seasonPasses.map(t => <li key={t.id}>
                    <FutureEventTicket ticket={t}
                        hover={t.seasonPassId === state.selectedSeasonPassId}
                        setHover={setHover}
                        setUsual={setUsual}
                        sendToResale={sendToResale} />
                </li>)}

                {dailyTickets.map(t => <li key={t.id}>
                    <FutureEventTicket ticket={t}
                        hover={t.seasonPassId === state.selectedSeasonPassId}
                        setHover={setHover}
                        setUsual={setUsual}
                        sendToResale={sendToResale} />
                </li>)}

                {cancelledTickets.map(t => <li key={t.id}>
                    <FutureEventTicket ticket={t}
                        hover={t.seasonPassId === state.selectedSeasonPassId}
                        setHover={setHover}
                        setUsual={setUsual}
                        sendToResale={sendToResale} />
                </li>)}

                {(availiableSeatsCount > 0) &&
                    <li>
                        <button className={styles['add-ticket-button']}
                            onClick={() => buyMoreHandler(event.eventData.eventId, availiableSeatsCount)}>{txt.memberEventsList['Buy more tickets']}</button>
                    </li>
                }

            </ul>

        </div>

        <button style={{ display: state.hided ? 'initial' : 'none' }} className={'btn-primary ' + styles['show-more-button']}
            onClick={openCloseTicketList}>{txt.memberEventsList['Show more']}</button>

    </article>
}

function FutureEventTicket(
    { ticket,
        sendToResale,
        hover = false,
        setHover,
        setUsual,
        ...props }) {

    const hasSeat = !!ticket.seat;
    const txt = getCurrentLanguageTexts();

    return <>

        <article className={styles['ticket']}>

            <div className={styles['ticket-and-price']}>

                <p className="partizan-open-selling__text-normal-small">{ticket.ticketName}</p>

                <div className={`StatusLabel IgnoreWidth ${ticket.type === 1 && 'MintDark'
                    || ticket.type === 2 && 'GoldDark'
                    || ticket.type === 3 && 'PurpleDark'}`}>

                    <p className="partizan-open-selling__text-normal-small">{ticket.type === 1 && txt.memberEventsList['Season Pass']
                        || ticket.type === 2 && txt.memberEventsList['Tickets']
                        || ticket.type === 3 && txt.memberEventsList['Cancelled']}</p>

                </div>

            </div>

            {ticket.customerName &&
                <p> {ticket.customerName}</p>
            }

            <div className={styles['footer']}>

                {hasSeat &&
                    <p className={styles['seat-label']}><strong>{ticket.area} {
                        ticket.side === 'L' && 'Left'
                        || ticket.side === 'R' && 'Right'
                        || ticket.side === 'M' && 'Center'
                        || ''
                    } {ticket.row} {ticket.seat}</strong></p>}

            </div>

            {(ticket.type === 1 && !!ticket.seasonPassId) &&
                <div className={`${styles['resale-block']} ${hover ? styles['hover'] : ''}`}>

                    <div className={`${styles['resale-label']} ${(ticket.resellStatus === 0 && ' '
                        || ticket.resellStatus === 1 && styles['selling']
                        || ticket.resellStatus === 2 && styles['sold']
                    )}`}
                        onClick={() => hover ? setUsual(ticket.seasonPassId) : setHover(ticket.seasonPassId)}>

                        <p>
                            {ticket.resellStatus === 0 && txt.memberEventsList.resaleBlock['Send to resale']
                                || ticket.resellStatus === 1 && txt.memberEventsList.resaleBlock['Selling']
                                || ticket.resellStatus === 2 && txt.memberEventsList.resaleBlock['Sold']
                            }
                        </p>

                    </div>

                    <span className={`partizan-open-selling__text-normal-small ${styles['resale-total-keeped']}`}>{txt.memberEventsList.resaleBlock['Total collected']}: {ticket.totalPointsForResale}</span>

                    {!(ticket.resellStatus !== 0 || !ticket.seasonPassId)
                        &&
                        <button className={`btn-primary-new ${styles['resale-button']}`}
                            onClick={() => sendToResale(ticket.eventId, ticket.seasonPassId)}>
                            {txt.memberEventsList.resaleBlock['Send to resale']}
                        </button>
                    }

                    {ticket.isResaleRequestProcessing
                        && <NewLoader />
                    }

                </div>
            }

        </article>
    </>
}