import React, {Fragment} from 'react';

import Icon from "../../Icon/Icon";

import '../Collapsible.sass';

const trigger = props => {
  return(
      <Fragment>
          <p>{props.title}</p>
          <span className="icon">
            <Icon name={'chevron'}/>
        </span>
      </Fragment>
  )
};

export default trigger;
