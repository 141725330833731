import React, { useEffect, useMemo, useState, useCallback } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import debounce from 'lodash/debounce';
import Select from "react-select";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactTable from "react-table";
import withFixedColumns from "react-table-hoc-fixed-columns";
import NewLoader from "../../components/NewLoader/NewLoader";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import Container from "../../components/UI/Container/Container";
import { getEmails,
  getEmail,
  resendEmail,
  getProblematicEmailsCount,
  updateProblematicEmail,
} from "../../store/actions/emailsActions";
import { EmailModal } from "./EmailModal/EmailModal";
import CheckBox from "../../components/UI/Checkbox/CheckBox";

import "../MembershipManagment/MembershipManagment.sass";
import "./Emails.sass";

const MySwal = withReactContent(swal);

export const periods = [
  { value: 0, label: "By last day" },
  { value: 1, label: "By last week" },
  { value: 2, label: "By last month" },
  { value: 3, label: "By last year" },
  { value: 4, label: "All" },
];

export const emailsStatuses = [
  { value: 1, label: "delivered" },
  { value: 2, label: "open" },
  { value: 3, label: "bounce" },
  { value: 4, label: "processed" },
  { value: 5, label: "dropped" },
  { value: 6, label: "deferred" },
  { value: 7, label: "spamreport" },
  { value: 8, label: "null" },
];

export const emailsTypes = [
  { value: 1, label: "Problematic" },
  { value: 2, label: "Solved" },
]

const Emails = ({
  getEmails,
  isEmailsLoading,
  getEmail,
  resendEmail,
  getProblematicEmailsCount,
  problematicEmailsCount,
  updateProblematicEmail,
}) => {
  const [data, setData] = useState([]);
  const [searchPeriod, setSearchPeriod] = useState(periods[0]);
  const [searchStatus, setSearchStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [requestedQuery, setRequestedQuery] = useState("");
  const [emailType, setEmailType] = useState(null);
  const [isShowProblematic, setShowProblematic] = useState(false);

  const [currentPageNumber, setCurrentPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [dataToDisplay, setDataToDisplay] = useState([]);

  const ReactTableFixedColumns = useMemo(
    () => withFixedColumns(ReactTable),
    []
  );

  const getData = async () => {
    const emails = await getEmails({
      period: searchPeriod ? searchPeriod.value : null,
      status: searchStatus ? searchStatus.label : null,
      search: requestedQuery,
      type: emailType ? emailType.value : 0,
    });

    setData(emails);
  };

  const resendEmailHandler = (id) => {
    resendEmail(id).then(() => {
      getData();
    });
  };

  const updateProblematicEmailHandler = (id, isSolved) => {
    updateProblematicEmail(id, isSolved).then(() => {
      getData();
    })
  }

  const showResendModal = (id) => {
    MySwal.fire({
      title: "Resend email",
      text: "Are you sure you want to resend email?",
      cancelButtonText: "Cancel",
      confirmButtonText: "Resend",
      allowEscapeKey: false,
      customClass: "swal2-modal",
      allowEnterKey: false,
      showConfirmButton: true,
      showCancelButton: true,
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        resendEmailHandler(id);
      }
    });
  };

  const showEmailModal = (emailId, errorMessage) => {
    MySwal.fire({
      html: (
        <div className="emails__modal-loader">
          <NewLoader />
        </div>
        ),
      width: "80vw",
      customClass: "seasonpass__modal swal2-modal",
      showConfirmButton: false,
    });

    getEmail(emailId).then((email) => {
      MySwal.fire({
        html: <EmailModal email={email} errorMessage={errorMessage} />,
        width: "80vw",
        customClass: "swal2-modal",
        showConfirmButton: true,
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: "Resend Email"
      }).then((result) => {
        if (result.value) {
          resendEmailHandler(emailId);
        }
      });
    });
  }

  const changeSearchStatusHandler = (item) => {
    setSearchStatus(item);
  };
  const changeSearchPeriodHandler = (item) => {
    setSearchPeriod(item);
  };

  const handleDebonceFn = (value) => {
    setRequestedQuery(value);
  };

  const debounceFn = useCallback(debounce(handleDebonceFn, 1500), []);

  const changeSearchInputHandler = (e) => {
    setSearchQuery(e.target.value);
    debounceFn(e.target.value);
  };

  const changeEmailTypeHandler = (item) => {
    setEmailType(item);
  };

  const changeShowProblematicHandler = () => {
    setShowProblematic(!isShowProblematic);
  }

  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: "46px",
      "min-height": "46px",
      cursor: "pointer",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#ABABAB",
    }),
  };

  const handleCurrentPageChanged = (value) => {
    setCurrentPageNumber(value);
  };

  const handlePageSizeChanged = (value) => {
    setPageSize(value);
  };

  const goToCurrentPage = () => {
    const start = currentPageNumber * pageSize;
    const end = (currentPageNumber + 1) * pageSize;

    setDataToDisplay(data.slice(start, end));
  };

  const refreshDataToDisplay = () => {
    if (currentPageNumber === 0) {
      goToCurrentPage();
    } else {
      setCurrentPageNumber(0);
    }
  };

  useEffect(() => {
    refreshDataToDisplay();
  }, [data, pageSize]);

  useEffect(() => {
    goToCurrentPage();
  }, [currentPageNumber]);

  useEffect(() => {
    getData();
  }, [requestedQuery, searchStatus, searchPeriod, emailType]);

  useEffect(() => {
    getProblematicEmailsCount();
  }, [data])

  let emptyStateText = "There are no data";

  const getLabelStyle = (state, rowInfo, column) => ({
    style: {
      marginTop: "6px",
    },
  });

  const getFlexStyle = () => ({
    style: {
      marginTop: "3px",
      padding: "0 5px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  });

  const getCheckboxStyle = () => ({
    style: {
      marginTop: "3px",
      padding: "0 5px",
      display: "flex",
      alignItems: "center",
    },
  });

  const columns = [
    {
      Header: "Email",
      accessor: "toAddress",
      resizable: false,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { toAddress }}}) => (
        <span title={toAddress}>{toAddress}</span>
      ),
    },
    {
      Header: "Subject",
      accessor: "subject",
      resizable: false,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { subject }}}) => (
        <span title={subject}>{subject}</span>
      ),
    },
    {
      Header: "Body",
      resizable: false,
      width: 120,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: { id, errorMessage }}}) => (
        <button className="emails__open-email-button" onClick={() => showEmailModal(id, errorMessage)}>
          Open Email
        </button>
      ),
    },
    {
      Header: () => (
        <div className="payment-status">
          <span>Status</span>
        </div>
      ),
      resizable: false,
      getProps: getCheckboxStyle,
      width: 120,
      Cell: ({
        row: {
          _original: { status },
        },
      }) => {
        const isColoredStatus = status === "delivered" || status === "open"
          || status === "click" || status === "bounce";
        if (isColoredStatus)
          return (
            <div className={`status-label status-label--${status}`}>
              {status}
            </div>
          );
        
        return (
          <div className={`status-label status-label--other`}>
            {`${status}`}
          </div>
        );
      },
    },
    {
      Header: "Modified date",
      resizable: false,
      width: 160,
      getProps: getLabelStyle,
      Cell: ({ row: { _original: data } }) => {
        const date = new Date(data.modifiedAtUtc)
        return <>{
          date.toLocaleDateString()
        } {date.toLocaleTimeString()}</>
      }
    },
    {
      Header: "Problematic",
      resizable: false,
      width: 140,
      getProps: getCheckboxStyle,
      Cell: ({ row: { _original: { status, isSolved, id } } }) => {
        if (status !== "delivered" && status !== "open" && status !== "click") {
          return (
            <CheckBox
              id={`is-solved-${id}`}
              checked={isSolved}
              onChange={() => updateProblematicEmailHandler(id, !isSolved)}
            >
              Solved
            </CheckBox>
          )
        }

        return null
      },
    },
    {
      Cell: ({ row: { _original: { id }} }) => (
        <button
          className="btn-primary emails__resend-button"
          onClick={() => showResendModal(id)}
        >
          Resend email
        </button>
      ),
      getProps: getFlexStyle,
      width: 200,
      fixed: "right",
    },
  ];

  return (
    <Container className="membership-managment emails">
      <di className="emails__header">
        <PageTitle>Emails</PageTitle>
        {problematicEmailsCount && (
          <div>
            <strong>Problematic emails:</strong> {problematicEmailsCount}
          </div>
        )}
      </di>

      <div className=" searchPanel emails__searchPanel">
        <Select
          options={periods}
          placeholder="Select period"
          name="searchProfilePeriod"
          classNamePrefix="custom-select"
          value={searchPeriod}
          onChange={changeSearchPeriodHandler}
          className="seasonpass__select searchPanel__select"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <Select
          isClearable
          options={emailsStatuses}
          placeholder="Select status"
          name="searchStatus"
          classNamePrefix="custom-select"
          value={searchStatus}
          onChange={changeSearchStatusHandler}
          className="seasonpass__select searchPanel__select emails__status"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <Select
          isClearable
          options={emailsTypes}
          placeholder="Select type"
          name="searchType"
          classNamePrefix="custom-select"
          value={emailType}
          onChange={changeEmailTypeHandler}
          className="seasonpass__select searchPanel__select emails__status"
          styles={customSelectStyles}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#6071B5",
              primary25: "#F2F4FE",
              primary50: "#F2F4FE",
              primary75: "#F2F4FE",
            },
          })}
        />

        <input
          className="searchPanel__search input-behavior"
          type="text"
          placeholder="Search by email"
          value={searchQuery}
          onChange={changeSearchInputHandler}
        />
      </div>

      <CheckBox
        name="isProblematick"
        id="is-problematic"
        checked={isShowProblematic}
        onChange={changeShowProblematicHandler}
      >
        Show problematic emails
      </CheckBox>

      {isEmailsLoading ? (
        <NewLoader />
      ) : data.length ? (
        <>
          <ReactTableFixedColumns
            data={dataToDisplay}
            columns={columns}
            manual
            showPagination={true}
            pageSizeOptions={[100, 200, 500, 1000, 100000]}
            minRows={0}
            page={currentPageNumber}
            pageSize={pageSize}
            pages={Math.ceil(data.length / pageSize)}
            onPageChange={handleCurrentPageChanged}
            onPageSizeChange={handlePageSizeChanged}
            sortable={false}
            getTrProps={() => ({
              style: {
                alignItems: "stretch",
              },
            })}
          // getTableProps={() => ({
          //   onScroll: handleTransactionsTableScroll,
          // })}
          />
        </>
      ) : (
        <div className="events__empty-state">
          <PageTitle>{emptyStateText}</PageTitle>
          <img src="/images/img-empty-events.svg" alt="Empty membership" />
        </div>
      )}
    </Container>
  );
};

const mapStateToProps = ({ emails }) => ({
  emailsList: emails.emailsList,
  isEmailsLoading: emails.isEmailsLoading,
  currentEmail: emails.currentEmail,
  isEmailLoading: emails.isEmailLoading,
  problematicEmailsCount: emails.problematicEmailsCount,
});

const mapDispatchToProps = (dispatch) => ({
  getEmails: (params) => dispatch(getEmails(params)),
  getEmail: (id) => dispatch(getEmail(id)),
  resendEmail: (id) => dispatch(resendEmail(id)),
  getProblematicEmailsCount: () => dispatch(getProblematicEmailsCount()),
  updateProblematicEmail: (id, isSolved) => dispatch(updateProblematicEmail(id, isSolved)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Emails)
);
