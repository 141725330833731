import * as actionTypes from "../actionTypes/memberType";

const initialState = {
    membershipList: []
};

const member = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.EDIT_MEMBER_CARD_INFO_SET_LOADING:
            return {
                ...state,
                membershipList: state.membershipList.map((mc, i) => mc.id === action.payload.id ? {
                    ...mc,
                    loading: action.payload.state ? true : undefined
                } : mc)
            }
        case actionTypes.EDIT_MEMBER_CARD_INFO:
            return {
                ...state,
                membershipList: state.membershipList.map((mc, i) => mc.id === action.payload.id ? {
                    ...action.payload
                } : mc)
            };
        case actionTypes.GET_MEMBER_CARDS_SUCCESS:
            return {
                ...state,
                membershipList: action.payload,
            };
        default:
            return state;
    }
}

export default member;