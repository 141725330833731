import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const executeAsyncWithModal = async (
  callback,
  html,
  className = "please-wait-modal"
) => {
  let result = null;

  try {
    MySwal.fire({
      html: html,
      customClass: className,
    });

    result = await callback();
  } finally {
    MySwal.close();
  }

  return result;
};
