import axios from "../axios-instance";

import { getHeaders } from "./getHeaders";

export const getScanteamsAsyncFunc = () => {
  const user = JSON.parse(localStorage.getItem("user"));

  if (!user) {
    return async () => [];
  }

  return (inputValue) =>
    axios
      .post("/EventReports/scanteams/list", null, {
        headers: getHeaders(user.token),
      })
      .then(({ data }) => {
        let options = [];

        if (data.result) {
          options = Object.keys(data.result)
            .map((key) => ({ label: data.result[key], value: key }))
            .filter(({ label }) =>
              label.toLowerCase().includes(inputValue.toLowerCase())
            );
        }
        return options;
      })
      .catch((e) => {
        console.log(e);
      });
};
