import React, { Component } from "react";

import "../Upload.sass";

class UnsupportedFileFormatModal extends Component {
  render() {
    return (
      <p>
        <strong>Error!</strong>
        Unsupported file format.
        <br /> Only {this.props.accept || ".csv"} files are allowed
      </p>
    );
  }
}

export default UnsupportedFileFormatModal;
