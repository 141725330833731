import React, {Component} from 'react';

import '../Upload.sass';

class OnlyOneFileModal extends Component {
    render() {
        return (
            <p>
                <strong>Error!</strong>
                You can upload only 1 file!
            </p>
        );
    }
}

export default OnlyOneFileModal;
