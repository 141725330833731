import React, { useState } from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { isValidEmail } from "../../../../../../helpers/validators";
import { InputValidation } from "../../../../../../components/UI/InputValidation/InputValidation";
import { SeatsDescriptionModal } from "../../../../../../components/Transactions/SeatsDescriptionModal/SeatsDescriptionModal";


const MySwal = withReactContent(Swal);

export const EditTransactionModal = ({
  token,
  editTransaction,
  deleteSeatsTransaction,
  transaction,
  getEventTransactions
}) => {
  const [name, setName] = useState(transaction.fullName);
  const [email, setEmail] = useState(transaction.buyerEmail);
  const [validEmail, setValidEmail] = useState(true);

  const idsSeats = transaction.seats.map((seat) => seat.id);
  const [checkedSeats, setCheckedSeats] = useState(idsSeats);

  const canSave = checkedSeats.length > 0 && validEmail;

  const changeInputNameHandler = (e) => {
    setName(e.target.value);
  };

  const changeInputEmailHandler = (e) => {
    setEmail(e.target.value);
  };

  const lostFocusEmailInput = () => {
    const valid = isAcceptableEmail();

    setValidEmail(valid);
  };

  const handleCheck = (e) => {
    const seatId = e.target.dataset.related;
    var updatedSeats = [...checkedSeats];

    if (e.target.checked) {
      updatedSeats = [...checkedSeats, seatId];
    } else {
      updatedSeats.splice(checkedSeats.indexOf(seatId), 1);
    }

    setCheckedSeats(updatedSeats);
  };

  const closeModalHandler = (e) => {
    MySwal.close();
  };

  const isAcceptableEmail = () => isValidEmail(email) || email === "";

  const saveTransactionHandler = (e) => {
    const valid = isAcceptableEmail();

    setValidEmail(valid);

    if (valid) {
      const editedTransaction = { ...transaction };

      editedTransaction.fullName = name;
      editedTransaction.buyerEmail = email;

      const seatIdsForDelete = transaction.seats
        .filter((seat) => !checkedSeats.includes(seat.id))
        .map((seat) => seat.id);

      editTransaction(token, editedTransaction)
        .then(() => {
          getEventTransactions(token);
        });

      if (seatIdsForDelete.length > 0) {
        deleteSeatsTransaction(token, transaction, seatIdsForDelete);
      }

      MySwal.close();
    }
  };

  return (
    <>
      <div className="input-block">
        <label className="swal2-label">Name</label>
        <input
          value={name}
          placeholder="Enter name"
          className="modal-input"
          onChange={changeInputNameHandler}
        />
      </div>
      <div className="input-block">
        <label className="swal2-label">Email</label>
        <InputValidation
          value={email}
          placeholder="Enter email"
          isValid={validEmail}
          errorMessage="This is an invalid email"
          className="modal-input"
          onChange={changeInputEmailHandler}
          onBlur={lostFocusEmailInput}
        />
      </div>
      {transaction.seats.length > 0 && (
        <div className="edit-seats">
          <h2 className="title">Edit seats</h2>
          <SeatsDescriptionModal
            seats={transaction.seats}
            checkedSeats={checkedSeats}
            handleCheck={handleCheck}
          />
          <span className={checkedSeats.length > 0 ? "" : "error-label"}>
            You cannot remove all seats from the selected transaction, please
            note that this action is irreversible.
          </span>
        </div>
      )}
      <div className="swal2-actions">
        <button
          className="swal2-cancel swal2-styled"
          onClick={closeModalHandler}
        >
          Cancel
        </button>
        <button
          className="swal2-confirm swal2-styled"
          disabled={!canSave}
          onClick={saveTransactionHandler}
        >
          Save
        </button>
      </div>
    </>
  );
};
