export default function getRelativeMousePositionOnCanvas({
  windowMouseX,
  windowMouseY,
  canvasWorkingSize,
  scrollPosition,
  zoom,
}) {
  const zoomRatio = zoom / 100;

  const canvasNewWidth = canvasWorkingSize.width * zoomRatio;
  const canvasNewHeight = canvasWorkingSize.height * zoomRatio;

  const gutterX = (canvasWorkingSize.width - canvasNewWidth) / 2;
  const gutterY = (canvasWorkingSize.height - canvasNewHeight) / 2;

  const relativeMouseX =
    (windowMouseX - scrollPosition.x - gutterX) / zoomRatio;
  const relativeMouseY =
    (windowMouseY - scrollPosition.y - gutterY) / zoomRatio;

  return {
    relativeMouseX,
    relativeMouseY,
  };
}
