import axios from 'axios';
import store from './index';
import {logout, refreshToken} from './store/actions/authActions';
import { getCustomURLs } from './helpers/getCustomURLs';

const { API_URL } = getCustomURLs();

const instance = axios.create({
    baseURL: API_URL
});

let isLoadingRefreshToken = false;

instance.interceptors.response.use(null, function (error) {
    if (error.response && error.response.status === 401) {
        const state = store.getState();
        const isPromoterBlocked = error.response.data && error.response.data.Code === 4007;
        if (isPromoterBlocked) {
          store.dispatch(logout(error.response.data.ErrorMessage));
        }

        if (!isLoadingRefreshToken && !isPromoterBlocked) {
            isLoadingRefreshToken = true;

            store.dispatch(refreshToken({refreshToken: state.auth.user.refreshToken}))
                .then(result => {
                    if (result.isSuccess === false) throw new Error(result.errorMessage);
                    return result;
                })
                .then(refreshedToken => {
                    isLoadingRefreshToken = false;

                    let currentLocalStoreUser = {
                        ...JSON.parse(localStorage.getItem('user')),
                        token: refreshedToken.accessToken,
                        refreshToken: refreshedToken.refreshToken,
                    };

                    localStorage.setItem('user', JSON.stringify(currentLocalStoreUser));

                    return {
                        token: refreshedToken.accessToken,
                        refreshToken: refreshedToken.refreshToken,
                    }
                })
                .catch(err => {
                    isLoadingRefreshToken = false;
                    store.dispatch(logout());
                });
        }
    }
    return Promise.reject(error);
});

export default instance;
