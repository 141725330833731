import React, { useEffect, useRef, useState } from "react";
import axios from '../../axios-instance';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import withReactContent from 'sweetalert2-react-content';
import Swal from "sweetalert2";
import { ADYEN_JS_URL, ADYEN_CSS_URL, DEVELOP_KEY, PROD_KEY } from './config';
import './AdyenDropin.sass'

const CONFIG = {
    ORIGIN_KEY: process.env.NODE_ENV === 'development' ? DEVELOP_KEY : PROD_KEY,
    SCRIPTS_LINK: ADYEN_JS_URL,
    STYLES_LINK: ADYEN_CSS_URL,
    LAST_PAYMENT_ID_FIELD_NAME: "lastPaymentId",
}

const RESULT_CODES = {
    Authorised: 1,
    Refused: 2,
    Pending: 3,
    PresentToShopper: 4,
    Cancelled: 5,
    Error: 6,
    Received: 7,
    IdentifyShopper: 8,
    RedirectShopper: 9,
}

const MySwal = withReactContent(Swal);

const translations = {
    "en-US": {
        "payButton": "Store card",
    }
};

export default function AdyenDropin(props) {
    const [cardComponent, setCardComponent] = useState(null);
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);

    const makePayment = (state) => {
        return axios.put(
            'PaymentMethods/CreditCard',
            {
                ...state.data.paymentMethod,
                browserInfoJSON: JSON.stringify(state.data.browserInfo),
            },
            {
                headers: {
                    'Authorization': `Bearer ${props.token}`,
                    'Accept': 'text/plain'
                }
            }
        )
    };

    const handleAdditionalInfoRequest = (lastPaymentId, data, token) => {
        return axios.put(
            `Payment/${lastPaymentId}/cardVerification`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'text/plain'
                }
            }
        )
    };

    function handleOnChange(state, component) {
        setCardComponent(component);
        if (state.isValid) {
            setIsSaveButtonDisabled(false)
        } else {
            setIsSaveButtonDisabled(true)
        }
    }

    function handleButtonCLick() {
        if (!isSaveButtonDisabled) {
            setIsSaveButtonDisabled(true)
            cardComponent.submit()
        }
    }

    function showNotification(type, title, message) {
        MySwal.fire({
            title: title,
            text: message,
            type: type,
            timer: 3000
        })
            .then(() => window.location.reload())
    }

    function initAdyenCheckout() {
        const configuration = {
            locale: "en_US",
            environment: "test",
            originKey: CONFIG.ORIGIN_KEY,
            paymentMethodsResponse: props.paymentMethodsResponse,
            translations,
        };
        const checkout = new window.AdyenCheckout(configuration);
        const storedPaymentMethod = checkout.paymentMethodsResponse.storedPaymentMethods[0];

        const dropin = checkout.create("card", {
            hasHolderName: true,
            holderNameRequired: false,
            showPayButton: false,
            onSubmit: (state, dropin) => {
                makePayment(state)
                    .then((response) => {
                        if (response.data.isSuccess) {
                            if (response.data.result.action) {
                                localStorage.setItem(CONFIG.LAST_PAYMENT_ID_FIELD_NAME, response.data.result.id);
                                toast.info('Redirecting...');
                                setTimeout(() => checkout.createFromAction(response.data.result.action).mount(dropinRef.current), 1000);
                                return;
                            }
                            showNotification(
                                "success",
                                "Card stored",
                                "Credit Card was successfully stored. Now you can buy scanbits!"
                            )
                            return;
                        }
                        if (response.data.errorMessage) {
                            showNotification(
                                "error",
                                "Something went wrong",
                                `${response.data.errorMessage}`
                            );
                        }
                    })
                    .catch(err => {
                          showNotification(
                            "error",
                            "Something went wrong",
                            err
                          );
                    });
            },
            onChange: handleOnChange,
            onAdditionalDetails: (state, dropin) => {
                toast.info('Processing...');
                handleAdditionalInfoRequest(
                    localStorage.getItem(CONFIG.LAST_PAYMENT_ID_FIELD_NAME),
                    {...state.data},
                    props.token,
                )
                    .then(response => {
                        if (response.data.isSuccess) {
                            switch (response.data.result.status) {
                                case RESULT_CODES.Authorised:
                                    showNotification(
                                        "success",
                                        "Card stored",
                                        "Credit Card was successfully stored. Now you can buy scanbits!"
                                    );
                                    break;
                                case RESULT_CODES.Error:
                                    showNotification(
                                        "error",
                                        "Error occurred",
                                        "Credit Card not stored. Try again!"
                                    );
                                    break;
                                case RESULT_CODES.Refused:
                                    showNotification(
                                        "error",
                                        "Refused",
                                        "Yor request was refused. Try again!"
                                    );
                                    break;
                                case RESULT_CODES.Pending,
                                    RESULT_CODES.Received:
                                    showNotification(
                                        "success",
                                        "Pending",
                                        "Yor request was accepted. We need some time to handle it!."
                                    );
                                    break;
                                default:
                                    showNotification(
                                        "error",
                                        "Something went wrong",
                                        "Credit Card not stored. Try again!"
                                    );
                                    break
                            }

                            localStorage.setItem(CONFIG.LAST_PAYMENT_ID_FIELD_NAME, '');
                        }
                    })
                    .catch(err => console.log(err));
                props.history.push('/payments');
            },
            onLoad: () => {
                if (!!window.location.search) {
                    handleAdditionalInfoRequest(
                        localStorage.getItem(CONFIG.LAST_PAYMENT_ID_FIELD_NAME),
                        {details: JSON.parse(queryString.parse(window.location.search).Details)},
                        props.token,
                    )
                        .then(response => {
                            if (response.data.isSuccess) {
                                switch (response.data.result.status) {
                                    case RESULT_CODES.Authorised:
                                        showNotification(
                                            "success",
                                            "Card stored",
                                            "Credit Card was successfully stored. Now you can buy scanbits!"
                                        );
                                        break;
                                    case RESULT_CODES.Error:
                                        showNotification(
                                            "error",
                                            "Error occurred",
                                            "Credit Card not stored. Try again!"
                                        );
                                        break;
                                    case RESULT_CODES.Refused:
                                        showNotification(
                                            "error",
                                            "Refused",
                                            "Yor request was refused. Try again!"
                                        );
                                        break;
                                    case RESULT_CODES.Pending,
                                        RESULT_CODES.Received:
                                        showNotification(
                                            "success",
                                            "Pending",
                                            "Yor request was accepted. We need some time to handle it!."
                                        );
                                        break;
                                    default:
                                        showNotification(
                                            "error",
                                            "Something went wrong",
                                            "Credit Card not stored. Try again!"
                                        );
                                        break
                                }
                                localStorage.setItem(CONFIG.LAST_PAYMENT_ID_FIELD_NAME, '');
                            }
                        })
                        .catch(err => console.log(err));
                    props.history.push('/payments');
                }
            }
        }).mount(dropinRef.current);
    }

    const dropinRef = useRef(null);

    useEffect(() => {
        if (!!window.location.search) {
            toast.info('Processing...');
        }

        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = CONFIG.STYLES_LINK;
        document.head.appendChild(link);

        const script = document.createElement("script");
        script.src = CONFIG.SCRIPTS_LINK;
        script.async = true;
        script.onload = initAdyenCheckout; // Wait until the script is loaded before initiating AdyenCheckout
        document.body.appendChild(script);
    }, []);

    if (props.paymentMethodsResponse.storedPaymentMethods) {
        props.handleDisabledButton(false);
    }

    return (
        <>
            {props.paymentMethodsResponse.storedPaymentMethods &&
            <div className="stored-card">
                <label className="stored-card__label">
                    <span className="stored-card__label-text">Current card:</span>
                    <input className="stored-card__input" type="text" disabled
                           value={`**** **** **** ${props.paymentMethodsResponse.storedPaymentMethods[0].lastFour}`} />
                </label>
                </div>
            }
            <div className="credit-card" ref={dropinRef} />
            <button className="btn-primary" onClick={handleButtonCLick} disabled={isSaveButtonDisabled}>Save</button>
        </>
    );
}
