import React from "react";
import swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import SeasonPassInfoModal from "../containers/SeasonPass/SeasonPassInfoModal/SeasonPassInfoModal";

const MySwal = withReactContent(swal);

export const showInfoModal = ({
  image,
  title,
  text,
  link,
  additionalText = null,
  imageAltText = "Thanks For Your Purchase",
  customClass = "seasonpass__modal_info seasonpass__modal",
  onConfirm = (result) => {},
  ...options
}) => {
  MySwal.fire({
    html: (
      <SeasonPassInfoModal
        image={image}
        imageAltText={imageAltText}
        title={title}
        text={text}
        link={link}
        additionalText={additionalText}
      />
    ),
    width: "900px",
    allowEscapeKey: false,
    padding: "0px 0px 48px 0px",
    customClass: customClass,
    allowOutsideClick: false,
    allowEnterKey: false,
    showConfirmButton: false,
    ...options,
  }).then(onConfirm);
};
