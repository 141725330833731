import { generateUniqueId } from "../components/Canvas/helpers";
import {
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT,
  DEFAULT_CANVAS_FOOTER_MB_OBJECT,
  DEFAULT_CANVAS_FOOTER_PIB_OBJECT,
  DEFAULT_CANVAS_FOOTER_LINK_OBJECT,
} from "../constants/canvas";

export const appendPDFfooter = async ({
  appendFooterImageObject,
  appendRectangleObject,
  appendTextObject,
  currentTemplate,
  footerInfo
}) => {
  appendFooterImageObject({
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
    id: generateUniqueId(),
  });

  appendRectangleObject({
    ...DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT,
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
    id: generateUniqueId(),
    width: currentTemplate.width,
    height: DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height,
    borderRadius: 0
  }, true);

  appendTextObject({
    ...DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height - 3,
    text: footerInfo.name,
    width: currentTemplate.width * 0.25,
    id: generateUniqueId(),
  }, true);

  appendTextObject({
    ...DEFAULT_CANVAS_FOOTER_MB_OBJECT,
    x: currentTemplate.width * 0.25,
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height - 3,
    text: `MB: ${footerInfo.mb}`,
    width: currentTemplate.width * 0.25,
    id: generateUniqueId(),
  }, true);
  appendTextObject({
    ...DEFAULT_CANVAS_FOOTER_PIB_OBJECT,
    x: currentTemplate.width * 0.5,
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height - 3,
    text: `PIB: ${footerInfo.pib}`,
    width: currentTemplate.width * 0.25,
    id: generateUniqueId(),
  }, true);

  appendTextObject({
    ...DEFAULT_CANVAS_FOOTER_LINK_OBJECT,
    x: currentTemplate.width * 0.75,
    y: currentTemplate.height - DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.height - 3,
    text: footerInfo.link,
    width: currentTemplate.width * 0.25,
    id: generateUniqueId(),
  }, true);
}
