import {
  CANVAS_CONTROLS_OVERLAY,
  DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT,
  DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT,
  DEFAULT_CANVAS_QRCODE_OBJECT,
  DEFAULT_CANVAS_FOOTER_NAME_OBJECT,
  DEFAULT_CANVAS_FOOTER_MB_OBJECT,
  DEFAULT_CANVAS_FOOTER_PIB_OBJECT,
  DEFAULT_CANVAS_FOOTER_LINK_OBJECT,
} from "../../constants/canvas";
import getControlPoints from "../../helpers/getControlPoints";

import {
  renderEllipse,
  renderFreeDrawing,
  renderImage,
  renderRectangle,
  renderText,
  renderRoundedImage,
} from "./render";
import renderFooterImage from "./render/renderFooterImage";

export function canvasSetCenter({
  canvas,
  context,
  canvasWorkingSize,
  setScrollPosition,
  windowSize,
  setZoom,
}) {
  if (!canvas || !context) return;
  const { width, height } = windowSize;

  const gteMd = width > 900;

  const widthOverflow = canvasWorkingSize.width - width;
  const heightOverflow = canvasWorkingSize.height - height;

  const maxOverflow = Math.max(widthOverflow, heightOverflow);

  const hasOverflow = maxOverflow > 0;

  const gutter = {
    top: gteMd ? 40 : 15,
    bottom: gteMd ? 100 : 90,
    horizontal: gteMd ? 100 : 24,
  };

  const scrollPositionX = (width - canvasWorkingSize.width) / 2;
  const scrollPositionY = (height - canvasWorkingSize.height) / 2;

  const newZoom =
    widthOverflow === maxOverflow
      ? ((width - gutter.horizontal * 2) / canvasWorkingSize.width) * 100
      : ((height - gutter.bottom) / canvasWorkingSize.height) * 100;

  setZoom(hasOverflow ? newZoom : 100);

  setScrollPosition({
    x: scrollPositionX,
    y: scrollPositionY,
  });
}

export function generateUniqueId() {
  let timestamp = Date.now().toString(16);

  let randomPart = Math.floor(Math.random() * 65536).toString(16);

  return timestamp + randomPart;
}

export function canvasInit({ canvas, context, canvasWidth, canvasHeight }) {
  if (!canvas || !context) return;

  canvas.width = canvasWidth;
  canvas.height = canvasHeight;

  canvas.style.width = `${canvasWidth}px`;
  canvas.style.height = `${canvasHeight}px`;
}

export function canvasDrawEverything({
  canvas,
  context,
  canvasWorkingSize,
  canvasBackgroundColor,
  canvasObjects,
  activeObjectId,
  actionMode,
  scrollPosition,
  windowSize,
  zoom,
  addImageElementToObj = () => {},
}) {
  if (!canvas || !context) return;

  // Draw background
  context.clearRect(0, 0, canvas.width, canvas.height);

  context.fillStyle = canvasBackgroundColor;
  context.fillRect(0, 0, canvasWorkingSize.width, canvasWorkingSize.height);

  // Draw objects
  canvasObjects.forEach((object) => {
    switch (object.type) {
      case "rectangle":
        renderRectangle({ context, ...object });
        break;
      case "ellipse":
        renderEllipse({ context, ...object });
        break;
      case "free-draw":
        renderFreeDrawing({ context, ...object });
        break;
      case "text":
        renderText({ context, ...object });
        break;
      case "image":
        renderImage({ context, ...object, addImageElementToObj });
        break;
      case DEFAULT_CANVAS_QRCODE_OBJECT.type:
        renderRoundedImage({ context, ...object, addImageElementToObj });
        break;
      case DEFAULT_CANVAS_FOOTER_IMAGE_OBJECT.type:
        renderFooterImage({ context, ...object });
        break;
      case DEFAULT_CANVAS_FOOTER_RECTABGLE_OBJECT.type:
        renderRectangle({ context, ...object });
        break;
      case DEFAULT_CANVAS_FOOTER_NAME_OBJECT.type:
        renderText({ context, ...object });
        break;
      case DEFAULT_CANVAS_FOOTER_MB_OBJECT.type:
        renderText({ context, ...object });
        break;
      case DEFAULT_CANVAS_FOOTER_PIB_OBJECT.type:
        renderText({ context, ...object });
        break;
      case DEFAULT_CANVAS_FOOTER_LINK_OBJECT.type:
        renderText({ context, ...object });
        break;
      default:
        break;
    }
  });

  const activeObject = canvasObjects.find(
    (object) => object.id === activeObjectId
  );

  const overlayCanvas = document.getElementById(CANVAS_CONTROLS_OVERLAY);
  const overlayContext = overlayCanvas ? overlayCanvas.getContext("2d") : null;

  if (overlayContext) {
    overlayContext.clearRect(0, 0, windowSize.width, windowSize.height);

    const type = actionMode && actionMode.type ? actionMode.type : "";

    if (activeObject && type !== "isDrawing") {
      const zoomRatio = zoom / 100;

      const positionAdjustment = {
        x:
          scrollPosition.x +
          (canvasWorkingSize.width - canvasWorkingSize.width * zoomRatio) / 2,
        y:
          scrollPosition.y +
          (canvasWorkingSize.height - canvasWorkingSize.height * zoomRatio) / 2,
      };

      // Draw controls
      const {
        position,
        topLeftBox,
        topCenterBox,
        topRightBox,
        middleLeftBox,
        middleRightBox,
        bottomLeftBox,
        bottomCenterBox,
        bottomRightBox,
      } = getControlPoints({
        canvasObject: activeObject,
        zoom,
      });

      overlayContext.lineWidth = 2;
      overlayContext.strokeStyle = "#ff0000"; // red
      overlayContext.strokeRect(
        position.x * zoomRatio + positionAdjustment.x,
        position.y * zoomRatio + positionAdjustment.y,
        position.width * zoomRatio,
        position.height * zoomRatio
      );
      overlayContext.setLineDash([0, 0]);
      overlayContext.fillStyle = "#ff0000";

      [
        topLeftBox,
        topCenterBox,
        topRightBox,
        middleLeftBox,
        middleRightBox,
        bottomLeftBox,
        bottomCenterBox,
        bottomRightBox,
      ].forEach((box) => {
        overlayContext.fillRect(
          box.x * zoomRatio + positionAdjustment.x,
          box.y * zoomRatio + positionAdjustment.y,
          box.width * zoomRatio,
          box.height * zoomRatio
        );
      });
    }
  }
}
