import React, { Component } from "react";
import { connect } from "react-redux";
import withRouter from "react-router/es/withRouter";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import queryString from "query-string";

import {
  getEvent,
  getEventScanStats,
  getEventSellStats,
} from "../../../../../store/actions/eventsActions";
import browserTabTitleOverride from "../../../../../helpers/tabTitleOverride";
import SellStatisticForm from "./sell/SellStatisticForm";
import ScanStatisticForm from "./scan/ScanStatisticForm";

import "./EventStatistics.sass";
import "react-tabs/style/react-tabs.css";
import NewLoader from "../../../../../components/NewLoader/NewLoader";


class StatisticPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isScanOpened: false,
      isStatsLoading: true,
    };
  }

  componentDidMount() {
    browserTabTitleOverride();
    const {
      token,
      match: { params },
      currentEvent,
    } = this.props;
    let event_id = params.event_id || params.id;    

    if (!currentEvent.id) {
      const action = queryString.parse(this.props.location.search).action;
      this.props.getEvent(event_id, token, action).then((res) => {
        if (res.isFound) {
          Promise.all([
            this.props.getEventSellStats(event_id, token), this.props.getEventScanStats(event_id, token)
          ]).then(() => {
            this.setState({ isStatsLoading: false });
          });
        }
      });
    } else {
      Promise.all([
        this.props.getEventSellStats(event_id, token), this.props.getEventScanStats(event_id, token)
      ]).then(() => {
        this.setState({ isStatsLoading: false });
      });
    }
  }

  handleScanClick = () => {
    this.setState({ isScanOpened: true });
  };

  render() {
    const { currentEvent, isLoading } = this.props;
    const { isStatsLoading } = this.state;

    if (isLoading || isStatsLoading) {
      return (
        <div className="new-event__stats statistics">
          <NewLoader />
        </div>
      )
    };

    return (
      <div className="new-event__stats statistics">
        <Tabs className="statistics__tabs">
          <TabList>
            <Tab>Sell</Tab>
            <Tab onClick={this.handleScanClick}>Scan</Tab>
          </TabList>
          <TabPanel>
            <SellStatisticForm id={currentEvent.id} />
          </TabPanel>
          <TabPanel>
            <ScanStatisticForm id={currentEvent.id} />
          </TabPanel>
        </Tabs>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.user.token,
    currentEvent: state.events.currentEvent,
    isLoading: state.events.loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token, action) => dispatch(getEvent(eventId, token, action)),
  getEventSellStats: (eventId, token) =>
    dispatch(getEventSellStats(eventId, token)),
  getEventScanStats: (eventId, token) =>
    dispatch(getEventScanStats(eventId, token)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StatisticPage));
