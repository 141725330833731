import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import ReactTable from "react-table";
import { toast } from "react-toastify";
import queryString from "query-string";
import CheckBox from "../../../../../components/UI/Checkbox/CheckBox";
import Icon from "../../../../../components/UI/Icon/Icon";
import PleaseWaitModal from "../../../../BarCodes/modals/PleaseWaitModal";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import axios from "../../../../../axios-instance";
import Page404 from "../../../../../components/Page404/Page404";

import {
  clearCurrentEvent,
  getEvent,
  getTicket,
} from "../../../../../store/actions/eventsActions";

import "./TicketActions.sass";
import Upload from "../../../../Upload/Upload";
import { getHeaders } from "../../../../../helpers/getHeaders";
import {
  actionHandler,
  getActions,
} from "../../../../../store/actions/ticketGenerationActions";
import NewLoader from "../../../../../components/NewLoader/NewLoader";
import { getCustomURLs } from "../../../../../helpers/getCustomURLs";
import { executeAsyncWithModal } from "../../../../../helpers/modal";
import { loadFile } from "../../../../../helpers/loadFile";

const MySwal = withReactContent(Swal);

class GeneratePdfs extends Component {
  state = {
    file: [],
    generationTitle: "",
    isFound: true,
    isSubmitBlocked: false,
    loading: false,
    needBookSeats: true,
  };

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      getEvent,
      getTicket,
      getActions,
    } = this.props;

    if (params.id) {
      getTicket(params.ticket_id, token).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
    }

    const action = queryString.parse(this.props.location.search).action;

    if (!this.props.currentEvent.id)
      getEvent(params.id, token, action).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });

    if (this.props.currentTicket.id || params.ticket_id) {
      getActions(token, this.props.currentTicket.id || params.ticket_id);
      this.intervalId = setInterval(
        () =>
          getActions(token, this.props.currentTicket.id || params.ticket_id, false),
        30000
      );
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  onButtonEnable = (file) => {
    if (!this.state.generationTitle) {
      console.log(file[0].name.split(".")[0]);
      this.setState({
        generationTitle: file[0].name.split(".")[0],
        file,
      });
      return;
    }

    this.setState({
      file,
    });
  };

  handleSubmit = async () => {
    //TODO: Should use redux!!!
    try {
      const {
        match: { params },
      } = this.props;
      this.setState({ loading: true });
      const user = JSON.parse(localStorage.getItem("user"));

      const { file, generationTitle, needBookSeats } = this.state;

      const body = new FormData();
      body.append("file", file[0]);

      const { data } = await axios.post(
        `/TicketGeneration/AddTickets/${params.ticket_id}?generationTitle=${generationTitle}&needBookSeats=${needBookSeats}`,
        body,
        {
          headers: getHeaders(user.token),
        }
      );

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${this.state.generationTitle}_uplRes.csv`
        );
        document.body.appendChild(link);
        link.click();
      }
      this.setState({ loading: false, generationTitle: "" });
      this.props.getActions(
        user.token,
        this.props.currentTicket.id || params.ticket_id
      );
      toast.success("File uploaded successfully");
    } catch (e) {
      toast.error("Something went wrong. Try later");
      console.log(e);
      this.setState({ loading: false });
    }
  };

  checkIsDisabled = () => {
    const { file, generationTitle, isSubmitBlocked } = this.state;

    if (isSubmitBlocked) return true;

    return !file || !file.length || !generationTitle;
  };

  actionHandler = (type, id) => {
    const {
      user: { token },
    } = this.props;

    this.props.actionHandler(token, id, type).then(() => {
      this.props.getActions(token, this.props.currentTicket.id);
    });
  };

  handleChange = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  loadResult = async (id, name) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));
      const res = await axios.get(
        `/TicketGeneration/LoadGenerationResult?generationActionId=${id}`,
        {
          headers: getHeaders(user.token),
          responseType: "arraybuffer",
        }
      );

      if (res.data) {
        const fileName = name ? name + ".zip" : "SponsorshipTickets.zip";
        const blob = new Blob([res.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      }
    } catch (e) {
      toast.error("Something went wrong. Try later");
      console.log(e);
    }
  };

  downloadDataAsFile = (data, name) => {
    if (data) {
      loadFile(data, name);

      toast.success("File uploaded successfully");
    } else {
      toast.error("Failed to retrieve data");
    }
  };

  downloadFileHandler = async (url, fileName) => {
    try {
      if (!navigator.onLine) {
        throw new Error("offline");
      }

      // const { SERVER_URL } = getCustomURLs();

      // const url = SERVER_URL + "/Files/Static/csv/AvailableSeats.csv";

      const { data } = await executeAsyncWithModal(
        () => axios.get(url),
        <PleaseWaitModal message="Downloading file…" />
      );

      this.downloadDataAsFile(data, `${fileName}.csv`);
    } catch (e) {
      toast.error("File was not downloaded");
      console.log(e);
    }
  }

  showConfirmModal = ({
    title,
    text,
    confirmButtonText,
    cancelButtonText = "Cancel",
    reverseButtons = true,
    onConfirm,
  }) => {
    MySwal.fire({
      title: title,
      text: text,
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      showConfirmButton: true,
      confirmButtonText: confirmButtonText,
      reverseButtons: reverseButtons,
    }).then((result) => {
      if (result.value) {
        onConfirm();
      }
    });
  };

  showCleanModal = (id) =>
    this.showConfirmModal({
      title: "Are you sure to clean pdfs?",
      text: "This action will delete all generated pdfs from system.",
      confirmButtonText: "Clean",
      onConfirm: () => this.actionHandler("clean", id),
    });

  showCancelModal = (id) =>
    this.showConfirmModal({
      title: "Are you sure to cancel tickets?",
      text: "This action will cancel all generated tickets (including pdfs) from system.",
      confirmButtonText: "Confirm",
      onConfirm: () => this.actionHandler("cancel", id),
    });

  renderActionCell = ({ original: rowData }) => {
    if (rowData.changesRequested && !rowData.originalOrderId) return null;

    const cancelButton = (
      <button
        className="btn-red-outline"
        title="Cancel"
        onClick={() => this.showCancelModal(rowData.id)}
      >
        Cancel
      </button>
    );

    const cleanButton = (
      <button
        className="btn-red-outline"
        title="Delete"
        onClick={() => this.showCleanModal(rowData.id)}
      >
        Clean
      </button>
    );

    const stopButton = (
      <button
        className="btn-primary"
        title="Stop"
        onClick={() => this.actionHandler("stop", rowData.id)}
      >
        Stop
      </button>
    );

    const startButton = (
      <button
        className="btn-primary"
        title="Start"
        onClick={() => this.actionHandler("start", rowData.id)}
      >
        Start
      </button>
    );

    const loadResult = (
      <button
        className="btn-primary"
        title="Load Result"
        onClick={() => this.loadResult(rowData.id, rowData.resultTag)}
      >
        Load Result
      </button>
    );

    switch (rowData.jobStatus) {
      case "NotCalled":
        return (
          <>
            {startButton}
            {cancelButton}
          </>
        );
      case "Running":
        return <>{stopButton}</>;
      case "Completed":
        return (
          <>
            {loadResult}
            {cleanButton}
            {cancelButton}
          </>
        );
      case "Deleted":
        return (
          <>
            <button
              className="btn-primary"
              title="Deleted"
              disabled
              style={{
                cursor: "default"
              }}
            >
              Cancelled
            </button>
          </>
        );
      case "Error":
        return (
          <>
            {startButton}
            {cleanButton}
            {cancelButton}
          </>
        );
      default:
        break;
    }
  };
  

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    if (this.state.loading) {
      return <NewLoader />;
    }

    const { actionsList, loading } = this.props;

    const getLabelStyle = () => ({
      style: {
        position: "relative",
      },
    });

    const columns = [
      {
        Header: "Title",
        accessor: "resultTag",
      },
      {
        Header: "Tickets",
        accessor: "generatedTicketCount",
        width: 100,
      },
      {
        Header: "Source File Name",
        accessor: "sourceFileName",
        getProps: getLabelStyle,
        Cell: ({ row: { _original: {
          sourceFileName, sourceFileUrl
        }}}) => (
          <div
            className="download-action"
            onClick={() => this.downloadFileHandler(sourceFileUrl, sourceFileName)}
          >
            <Icon name="excel" />
            <button>
              {sourceFileName}
            </button>
          </div>
        ),
      },
      {
        Header: "Action",
        accessor: "action",
        width: 290,
        className: "new-generate-pdfs__action",
        Cell: this.renderActionCell,
      },
    ];

    const { SERVER_URL } = getCustomURLs();

    return (
      <div className="new-ticket-action new-generate-pdfs">
        <input
          placeholder="Title"
          value={this.state.generationTitle}
          onChange={(e) => this.handleChange("generationTitle", e.target.value)}
        />
        <div className="checkboxes">
          <div className="checkboxes-item custom-margin-left">
            <CheckBox
              name="isNeedBookSeats"
              id="is-need-book-seats"
              checked={this.state.needBookSeats}
              onChange={(e) => this.handleChange("needBookSeats", e.target.type === "checkbox" ? e.target.checked : e.target.value)}
            >
              Book Seats
            </CheckBox>
          </div>
        </div>

        <div className="new-ticket-action__upload">
          <div className="new-ticket-action__download">
            <form
              action={
                SERVER_URL + "/Files/Static/csv/SponsorshipTickets.csv"
              }
              method="GET"
            >
              <button>Download template</button>
            </form>
          </div>

          <div className="new-ticket-action__choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload onButtonEnable={this.onButtonEnable} />
          </div>
        </div>
        <button
          type="button"
          disabled={this.checkIsDisabled()}
          onClick={this.handleSubmit}
          className="new-ticket-action__submit new-ticket-action__submit btn-primary"
        >
          Send
        </button>

        {actionsList.length ? (
          loading ? (
            <NewLoader />
          ) : (
            <ReactTable
              resizable={false}
              showPagination={false}
              sortable={false}
              data={actionsList}
              columns={columns}
              defaultPageSize={actionsList.length}
            />
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, currentTicket },
  ticketGeneration: { actionsList, error, loading },
}) => ({ user, currentEvent, currentTicket, actionsList, error, loading });

const mapDispatchToProps = (dispatch) => ({
  getActions: (token, ticketTemplateId, shouldStartLoading) =>
    dispatch(getActions(token, ticketTemplateId, shouldStartLoading)),
  actionHandler: (userToken, generationActionId, type) =>
    dispatch(actionHandler(userToken, generationActionId, type)),
  getEvent: (eventId, token, action) => dispatch(getEvent(eventId, token, action)),
  getTicket: (data, token) => dispatch(getTicket(data, token)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GeneratePdfs)
);
