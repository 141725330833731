import { TICKETS_IMPORT } from "../actionTypes/ticketImportActionsTypes";

const initialState = {
    providers: [],
    loginData: {
        email: '',
        password: '',
    },
    providersEvents: [],
    selectedEvent: [],
    selectedTicket: {},
    dataForImport: {
        sixtixEventId: 0,
        tibbaEventId: 0,
        ticketTemplateMappings: [
            {
                sixtixTicketTemplateId: 0,
                tibbaTicketTemplateId: 0,
                tibbaTicketTemplateName: '',
            }
        ]
    },
    isActive: true,
    currentStep: 1,
    isLoading: false,
    isValid: false,
};

export const ticketsImport = (state = initialState, action) => {
    switch (action.type) {
        /* Navigation */
        case TICKETS_IMPORT.NEXT_STEP:
            return {
                ...state,
                currentStep: state.currentStep + 1,
                isValid: false,
            };

        case TICKETS_IMPORT.PREV_STEP:
            return {
                ...state,
                currentStep: state.currentStep - 1,
                isValid: true,
            };

        /* GetProviders */
        case TICKETS_IMPORT.GET_PROVIDERS_START:
            return {
                ...state,
                isLoading: true,
            };

        case TICKETS_IMPORT.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                providers: action.payload,
                isLoading: false,
            };

        case TICKETS_IMPORT.GET_PROVIDERS_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        /* GetProvidersEvents */
        case TICKETS_IMPORT.GET_PROVIDERS_EVENTS_START:
            return {
                ...state,
                isLoading: true,
            };

        case TICKETS_IMPORT.GET_PROVIDERS_EVENTS_SUCCESS:
            return {
                ...state,
                providersEvents: action.payload,
                isLoading: false,
            };

        case TICKETS_IMPORT.GET_PROVIDERS_EVENTS_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        /* Actions for select instances */
        case TICKETS_IMPORT.SELECT_PROVIDERS_EVENT:
            return {
                ...state,
                selectedEvent: action.payload,
                dataForImport: {
                    ...state.dataForImport,
                    sixtixEventId: action.payload.sixtixEventId,
                    tibbaEventId: action.payload.eventId,
                },
                isValid: true,
            };

        case TICKETS_IMPORT.SELECT_PROVIDERS_TICKET_TEMPLATE:
            return {
                ...state,
                selectedTicket: action.payload,
                dataForImport: {
                    ...state.dataForImport,
                    ticketTemplateMappings: [
                        {
                            sixtixTicketTemplateId: action.payload.sixtixTicketTemplateId,
                            tibbaTicketTemplateId: action.payload.ticketTemplateId,
                            tibbaTicketTemplateName: action.payload.ticketName,
                        }
                    ]
                },
                isValid: true,
            };

        /* Import tickets action */
        case TICKETS_IMPORT.IMPORT_TICKETS_START:
            return {
                ...state,
                isLoading: true
            };

        case TICKETS_IMPORT.IMPORT_TICKETS_SUCCESS:
            return {
                ...initialState,
                currentStep: 1,
            };

        case TICKETS_IMPORT.IMPORT_TICKETS_FAIL:
            return {
                ...initialState,
                isLoading: false,
            };

        case TICKETS_IMPORT.TICKETS_IMPORT_CLEAR_STORE:
            return {
                ...initialState,
            };

        default:
            return state;
    }
};
