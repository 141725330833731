import React, { Component } from "react";

import "../BarCodes.sass";

class PleaseWaitModal extends Component {
  render() {
    const { message } = this.props;
    return (
      <p>
        <strong>Please wait</strong>
        {message}
      </p>
    );
  }
}

export default PleaseWaitModal;
