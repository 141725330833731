import { getFooterImagePdfTemplate } from "../../../helpers/getFooterImagePdfTemplate";
import getImageElementFromUrl from "../../../helpers/getImageElementFromUrl";
import renderRectangle from "./renderRectangle";
import renderText from "./renderText";

export default async function renderFooterImage({
  context,
  x,
  y,
  width,
  height,
  opacity,
  imageElement,
  addImageElementToObj,
  id,
}) {
  context.globalAlpha = opacity / 100;

  const isImageObj = imageElement && imageElement instanceof Image;

  if (isImageObj) {
    context.drawImage(imageElement, x, y, width, height);
  } else {
    /* const imageElement = await getFooterImagePdfTemplate();
    id && addImageElementToObj(id, imageElement);
    context.drawImage(imageElement, x, y, width, height); */
  }

  context.globalAlpha = 1;
}
