import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
import { toast } from "react-toastify";
import AsyncSelect from "react-select/lib/Async";
import queryString from "query-string";

import Container from "../../components/UI/Container/Container";
import axios from "../../axios-instance";
import PageTitle from "../../components/UI/PageTitle/PageTitle";

import ROUTES from "../../constants/routes";
import Page404 from "../../components/Page404/Page404";

import {
  clearCurrentEvent,
  getEvent,
  getTicket,
} from "../../store/actions/eventsActions";

import "./TicketActions.sass";
import Upload from "../Upload/Upload";
import { getHeaders } from "../../helpers/getHeaders";
import { Breadcrumbs } from "../../components/Breadcrumbs/Breadcrumbs";
import { getEmailTemplatesAsyncFunc } from "../../helpers/getEmailTemplatesAsyncFunc";
import { getCustomURLs } from "../../helpers/getCustomURLs";

// const MySwal = withReactContent(Swal);

class SendBuyEmail extends Component {
  state = {
    emailTemplate: null,
    file: [],
    isFound: true,
  };

  componentDidMount() {
    const {
      user: { token },
      match: { params },
      getEvent,
      getTicket,
    } = this.props;

    if (params.event_id) {
      getTicket(params.id, token).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
    }

    const action = queryString.parse(this.props.location.search).action;

    if (!this.props.currentEvent.id)
      getEvent(params.event_id, token, action).then((result) => {
        if (!result.isFound) {
          this.setState({ isFound: false });
        }
      });
  }

  handleChange = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  onButtonEnable = (file) => {
    this.setState({
      file,
    });
  };
  handleSubmit = async () => {
    //TODO: Should use redux!!!
    try {
      const {
        match: { params },
      } = this.props;

      const user = JSON.parse(localStorage.getItem("user"));

      const { file, emailTemplate } = this.state;

      const body = new FormData();
      body.append("file", file[0]);

      const { data } = await axios.post(
        `/ManageTransactions/SaveTransactions?eventId=${params.event_id}&emailTemplateName=${emailTemplate.value}`,
        body,
        {
          headers: getHeaders(user.token),
        }
      );

      if (data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SendOffer.csv");
        document.body.appendChild(link);
        link.click();
      }

      toast.success("File uploaded successfully");
    } catch (e) {
      toast.error("Something went wrong. Try later");
      console.log(e);
    }
  };

  checkIsDisabled = () => {
    const { file, emailTemplate } = this.state;

    return !file || !file.length || !emailTemplate || !emailTemplate.value;
  };

  render() {
    if (!this.state.isFound) {
      return <Page404 />;
    }

    const { currentEvent, currentTicket } = this.props;
    const { emailTemplate } = this.state;

    const fullPath = [
      {
        path: ROUTES.EVENTS,
        name: "Events",
      },
      {
        path: ROUTES.NEW_EVENT.replace(":id", currentEvent.id),
        name: currentEvent.name,
      },
      {
        path: ROUTES.TICKET_DETAIL.replace(
          ":event_id",
          currentEvent.id
        ).replace(":id", currentTicket.id),
        name: currentTicket.ticketName,
      },
      {
        name: "Send offer",
      },
    ];

    const { SERVER_URL } = getCustomURLs();

    return (
      <Container className="ticket-action">
        <PageTitle>
          <Breadcrumbs routes={fullPath} />
        </PageTitle>

        <AsyncSelect
          cacheOptions
          defaultOptions
          loadOptions={getEmailTemplatesAsyncFunc()}
          value={emailTemplate}
          placeholder="Select Email Template"
          onChange={(value) => this.handleChange("emailTemplate", value)}
          classNamePrefix="custom-select"
          className="custom-select"
        />

        <div className="ticket-action__upload">
          <div className="ticket-action__download">
            <form
              action={
                SERVER_URL + "/Files/Static/csv/SendOffer.csv"
              }
              method="GET"
            >
              <button>Download template</button>
            </form>
          </div>

          <div className="ticket-action__choose-file">
            <p>Choose the file ( .csv)</p>

            <Upload onButtonEnable={this.onButtonEnable} />
          </div>
        </div>

        <button
          type="button"
          disabled={this.checkIsDisabled()}
          onClick={this.handleSubmit}
          className="ticket-action__submit btn-primary"
        >
          Send
        </button>
      </Container>
    );
  }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, currentTicket },
}) => ({ user, currentEvent, currentTicket });

const mapDispatchToProps = (dispatch) => ({
  getEvent: (eventId, token, action) => dispatch(getEvent(eventId, token, action)),
  getTicket: (data, token) => dispatch(getTicket(data, token)),
  clearCurrentEvent: () => dispatch(clearCurrentEvent()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SendBuyEmail)
);
