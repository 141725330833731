import React from "react";

import ReactTable from "react-table";
import NewLoader from "../../../components/NewLoader/NewLoader";

import "./StatsInfo.sass";

const calculateColumnTotal = (data, accessor) => {
  return data.reduce((sum, row) => {
    const value = row[accessor];
    return value !== "-" ? sum + value : sum;
  }, 0);
};

function StatsInfo({ selectedTab, data = [], loading }) {
  if (loading) {
    return <NewLoader />;
  }

  const columns =
    selectedTab === 0
      ? [
          {
            Header: "Ticket Name",
            accessor: "ticketName",
            width: 170,
          },
          {
            Header: "Price",
            width: 100,
            accessor: "price",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Online + link",
            width: 125,
            accessor: "soldOnline",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Cash + credit cards)",
            width: 180,
            accessor: "soldOffline",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Invoices",
            accessor: "soldInvoices",
            width: 105,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Total sold",
            width: 110,
            accessor: "soldTotal",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Total value sold",
            width: 150,
            accessor: "soldTotalValue",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Reselling request",
            width: 160,
            accessor: "resellingRequest",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Reselling sold",
            accessor: "resellingSold",
            width: 140,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Reselling value",
            accessor: "reselingValue",
            width: 145,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Free of charge",
            width: 135,
            accessor: "freeOfCharge",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Total daily tickets",
            accessor: "totalDailyTickets",
            width: 160,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Total scanned",
            width: 135,
            accessor: "scannedTotal",
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
        ]
      : [
          {
            Header: "Entrance",
            accessor: "entranceName",
            width: 280,
          },
          {
            Header: "Daily",
            accessor: "categoriesAmount",
            Cell: (info) => info.value.Daily,
            width: 160,
            Footer: (info) => {
              const data = info.data.map(({ categoriesAmount }) => ({
                ...categoriesAmount,
              }));
              const total = calculateColumnTotal(data, "Daily");
              return <>{total}</>;
            },
          },
          {
            Header: "SP",
            accessor: "categoriesAmount",
            Cell: (info) => info.value.SP,
            width: 160,
            Footer: (info) => {
              const data = info.data.map(({ categoriesAmount }) => ({
                ...categoriesAmount,
              }));
              const total = calculateColumnTotal(data, "SP");
              return <>{total}</>;
            },
          },
          {
            Header: "Wrong Entrance Amount",
            accessor: "wrongEntranceAmount",
            width: 270,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Invalid Scans Amount",
            accessor: "invalidScansAmount",
            width: 270,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
          {
            Header: "Total",
            accessor: "total",
            width: 135,
            Footer: (info) => {
              const total = calculateColumnTotal(info.data, info.column.id);
              return <>{total}</>;
            },
          },
        ];

  return (
    <div className="statistics__table">
      <ReactTable
        columns={columns}
        data={data}
        style={{ height: "575px" }}
        showPagination={false}
        resizable={false}
        defaultPageSize={data.length}
      />
    </div>
  );
}

export default StatsInfo;
